<div fxLayout="column" fxLayoutAlign="center center">
  <h1 class="mat-headline">{{'reports'| translate}}</h1>

  <a fxFill mat-flat-button color="primary" class="control__btn"
    href="/agent/reports/transactions">{{'transactions'| translate}}</a>

  <a fxFill mat-flat-button color="primary" class="control__btn"
    href="/agent/reports/providers-report">{{'providers-report'| translate}}</a>

  <a fxFill mat-flat-button color="primary" class="control__btn"
    href="/agent/reports/agents-report">{{'agents-report'| translate}}</a>

</div>