import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordManagerService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/user'
   }

   // Сервис восстановления пароля
   forgotpassword(email: string): Observable<any> {
    let url = this.host + '/forgot-password';
    let json =
    {
      email: email
    }
    return this.http.post<any>(url, json);
  }

   // Сброс пароля
   resetpassword(code: string, email: string, password: string): Observable<any> {
    let url = this.host + '/reset-password';
    let json =
    {
      code: code,
      email: email,
      newPassword: password
    }
    return this.http.post<any>(url, json);
  }
}
