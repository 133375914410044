import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { I18nModule } from './i18n/i18n.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';

import { registerLocaleData, CommonModule } from '@angular/common';
import localeKz from '@angular/common/locales/ru-KZ';
import localeKg from '@angular/common/locales/ru-KG';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { HomeComponent } from './components/home/home.component';
import { UnauthorizedComponent } from './components/pages/unauthorized/unauthorized.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { DetailsComponent } from './components/payment/details/details.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { TemplateComponent } from './components/payment/template/template.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { TemplatecontrolComponent } from './components/payment/template/templatecontrol/templatecontrol.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

registerLocaleData(localeKz, 'kz');
registerLocaleData(localeKg, 'kg');


import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { RatesComponent } from './components/agent/rates/rates.component';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AgentsComponent } from './components/agents/agents.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FooterComponent } from './components/layout/footer/footer.component';
import { RecoveryComponent } from './components/user/recovery/recovery.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { AddAgentComponent } from './components/agents/add-agent/add-agent.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommissionComponent } from './components/commission/commission.component';
import { TariffChangeComponent } from './components/agents/tariff-change/tariff-change.component';
import { ProvidersReportComponent } from './components/agent/reports/providers-report/providers-report.component';
import { AgentsReportComponent } from './components/agent/reports/agents-report/agents-report.component';
import { TransactionsComponent } from './components/agent/reports/transactions/transactions.component';
import { DashboardComponent } from './components/agent/reports/dashboard/dashboard.component';
import { LoaderService } from './services/loader.service';
import { SuccessComponent } from './components/payment/success/success.component';
import { UpperCommissionComponent } from './components/agent/rates/upper-commission/upper-commission.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResetpasswordComponent } from './components/user/resetpassword/resetpassword.component';
import { CheckComponent } from './components/payment/check/check.component';
import { SearchComponent } from './components/services/search/search.component';
import { UserRegionComponent } from './components/user/user-region/user-region.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { FailedComponent } from './components/payment/failed/failed.component';
import { InvoiceTypeCDetailsComponent } from './components/payment/invoice-type-cdetails/invoice-type-cdetails.component';
import { InvoiceTypeOnlyLastCountsDetailsComponent } from './components/payment/invoice-type-only-last-counts-details/invoice-type-only-last-counts-details.component';
import { InvoiceTypeKtekComponent } from './components/payment/invoice-type-ktek/invoice-type-ktek.component';
import { InvoiceTypeSelectContractComponent } from './components/payment/invoice-type-select-contract/invoice-type-select-contract.component';
import { LowerCommissionComponent } from './components/agent/rates/lower-commission/lower-commission.component';
import { CookieService } from 'ngx-cookie-service';
import { CreditAgentComponent } from './components/agents/credit-agent/credit-agent.component';
import { DebetAgentComponent } from './components/agents/debet-agent/debet-agent.component';
import { ChangeInfoComponent } from './components/agents/change-info/change-info.component';
import { InfoComponent } from './components/pages/info/info.component';
import { AgentsReportFilterComponent } from './components/agent/reports/agents-report/agents-report-filter/agents-report-filter.component';
import { ProvidersReportFilterComponent } from './components/agent/reports/providers-report/providers-report-filter/providers-report-filter.component';
import { TransactionsFilterComponent } from './components/agent/reports/transactions/transactions-filter/transactions-filter.component';
import { ErrorDialogComponent } from './components/custom-dialogs/error-dialog/error-dialog.component';
import { ChangePasswordComponent } from './components/agents/change-password/change-password.component';
import { TwoFactorSettingsComponent } from './components/agents/two-factor-settings/two-factor-settings.component';
import { TariffsComponent } from './components/tariffs/tariffs/tariffs.component';
import { AddTariffComponent } from './components/tariffs/add-tariff/add-tariff/add-tariff.component';
import { EditTariffComponent } from './components/tariffs/edit-tariff/edit-tariff/edit-tariff.component';
import { UpperCommissionsComponent } from './components/tariffs/upper-commissions/upper-commissions/upper-commissions.component';
import { UpperCommissionsEditComponent } from './components/tariffs/upper-commissions/upper-commissions-edit/upper-commissions-edit/upper-commissions-edit.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NewsComponent } from './components/news/news/news.component';
import { NewsFilterComponent } from './components/news/news-filter/news-filter.component';
import { ViewTariffComponent } from './components/tariffs/view-tariff/view-tariff.component';
import { environment } from 'src/environments/environment';
import { DivisionalPaymentsConfirmDialogComponent } from './components/payment/divisional-payments-confirm-dialog/divisional-payments-confirm-dialog.component';
import { SuccessDivisionalTransactionComponent } from './components/payment/success-divisional-transaction/success-divisional-transaction.component';
import { RepayDialogComponent } from './components/payment/repay-dialog/repay-dialog.component';
import { RefundRequestComponent } from './components/agent/refund-request/refund-request.component';
import { RefundFilterComponent } from './components/agent/refund-request/refund-filter/refund-filter.component';
import { RefundDialogComponent } from './components/custom-dialogs/refund-dialog/refund-dialog.component';


export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: " " + environment.globalCurrencySymbol,
  thousands: " ",
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UnauthorizedComponent,
    CategoriesComponent,
    DetailsComponent,
    DashboardComponent,
    TemplateComponent,
    TemplatecontrolComponent,
    RatesComponent,
    AgentsComponent,
    RegistrationComponent,
    FooterComponent,
    RecoveryComponent,
    AddAgentComponent,
    CommissionComponent,
    TariffsComponent,
    TariffChangeComponent,
    ProvidersReportComponent,
    AgentsReportComponent,
    TransactionsComponent,
    SuccessComponent,
    UpperCommissionComponent,
    ResetpasswordComponent,
    CheckComponent,
    SearchComponent,
    UserRegionComponent,
    FailedComponent,
    InvoiceTypeCDetailsComponent,
    InvoiceTypeOnlyLastCountsDetailsComponent,
    InvoiceTypeKtekComponent,
    InvoiceTypeSelectContractComponent,
    LowerCommissionComponent,
    CreditAgentComponent,
    DebetAgentComponent,
    ChangeInfoComponent,
    InfoComponent,
    AgentsReportFilterComponent,
    ProvidersReportFilterComponent,
    TransactionsFilterComponent,
    ErrorDialogComponent,
    ChangePasswordComponent,
    TwoFactorSettingsComponent,
    AddTariffComponent,
    EditTariffComponent,
    UpperCommissionsComponent,
    UpperCommissionsEditComponent,
    NewsComponent,
    NewsFilterComponent,
    ViewTariffComponent,
    DivisionalPaymentsConfirmDialogComponent,
    SuccessDivisionalTransactionComponent,
    RepayDialogComponent,
    RefundRequestComponent,
    RefundFilterComponent,
    RefundDialogComponent
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    I18nModule,

    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule,
    MatAutocompleteModule, LayoutModule, MatToolbarModule, MatSidenavModule, MatListModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCardModule,
    FormsModule,
    MatGridListModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatSelectModule,
    MatExpansionModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatProgressBarModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    MatChipsModule,
    MatBadgeModule,
    MatPaginatorModule, 
    MatCheckboxModule
  ],
  exports: [MatDatepickerModule],
  providers: [
    LoaderService,
    { provide: MAT_DATE_LOCALE, useValue: 'ru-KZ' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatDatepickerModule,
    MatNativeDateModule,
    CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
