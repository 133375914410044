<mat-nav-list>

    <mat-form-field  fxFill mat-list-item>
        <mat-label>{{'transaction-id' | translate}}</mat-label>
        <input matInput type="number" placeholder="{{'search' | translate}}" [formControl]="transactionId">
      </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom"
            placeholder="dateFrom" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [formControl]="dateTo"
            placeholder="{{'dateTo'| translate}}" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-select placeholder="{{'transactionStatus' | translate}}"  [formControl]="statusId">
            <mat-option>{{'all' | translate}}</mat-option>
            <mat-option *ngFor="let option of data.refundStatuses" [value]="option">
                {{option.title}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div mat-list-item fxFill fxLayoutAlign="end" fxLayoutGap="10px">
        
        <button mat-button (click)="closeReportFilter()">
            <mat-icon>close</mat-icon>
            {{'cancel'| translate}}
        </button>
        
        <button mat-flat-button (click)="searchReportFilter()" color="primary">
            <mat-icon>search</mat-icon>
            {{'apply'| translate}}
        </button>
    </div>
  </mat-nav-list>