import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PagesService {

  host: string;
  
  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/pages'
  }
  get(): Observable<any[]> {
    let url = this.host;
    return this.http.get<any[]>(url);
  }

  getPage(pageUrl: string): Observable<any> {
    let url = this.host + '/' + pageUrl;
    return this.http.get<any>(url);
  }
}
