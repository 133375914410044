import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ICheckDetails } from 'src/app/interfaces/check-details/icheck-details';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IBill } from 'src/app/interfaces/payment/ibill';
import { IService } from 'src/app/interfaces/payment/iservice';
import { IReading } from 'src/app/interfaces/check-details/ireading';
import { TemplateComponent } from '../template/template.component';

@Component({
  selector: 'app-invoice-type-only-last-counts-details',
  templateUrl: './invoice-type-only-last-counts-details.component.html',
  styleUrls: ['./invoice-type-only-last-counts-details.component.css']
})
export class InvoiceTypeOnlyLastCountsDetailsComponent implements OnInit, AfterViewInit {

  @Input() checkDetails: ICheckDetails;
  @Output('update') handleAmountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output('changedServices') handleServicesChange: EventEmitter<IBill> = new EventEmitter<IBill>();
  data: any[];
  formDetails: FormGroup;

  changedBill: IBill;


  get services() {
    return (<FormArray>this.formDetails.get('services')).controls;
  }

  constructor(private fb: FormBuilder, private templateComponent: TemplateComponent) { }

  ngOnInit() {
    this.loadData();
    this.formDetails = this.fb.group({
      number: new FormControl({ value: this.checkDetails?.information.number, disabled: true }, Validators.required),
      amount: new FormControl({ value: this.checkDetails?.information.amount / 100, disabled: true }, Validators.required),
      address: new FormControl({ value: this.checkDetails?.information.address, disabled: true }),
      name: new FormControl({ value: this.checkDetails?.information.name, disabled: true }),
      services: this.fb.array(this.getServices())
    });

    this.getTotal();
  }

  ngAfterViewInit(){
    this.templateComponent.getArrayInput();
  }

  getServices() {
    return this.data.map(service => this.fb.group({
      isWantToPay: new FormControl(true),
      id: new FormControl({ value: service.id, disabled: false }),
      name: new FormControl({ value: service.name, disabled: false }),
      readings: this.fb.array(this.getReadings(service.readings)),
    })).sort(function (a, b) {
      return (parseInt(a.get('id').value) > parseInt(b.get('id').value) ? 1 : -1); // сортировка по идентификатору по просьбе ИВЦ
    });
  }

  getReadings(readings: any[]) {
    return readings.map(read => this.fb.group({
      type: read.type,
      count: read.count,
      overwritable: read.overwritable
    }));
  }

  getReadingsFor(index) {
    return (<FormArray>(<FormArray>(this.formDetails.get('services'))).controls[index].get('readings')).controls;
  }

  loadData() {
    this.data = this.checkDetails.invoice.invoiceServices;
  }

  getTotal() {
    let totalTotal = this.checkDetails.addInfo.document.invoices.invoice[0].amount;
    let _services: IService[] = [];

    this.services.forEach(item => {

      let _service: IService =
      {
        id: item.value.id.toString(),
        name: item.value.name,
        total: 0,
        readings: item.value.readings
      }
      _services.push(_service);
    });

    if (this.formDetails.valid) {
      this.changedBill =
      {
        id: this.checkDetails.addInfo.document.invoices.invoice[0].id,
        total: totalTotal,//parseInt((Number(Number(total).toFixed(2)) * 100).toString()),
        services: _services
      }
      this.handleServicesChange.emit(this.changedBill);
    }

    this.formDetails.controls['amount'].setValue(totalTotal/100);
  }

  userChangedReading() {
    this.getTotal();
  }

}
