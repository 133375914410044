<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'resetpassword' | translate}}</h1>
    {{'resetpassword-description' | translate}}
    <form [formGroup]="resetpasswordForm" novalidate (ngSubmit)="recovery()">
        <mat-form-field fxFill>
            <input matInput placeholder="{{'one-time-code' | translate}}" type="text" formControlName="code">
            <mat-error *ngIf="f.code.errors">
                <div *ngIf="f.code.errors.required">{{'code-is-required' | translate}}</div>
            </mat-error>
        </mat-form-field>
        <mat-form-field fxFill>
            <input matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
                <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
                <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
        </mat-form-field>
        <mat-form-field fxFill>
            <input [type]="hide ? 'password' : 'text'"  matInput placeholder="{{'password' | translate}}" formControlName="password">
            <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="f.password.errors">
              <div *ngIf="f.password.errors?.required">{{'password-is-required' | translate}}</div>
              <div *ngIf="f.password.errors?.pattern">{{'password-pattern' | translate}}</div>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFill>
            <input [type]="hide ? 'password' : 'text'" matInput placeholder="{{'passwordConfirm' | translate}}"
              formControlName="passwordConfirm">
              <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="f.passwordConfirm.errors">
              <div *ngIf="f.passwordConfirm.errors.required">{{'password-confirm-req' | translate}}</div>
              <div *ngIf="f.passwordConfirm.errors.mustMatch">{{'passwords-must-match' | translate}}</div>
            </mat-error>
          </mat-form-field>
        <button mat-flat-button fxFill color="primary" class="control__btn" type="submit"
            [disabled]="!resetpasswordForm.valid" cdkFocusInitial>{{'resetpassword'| translate}}</button>
    </form>
</div>