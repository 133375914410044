<div fxFlex="100" fxFlex.xs="100" fxFlexOffset.xs="0" fxLayout="column" style="margin-right: 10px;" class="service-search-block">
    <mat-form-field>
      <input [formControl]="autoCompleteControl" type="text" placeholder="{{'query' | translate}}?" matInput [matAutocomplete]="auto">
      <mat-icon matSuffix class="service-search-icon">search</mat-icon>
      <mat-autocomplete [displayWith]="displayFn" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="navigateTo($event)">   
        <mat-option *ngFor="let item of serviceAutoComplete$ | async; let index = index" [value]="item">
          {{ item.name | titlecase}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
</div>