import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IAgent } from 'src/app/interfaces/agent/iagent';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';

@Component({
  selector: 'app-user-region',
  templateUrl: './user-region.component.html',
  styleUrls: ['./user-region.component.css']
})
export class UserRegionComponent implements OnInit {
  regions = [];
  agent: any;

  regionForm = this.fb.group({
    region: [null, Validators.required]
  });
  
  constructor(private dictionarySrv: DictionaryService,
    private fb: FormBuilder,
    private agentSrv: AgentService,
    private translate: TranslateService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getRegions();
    this.getAgent();
  }

  getAgent() {
    this.agentSrv.agent().subscribe(
      (data: any) => {
        this.agent = data;
        this.regionForm.controls['region'].setValue(this.agent.regionId);
      },
      error => console.log(error)
    )
  }

  getRegions() {
    this.dictionarySrv.getRegions().subscribe(
      (data: any) => { 
        this.regions = data; 
      },
      error => console.log(error)
    )
  }

  regionChanged(obj){
    let json: IAgent = {            
      regionId: obj.value
    }

    this.agentSrv.updateAgent(json)
      .subscribe(
        data => {
          let message = this.translate.instant('data-success-updated');
          this.openSnackBar(message, 'x');    
          window.location.reload();
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }


}
