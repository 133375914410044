import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-refund-filter',
  templateUrl: './refund-filter.component.html',
  styleUrls: ['./refund-filter.component.css']
})
export class RefundFilterComponent implements OnInit {

  dateFrom = new FormControl();
  dateTo = new FormControl();
  statusId = new FormControl();
  transactionId = new FormControl();
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private bottomSheetRef: MatBottomSheetRef) { 
    this.dateFrom.setValue(this.data.dateFrom.value);
    this.dateTo.setValue(this.data.dateTo.value);
    this.statusId.setValue(this.data.statusId?.value);
    this.transactionId.setValue(this.data.transactionId?.value);
  }

  ngOnInit(): void {
  }

  searchReportFilter(){
    let data = [];
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    data['statusId'] = this.statusId;
    data['transactionId'] = this.transactionId;
    data['isClosed'] = false;
    this.bottomSheetRef.dismiss(data);
  }

  closeReportFilter(){
    this.bottomSheetRef.dismiss();
  }

}
