<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'transactions'| translate}}</h1>
</div>

<div class="container" fxLayout fxLayoutAlign="end center">
    <mat-chip-list>
        <mat-chip (click)="openReportFilters()" *ngIf="paymentParameter?.value">{{'parameter' | translate}}: {{paymentParameter?.value}}<mat-icon matChipRemove (click)="emptyPaymentParameter()">cancel</mat-icon></mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="selectedAgent?.value">{{selectedAgent?.value?.company}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="selectedService?.value">{{selectedService?.value?.name}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateFrom?.value">{{'from-date' | translate}} {{dateFrom?.value | date: 'dd/MM/yyyy'}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateTo?.value">{{'to-date' | translate}} {{dateTo?.value | date: 'dd/MM/yyyy'}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="transactionStatusTitle">{{transactionStatusTitle}}<mat-icon matChipRemove (click)="emptyTransactionStatus()">cancel</mat-icon></mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="transactionTypeTitle">{{transactionTypeTitle}}<mat-icon matChipRemove (click)="emptyTransactionType()">cancel</mat-icon></mat-chip>
    </mat-chip-list>

    <button mat-icon-button color="primary" (click)="exportToExcel()" [disabled]="dataSource?.length==0" >
        <mat-icon>save_alt</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="openReportFilters()">
        <mat-icon>filter_list</mat-icon>
    </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'total' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="inprogress-transaction-icon" *ngIf="element.status == 0" matTooltip="{{element.statusTitle}}">update</mat-icon>
                <mat-icon class="completed-transaction-icon" *ngIf="element.status == 1" matTooltip="{{element.statusTitle}}">check_circle</mat-icon>
                <mat-icon class="declined-transaction-icon" *ngIf="element.status == 2" matTooltip="{{element.statusTitle}}">cancel</mat-icon>
                <mat-icon class="canceled-transaction-icon" *ngIf="element.status == 3 || element.status == 4" matTooltip="{{element.statusTitle}}">cancel</mat-icon>
            </td>
        </ng-container>

        <!-- From Account Name Column -->
        <ng-container matColumnDef="mainInfo">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <span class="mat-body">{{element.fromAccountName }} </span> <br>
                <span class="mat-body" class="to-account-span"><mat-icon class="from-to-account-icon">arrow_right_alt</mat-icon> {{element.toAccountName }}</span> <br>
                <span class="mat-small">[{{element.statusTitle}}] {{element.typeTitle}} <span *ngIf="element.serviceName">- {{element.serviceName}}</span></span> <br>
                <div *ngIf="element.status == 2 && element.failReason != null">
                  <span class="fail-reason">{{element.failReason | translate}}</span><br>
                </div>
                <div *ngIf="element.parameters">
                    <span class="mat-small" *ngFor="let parameter of element.parameters | keyvalue">
                        {{parameter.key.toLowerCase() | translate}} - {{parameter.value}}
                    </span><br>
                </div>
                <span class="mat-small">{{element.completedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</span> <br>
            </td>
        </ng-container>

        <!-- allAmount Column -->
        <ng-container matColumnDef="allAmount">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                <span class="mat-caption">{{'report-accepted' | translate}}: {{getTotalAccepted() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-user-amount' | translate}}: {{getTotalUserAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-upper-commission-amount' | translate}}: {{getTotalUpper() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span><br>
                <span class="mat-caption">{{'report-canceled-amount' | translate}}: {{getTotalCanceledAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span class="mat-caption">{{'report-accepted' | translate}}: {{element.accepted | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span> <br>
                <span class="mat-caption">{{'report-user-amount' | translate}}: {{element.amount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span>
                <span class="mat-caption" *ngIf="element.type == 1"><br>{{'report-upper-commission-amount' | translate}}: {{element.commission | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                <span class="mat-caption" *ngIf="element.canceledAmount != 0"><br>{{'report-canceled-amount' | translate}}: {{element.canceledAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                <div>
                    <a *ngIf="element.status==1 && element.type == 1" [routerLink]="['/template', element.serviceId, element.id]" matTooltip="{{'repeat-transaction' | translate}}">
                        <button mat-icon-button color="primary">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </a>

                    <button mat-icon-button color="primary" *ngIf="element.status==1 && element.type == 1" (click)="checkDownload(element)" matTooltip="{{'check-download' | translate}}">
                        <mat-icon>picture_as_pdf</mat-icon>
                    </button>

                    <a *ngIf="element.status==1 && element.type == 1" [routerLink]="['/payment/success', element.id]" matTooltip="{{'payment-details' | translate}}">
                        <button mat-icon-button color="primary">
                            <mat-icon>receipt</mat-icon>
                        </button>
                    </a>

                    <a *ngIf="element.status==2 && element.type == 1" [routerLink]="['/payment/failed', element.id]" matTooltip="{{'payment-details' | translate}}">
                        <button mat-icon-button color="primary">
                            <mat-icon>receipt</mat-icon>
                        </button>
                    </a>

                    <a *ngIf="element.status==1 && element.type == 1" (click)="openDialogConfirmRefund(element)" matTooltip="{{'cancel-payment' | translate}}">
                        <button mat-icon-button color="primary">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </a>

                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [hidden]="isHideHeader == true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">{{'empty-report-result' | translate}}</td>
        </tr>
    </table>
</div>
