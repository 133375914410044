import { Component, OnInit } from '@angular/core';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  startWith,
  map,
  debounceTime,
  switchMap,
  catchError
} from 'rxjs/operators';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  public serviceAutoComplete$: Observable<any> = null;
  public autoCompleteControl = new FormControl();

  constructor(private searchService: ServiceService,
    private router: Router
  ) { }

  lookup(value: any): Observable<any> {  
   if (typeof value === 'object')
   {
     value = value.name;
   } 
    return this.searchService.search(value.toLowerCase()).pipe(
      // map the item property of the results as our return object
      map(results => results),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
  }

  ngOnInit() {
    this.serviceAutoComplete$ = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from services
          return this.lookup(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  navigateTo(event) {
    let selectedValue = event.option.value;
    let url = "template/"+selectedValue.id;
    window.location.href = url;
   }

  displayFn(service: any): string {
    return service && service.name ? service.name : '';
  }

}