<h2 class="mat-headline">{{'lower-commission'| translate}}</h2>
<mat-dialog-content class="mat-typography">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <div fxLayout="column" fxLayoutGap="30px" *ngIf="isCompleted">
        <ng-container>           
            <div fxLayout="column" fxLayoutAlign="none">
                <p>Процент: <b>{{lowerInfo.LowerPercent/100}}%</b></p>
                <p>Фиксированная сумма: <b>{{lowerInfo.LowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b></p>
                <p>Расчет: ({{amount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} * <b>{{lowerInfo.LowerPercent/100}}%</b> / 100)  + <b>{{lowerInfo.LowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b> = <b>{{(amount*lowerInfo.LowerPercent/100/100)+lowerInfo.LowerFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b></p>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>

<h2 class="mat-headline">{{'ito-commission'| translate}}</h2>
<mat-dialog-content class="mat-typography">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <div fxLayout="column" fxLayoutGap="30px" *ngIf="isCompleted">
        <ng-container>           
            <div fxLayout="column" fxLayoutAlign="none">
                <p>Процент: <b>{{lowerInfo.ItoPercent/100}}%</b></p>
                <p>Фиксированная сумма: <b>{{lowerInfo.ItoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b> </p>
                <p>Расчет: ({{amount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} * <b>{{lowerInfo.ItoPercent/100}}%</b> / 100)  + <b>{{lowerInfo.ItoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b> = <b>{{(amount*lowerInfo.ItoPercent/100/100)+lowerInfo.ItoFix/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b></p>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'| translate}}</button>
</mat-dialog-actions>