<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'manage-upper-commissions'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-body">{{element.name }} </span>
            </td>
        </ng-container>
        
        <!-- action Column -->
        <ng-container matColumnDef="action">
            <td mat-cell *matCellDef="let element"> 
                <div>
                    <button mat-icon-button color="primary" (click)="openAgentUpperCommissionEditDialog(element)">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>

            </td>
        </ng-container>
       
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>