import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AgentService } from 'src/app/services/agent.service';

@Component({
  selector: 'app-providers-report-filter',
  templateUrl: './providers-report-filter.component.html',
  styleUrls: ['./providers-report-filter.component.css']
})
export class ProvidersReportFilterComponent implements OnInit {

  public agents: any[] = this.data.agents;
  selectedAgent = this.data.selectedAgent;
  dateFrom = this.data.dateFrom;
  dateTo = this.data.dateTo;
  
  @Output() someEvent = new EventEmitter<string>();

  constructor(public agentsSrv: AgentService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef) { }

  ngOnInit(): void {
    this.load();
  }

  load() {

  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  searchReportFilter(){
    let data = [];
    data['selectedAgent'] = this.selectedAgent;
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    this.bottomSheetRef.dismiss(data);
  }

  closeReportFilter(){
    this.bottomSheetRef.dismiss();
  }

}
