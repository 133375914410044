import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputControlBase } from 'src/app/classes/input-control-base';
import { ITemplate } from 'src/app/interfaces/payment/itemplate';
import { IsPayProcessService } from 'src/app/services/is-pay-process.service';

@Component({
  selector: 'app-templatecontrol',
  templateUrl: './templatecontrol.component.html',
  styleUrls: ['./templatecontrol.component.css']
})
export class TemplatecontrolComponent implements OnInit {

  @Input() inputcontrol: InputControlBase<string>;
  @Input() template: ITemplate;
  @Input() form: FormGroup;
  @Input() inputPrefix: string;
  @Input() inputMask: string;
  isPay: boolean;
  
  get isValid() { 
      return this.form.controls[this.inputcontrol.key]; 
  }

  constructor(private isPayProcess: IsPayProcessService) {  }

  ngOnInit() {
    this.isPayProcess.get().subscribe(val => this.isPay = val);
  }
  
}