<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'agents-report'| translate}}</h1>
</div>

<div class="container" fxLayout fxLayoutAlign="end center">
    <mat-chip-list>
        <mat-chip (click)="openReportFilters()" *ngIf="selectedService?.value">{{selectedService?.value?.name}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="selectedAgent?.value">{{selectedAgent?.value?.company}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateFrom?.value">{{'from-date' | translate}} {{dateFrom?.value | date: 'dd/MM/yyyy'}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateTo?.value">{{'to-date' | translate}} {{dateTo?.value | date: 'dd/MM/yyyy'}}</mat-chip>
    </mat-chip-list>
    
    <button mat-icon-button color="primary" (click)="exportToExcel()" [disabled]="dataSource?.length==0" >
        <mat-icon>save_alt</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="openReportFilters()">
        <mat-icon>filter_list</mat-icon>
    </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- agentInfo Column -->
        <ng-container matColumnDef="agentInfo">
            <th mat-header-cell *matHeaderCellDef> {{'total-transactions' | translate}}: {{getTotalSuccesTransaction()}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-body">{{element.agentName }} </span> <br>
                <span class="mat-caption">{{'report-success-transaction-count' | translate}}: {{element.successCount}} </span>
                <span class="mat-caption" *ngIf="element.canceledCount > 0"><br>{{'report-canceled-count' | translate}}: {{element.canceledCount}} </span>
            </td>
        </ng-container>

        <!-- allAmount Column -->
        <ng-container matColumnDef="allAmount">
            <th mat-header-cell *matHeaderCellDef>
                <span class="mat-caption">{{'report-accepted-amount' | translate}}: {{getTotalAccepted() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-user-amount' | translate}}: {{getTotalUserAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-upper-commission-amount' | translate}}: {{getTotalUpper() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-lower-commission-amount' | translate}}: {{getTotalLower() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-ito-commission-amount' | translate}}: {{getTotalITO() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}  </span>
                <span class="mat-caption" *ngIf="getCanceledAmount() != 0"><br>{{'report-canceled-amount' | translate}}: {{getCanceledAmount() | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}  </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span class="mat-caption">{{'report-accepted-amount' | translate}}: {{element.acceptedAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span> <br>
                <span class="mat-caption">{{'report-user-amount' | translate}}: {{element.userAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span> <br>
                <span class="mat-caption">{{'report-upper-commission-amount' | translate}}: {{element.upperCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-lower-commission-amount' | translate}}: {{element.lowerCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span> <br>
                <span class="mat-caption">{{'report-ito-commission-amount' | translate}}: {{element.itoCommissionAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span>
                <span class="mat-caption" *ngIf="element.canceledAmount != 0"><br>{{'report-canceled-amount' | translate}}: {{element.canceledAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="2">{{'empty-report-result' | translate}}</td>
        </tr>
    </table>
</div>