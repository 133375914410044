<div fxLayout="column" fxLayoutAlign="center center">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <ng-container *ngIf="isCompleted">
        <div class="container check-container check-btn-bar" >
            <div fxLayout="row" fxLayout.xs="row" fxLayoutAlign="space-between" style="margin-bottom: 10px;">
                <div fxLayoutAlign.xs="start">
                    <button mat-button   class="padding-none back-button font-size-13"  >
                        <a fxFill mat-flat-button class="font-size-13"
                            href="/template/{{check?.Payload?.serviceId}}"><mat-icon>keyboard_backspace</mat-icon>{{'back'| translate}}</a>  
                    </button>
                    
                </div>
                <div fxLayoutAlign.xs="end">
                    <button mat-button mat-flat-button  class="button-height font-size-13"  color="primary" autofocus (click)="printCheck()">
                        <mat-icon >print</mat-icon>
                        {{'print-check'| translate}}
                    </button>    
                </div>
            </div>
        
            <div fxLayout="row" fxLayout.xs="column" fxFill *ngIf="check?.IsFiscal == true && check?.FiscalCheck == null">
                <label class="font-size-13">{{'update-fiscal-check-status-desc' | translate}}</label>
                <button mat-button mat-flat-button  class="button-height font-size-13"  color="primary" (click)="updateFiscalCheckStatus()" disabled="{{updateFiscalBtnStatus}}">
                    <mat-icon >update</mat-icon>
                    {{'update-fiscal-check-status'| translate}} <span *ngIf="updateFiscalBtnStatus">({{timeLeft}})</span>
                </button>    
            </div>
        </div>

        <div class="container check-container">
            <div id="check_container" > 
                <div class="upper">
                    <img src="{{static_api_url}}ukassa/read/upper_new.png" alt="">
                </div>
                <div class="logo">
                    <img src="{{static_api_url}}ukassa/read/kassa-logo.png" alt="">
                    <p class="service-name">{{check?.Payload?.serviceName}}</p>
                </div>
                <div fxLayout="column" class="fiscal-container" fxLayout.xs="column"  *ngIf="check?.IsFiscal == true && check?.FiscalCheck != null">

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%" >{{'fiscal-company-name' | translate}}:</div>
                        <div fxFlex="50%">{{'fiscal-company-name-value' | translate}}</div>
                    </div>
                    
                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-registration-number-title' | translate}}:</div>
                        <div fxFlex="50%">{{'fiscal-registration-number-value' | translate}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-tax-payer-name' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.TaxPayerName}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-tax-payer-bin' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.TaxPayerBin}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-terminal-id' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.AgentId}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-address' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.Address}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-check-id' | translate}}:</div>
                        <div fxFlex="50%">{{check?.TransactionId}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-payment-date' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.PaymentDate | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-service-name' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.ServiceName}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-accepted' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.Accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-amount' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.Amount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-commission' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.Commission/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                    </div>

                    <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                        <div class="check-info-group-title" fxFlex="50%">
                            <p *ngIf="(check?.Payload?.parameters | keyvalue)?.length <= 1">{{'parameter' | translate}}:</p> 
                            <p *ngIf="(check?.Payload?.parameters | keyvalue)?.length > 1">{{'parameters' | translate}}:</p>  
                        </div>

                        <div fxFlex="50%">
                            <ng-container>
                                <p *ngFor="let applicant of check?.Payload?.parameters | keyvalue">
                                    {{applicant.value}}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="check?.Payload?.bill?.Services">
                        <div fxLayout="column" fxLayout.xs="column" class="check-info-group bill-info-group" *ngFor="let service of check?.Payload?.bill?.Services">
                            <div  class="bill-service"  fxLayout.xs="column"  fxLayout="row">
                                <div class="check-info-group-title reading-group-block" fxFlex="50%">{{service.Name }}:
                                    <div class="reading-value-block"  *ngFor="let readings of service.Readings">
                                        <p class="mb-0" *ngIf="readings.Type == 1">{{'prev-reading-short' | translate}} {{readings.Count}}</p>
                                        <p class="mb-0" *ngIf="readings.Type == 2">{{'current-reading-short' | translate}} {{readings.Count}}</p>
                                    </div>
                                </div>
                                <div fxFlex="50%" class="reading-total-block">
                                    {{service.Total/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                                </div>
                            </div>           
                        </div>
                    </ng-container>
                   
                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-ofd-document-number' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.OfdDocumentNumber}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group">
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-serial-number' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.SerialNumber}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-rnm' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.Rnm}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-ofd-provider-name' | translate}}:</div>
                        <div fxFlex="50%">{{check?.FiscalCheck?.OfdProviderName}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%">{{'fiscal-nds-title' | translate}}:</div>
                        <div fxFlex="50%">{{'fiscal-without-nds' | translate}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="fiscal-ofd-link check-info-group" >
                        <div class="check-info-group-title" fxFlex="50%">
                            {{'fiscal-ofd-link' | translate}}:
                        </div>
                        <div fxFlex="50%">
                            <a href="{{check?.FiscalCheck?.OfdLink}}" target="_blank">
                                {{check?.FiscalCheck?.OfdLink}}
                            </a>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="padding-btm-tp-10 check-info-group commission-text"   fxHide>
                        <div fxFlex="100%">{{'fiscal-commission-text1' | translate}} <br> {{'fiscal-commission-text2' | translate}}</div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                        <div fxFlex="100%" class="qr-code-block">
                            <img src="{{'data:image/jpg;base64,' + check?.FiscalCheck?.OfdQrCode}}" width="75" height="75">
                        </div>
                    </div>
 
                </div>                

                <div class="fiscal-container" *ngIf="check?.IsFiscal != true || (check?.IsFiscal == true && check?.FiscalCheck == null)">
                        <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                            <div class="check-info-group-title" fxFlex="50%" >{{'receipt-number' | translate}}:</div>
                            <div fxFlex="50%">{{check?.TransactionId}}</div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                            <div class="check-info-group-title" fxFlex="50%" >{{'date' | translate}}:</div>
                            <div fxFlex="50%">{{check?.CreatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                        </div>
                        
                        <div fxLayout="row" class="check-info-group" fxLayout.xs="column" >
                            <div class="check-info-group-title" fxFlex="50%">
                                <p *ngIf="(check?.Payload?.parameters | keyvalue)?.length <= 1">{{'parameter' | translate}}:</p> 
                                <p *ngIf="(check?.Payload?.parameters | keyvalue)?.length > 1">{{'parameters' | translate}}:</p>  
                            </div>
    
                            <div fxFlex="50%">
                                <ng-container>
                                    <p *ngFor="let applicant of check?.Payload?.parameters | keyvalue">
                                        {{applicant.value}}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                   
                        <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                            <div class="check-info-group-title" fxFlex="50%" >{{'check-accepted' | translate}}:</div>
                            <div fxFlex="50%">{{check?.Payload?.accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                            <div class="check-info-group-title" fxFlex="50%" >{{'check-amount' | translate}}:</div>
                            <div fxFlex="50%">{{check?.Payload?.amount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                            <div class="check-info-group-title" fxFlex="50%" >{{'check-upper-commission' | translate}}:</div>
                            <div fxFlex="50%">{{check?.Payload?.fee/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
                        </div>

                        <div fxLayout="column" fxLayout.xs="column" class="check-info-group bill-info-group" *ngIf="check?.Payload?.bill?.Services">
                            <div *ngFor="let service of check?.Payload?.bill?.Services" class="bill-service"  fxLayout.xs="column"  fxLayout="row">
                                <div class="check-info-group-title reading-group-block"  fxLayout.xs="column" fxFlex="100%" >{{service.Name }}:
                                    <div class="reading-value-block" *ngFor="let readings of service.Readings" >
                                        <div class="mb-0" *ngIf="readings.Type == 1">{{'prev-reading-short' | translate}} {{readings.Count}}</div>
                                        <div class="mb-0" *ngIf="readings.Type == 2">{{'current-reading-short' | translate}} {{readings.Count}}</div>
                                    </div>
                                </div>
                                <div fxFlex="100%"  fxLayout.xs="column" class="reading-total-block">
                                    {{service.Total/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                                </div>
                            </div>           
                        </div>                        
                </div>
        
                <div class="check-container info-{{globalLocation}}">
                    <p class="info-text">{{'payment-accepted' | translate }}!</p>
                    <p class="info-sum"><b>{{check?.Payload?.accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b></p>
                    <p class="info-sum info-sum-size">{{'check-upper-commission' | translate}}: {{check?.Payload?.fee/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</p>
                </div>
        
                <div>
                    <p class="support-info">{{'support' | translate}}:</p>
                    <div class="table-info sup">
                            <div class="fiscal-container">
                                <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                                    <div class="check-info-group-title" fxFlex="50%" >{{'check-phone-label' | translate}}:</div>
                                    <div fxFlex="50%">{{'support-description' | translate}}<br>
                                        {{'call-center-mobile-phone' | translate}}</div>
                                </div>

                                <div fxLayout="row" *ngIf="globalLocation =='kg'" fxLayout.xs="column" class="check-info-group" >
                                    <div class="check-info-group-title" fxFlex="50%" >{{'whatsapp' | translate}}:</div>
                                    <div fxFlex="50%">{{'whatsapp-phone' | translate}}</div>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" class="check-info-group" >
                                    <div class="check-info-group-title" fxFlex="50%" >{{'check-email-label' | translate}}:</div>
                                    <div fxFlex="50%">{{'call-center-email' | translate}}</div>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="stamp">
                    <img src="{{static_api_url}}ukassa/read/stamp-kassa.png" alt="">
                </div>
                <div class="lower">
                    <img src="{{static_api_url}}ukassa/read/lower_new.png" alt="">
                </div>
            </div>    
        </div>

        <div class="container check-container history-block-bg-color" fxLayout="row" fxFill fxLayout.xs="column" fxFlex="100" fxLayoutGap.xs="10px" fxLayoutAlign="center">
            <button mat-button fxFlex.xs="100" class="mt-30" color="primary" >
                <a fxFill mat-flat-button color="primary" class="font-size-13"
                href="/agent/reports/transactions"><mat-icon>history_icon</mat-icon>{{'payments-history'| translate}}</a>
            </button>
        </div>   

        <form [formGroup]="mailForm" novalidate (ngSubmit)="sendToMail()" fxFill >

            <div class="container check-container history-block-bg-color" fxLayout fxLayout.xs="column" fxLayoutAlign="end" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
            </div>
        </form>
        

        <div fxLayout="row" fxLayout.xs="column" class="container check-container history-block-bg-color" fxFlex="100" > 
            <div fxFlex="50">
                <form [formGroup]="mailForm" novalidate (ngSubmit)="sendToMail()" fxFill>

                    <div class="container check-container history-block-bg-color"  fxLayout fxLayoutAlign="left" fxLayoutGap="1px"
                        fxLayoutGap.xs="0">
        
                        <mat-form-field fxFlex="100" fxFlex.xs="90">
                            <input matInput placeholder="{{'email-check-send-desc' | translate}}" formControlName="email">
                            <mat-error *ngIf="f.email.errors">
                                <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
                                <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
                            </mat-error>
                        </mat-form-field>
        
                        <button mat-icon-button color="primary" [disabled]="!mailForm.valid">
                            <mat-icon>send</mat-icon>
                        </button>
        
                    </div>
                </form>
            </div>

            <div fxFlex="50">
                <form [formGroup]="whatsappForm" novalidate (ngSubmit)="sendToWhatsApp()" fxFill>

                    <div class="container check-container history-block-bg-color" fxLayout fxLayoutAlign="left" fxLayoutGap="1px"
                        fxLayoutGap.xs="0">
        
                        <mat-form-field  fxFlex="100"  fxFlex.xs="90">
                            <input matInput placeholder="{{'whatsapp-check-send-desc' | translate}}" formControlName="phone">
                            <mat-hint>
                                {{'whatsapp-number-sample'| translate}}
                            </mat-hint>
                            <mat-error *ngIf="p.phone.errors">
                                <div *ngIf="p.phone.errors.required">{{'phone-is-req' | translate}}</div>
                                <div *ngIf="p.phone.errors.pattern">{{'phone-number-pattern' | translate}}</div>
                            </mat-error>
                        </mat-form-field>
        
                        <button mat-icon-button color="primary" [disabled]="!whatsappForm.valid">
                            <mat-icon>send</mat-icon>
                        </button>
                    </div>
                </form>
        
            </div>
        </div>
       

      
    </ng-container>
</div>
