import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/news'
  }

  // Получить шаблоны тарифов для суб агента
  getAgentNews(dateFrom: Date, dateTo: Date): Observable<any> {
    let url = this.host;

    var dateFromNew = null;
    var dateToNew = null;
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.999") + "Z";
    }

    let json: any = {
      from: dateFromNew,   
      to: dateToNew,
    }   
    return this.http.post<any[]>(url, json);
  };

  getAgentNewsCount(): Observable<any> {
    let url = this.host + "/user-news-count";
    return this.http.get<any>(url);
  }

  setNewsAsReaded(data: any, status: boolean): Observable<any> {
    let url = this.host + '/change-news-status/' + status;
    return this.http.post(url, data);
  }

}
