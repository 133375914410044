import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentReceiptService } from 'src/app/services/payment-receipt.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';
import { appConfig } from 'src/assets/config/application-config';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  isCompleted: boolean = false;
  check: any;
  id: number; // Идентификатор транзакции
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  static_api_url = environment.api_static;
  updateFiscalBtnStatus = false;
  timeLeft: number = 10;
  maxTimeLeft: number = 10;
  interval;

  mailForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  });

  whatsappForm = this.fb.group({
    phone: [null, [Validators.required, Validators.pattern(appConfig["add_agent_phone_pattern"])]]
  })

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private checkSrv: PaymentReceiptService,
    private loaderService: LoaderService,
    private transactionSrv: TransactionService,) { }

  ngOnInit(): void {
    this.load();
  }

    // convenience getter for easy access to form fields
    get f() { return this.mailForm.controls; }
    get p(){ return this.whatsappForm.controls; }

  load() {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe(data => this.id = +data);
    this.getCheck();
  }

  getCheck() {
    this.isCompleted = false;

    this.transactionSrv.get(this.id.toString()).subscribe(
      data => {
        this.check = data;
      },
      error => {
        let message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
      }
    ).add(() => { this.isCompleted = true });
  }

  updateFiscalCheckStatus(){
    this.updateFiscalBtnStatus = true;
    this.timeLeft = this.maxTimeLeft;
    this.getCheck();

    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      else {
        this.timeLeft = this.maxTimeLeft;
        this.updateFiscalBtnStatus = false;
        clearInterval(this.interval);
      }
    },1000);
  }

  sendToMail(): void {
    if (this.mailForm.valid) {
      this.loaderService.display(true);
      this.checkSrv.send(this.mailForm.controls['email'].value, this.id)
        .subscribe(
          data => {
            let message = this.translate.instant('email-succesfully-send');
            this.openSnackBar(message, 'x');
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
          this.loaderService.display(false);
        });
    }
  }

  sendToWhatsApp(){
    if (this.whatsappForm.valid) {
      this.loaderService.display(true);
      this.checkSrv.sendToWhatsapp(this.whatsappForm.controls['phone'].value, this.id)
        .subscribe(
          data => {
            let message = this.translate.instant('whatsapp-succesfully-send');
            this.openSnackBar(message, 'x');
          },
          error => {
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
          this.loaderService.display(false);
        });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  printCheck(){
    var innerContents = document.getElementById("check_container").outerHTML;
    var popupWinindow = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head></head><style>body{ font-size: 10px;} #check_container{max-width:350px;} table{min-width: 320px; font-size: 10px;} table td {border-bottom:1px solid silver; max-width:160px;} .logo img,.info-sum{display: none;} .logo p {  text-align: left; font-size:12px; padding-bottom:5px; padding-left: 19px} td{width:50%; word-break: break-all;} .support-info,.service-name {font-weight: bold;} .support-info {padding-left: 19px;} .mb-0{margin:0;} p.info-text{text-align: center; max-width: 320px;margin: 2px;font-weight: bold; padding-bottom:2px; } .stamp{max-width: 350px;} .stamp img {width: 30%;float: right;margin-top: -78px;} .qr-code-block{ text-align: center; } .qr-code-block img{width:125px; height:125px;} .fiscal-ofd-link{display: none !important;} .fiscal-container{ font-size: 10px; padding: 0px 19px; font-family: Roboto Condensed; font-style: normal; font-weight: normal; } .fiscal-ofd-link{ word-break: break-all; } .padding-btm-tp-10{ padding-bottom: 10px; padding-top: 10px; } .check-info-group{width:100%; border-bottom: 1px solid silver; padding: 2px 0; flex-direction: row !important;} div p {padding:0; margin:0;} p.info-text, .support {padding: 2px 0px 2px 19px; } .bill-service{ flex-direction: row !important;} .bill-info-group{flex-direction: column !important} .check-info-group .bill-service { border-bottom: 1px solid lightgrey !important; } .check-info-group .bill-service:last-child{ border: none !important; } </style><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
    popupWinindow.onload = function() { popupWinindow.print(); popupWinindow.close(); }
  }

}
