import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-repay-dialog',
  templateUrl: './repay-dialog.component.html',
  styleUrls: ['./repay-dialog.component.css']
})

export class RepayDialogComponent implements OnInit {
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  dataSource: any;
  constructor( @Inject(MAT_DIALOG_DATA) public item: any,
    private matDialogRef: MatDialogRef<RepayDialogComponent>) {  
  }

  ngOnInit(): void {
    this.dataSource = this.item;
  }

  submitButton(){
    let data = [];
    data['repayStatus'] = true;
    data['isClosed'] = false;
    this.matDialogRef.close(data);
  }

  closeButton(){
    let data = [];
    data['repayStatus'] = false;
    data['isClosed'] = true;
    this.matDialogRef.close(data);
  }
}
