import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITariffs } from 'src/app/interfaces/itariffs';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommissionService } from 'src/app/services/commission.service';
import { ViewTariffComponent } from '../view-tariff/view-tariff.component';

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.css']
})
export class TariffsComponent implements OnInit {

  tariffs: ITariffs[] = [];
  displayedColumns: string[] = ['name', 'created', 'action'];
  dataSource: ITariffs[] = [];
  isCompleted: boolean = false;

  constructor(public authService: AuthService,
    private agentSrv: AgentService,
    public dialog: MatDialog,
    private commissionSrv: CommissionService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getAgentTariffs();
  }

  getAgentTariffs() {
    this.commissionSrv.agentTariffs().subscribe(
      (data: ITariffs[]) => {
        this.tariffs = data;
        this.dataSource = this.tariffs;
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true })
  }

   // View tariff commission dialog
   openTariffViewDialog(item: any) {
    const dialogRef = this.dialog.open(ViewTariffComponent,
      { data: { tariff: item },
      autoFocus: false,
      maxHeight: '95vh'
     });
  }
}
