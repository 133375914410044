import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AgentService } from 'src/app/services/agent.service';

@Component({
  selector: 'app-news-filter',
  templateUrl: './news-filter.component.html',
  styleUrls: ['./news-filter.component.css']
})
export class NewsFilterComponent implements OnInit {

  dateFrom = this.data.dateFrom;
  dateTo = this.data.dateTo;
  statusNews = new FormControl(this.data.statusNews);
  
  @Output() someEvent = new EventEmitter<string>();

  constructor(public agentsSrv: AgentService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef) { }

  ngOnInit(): void {
    this.load();
  }

  load() {

  }

  searchNewsFilter(){
    let data = [];
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    data['statusNews'] = this.statusNews.value;
    this.bottomSheetRef.dismiss(data);
  }

  closeNewsFilter(){
    this.bottomSheetRef.dismiss();
  }
}
