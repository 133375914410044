import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommissionService } from 'src/app/services/commission.service';
import { ServiceService } from 'src/app/services/service.service';
import { UpperCommissionsEditComponent } from '../upper-commissions-edit/upper-commissions-edit/upper-commissions-edit.component';

@Component({
  selector: 'app-upper-commissions',
  templateUrl: './upper-commissions.component.html',
  styleUrls: ['./upper-commissions.component.css']
})
export class UpperCommissionsComponent implements OnInit {
  isCompleted: boolean = false;

  services: any[] = [];
  displayedColumns: string[] = ['name', 'action'];
  dataSource: any[] = [];

  constructor(public servicesSrv: ServiceService,
            private commissionSrv: CommissionService,
            public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getServices();
  }

  getServices() {
    this.servicesSrv.getUpperEditableServices().subscribe(
      (data: any[]) => {
        this.services = data;
        this.dataSource = this.services?.sort((a, b) => a.id - b.id);
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  // View upper commission
  openAgentUpperCommissionEditDialog(item: any) 
  {
    const dialogRef = this.dialog.open(UpperCommissionsEditComponent,
      { data: { service: item } });

    dialogRef.afterClosed().subscribe(result => {
        this.getServices();
    });
  }

}
