<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="mat-elevation-z0">
    <form [formGroup]="loginForm">
      <mat-card-content>
        <h1 class="mat-headline">{{'enter'| translate}}</h1>

        <mat-form-field fxFill>
          <input matInput placeholder="{{'email' | translate}}" type="email" formControlName="email">
          <mat-error *ngIf="f.email.errors">
            <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
            <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFill>
          <input [type]="hide ? 'password' : 'text'" matInput placeholder="{{'password' | translate}}" formControlName="password">
          <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="f.password.errors">
            <div *ngIf="f.password.errors.required">{{'password-is-required' | translate}}</div>
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <button mat-flat-button fxFill (click)="login()"
        color="primary">{{'come-in'| translate}}</button>
       <small> <a mat-button [routerLink]="'/recovery'">{{'recovery' | translate}}?</a></small>
      <!-- <small> <a mat-button [routerLink]="'/registration'">{{'registration' | translate}}</a></small> -->

    </form>
  </mat-card>
</div>
