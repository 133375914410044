import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PagesService } from 'src/app/services/pages.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  pageCode: string;
  pageInfo: any = [];
  isCompleted: boolean = false;
  constructor(private route: ActivatedRoute,
    private pagesSrv: PagesService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.route.params.subscribe(routeParams => {
      this.pageCode = routeParams.code;
      this.getPageInfo();
    });
  }

  getPageInfo(){
    this.isCompleted = false;
    this.pagesSrv.getPage(this.pageCode).subscribe(
      (data: any) => {
        this.pageInfo = data;
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

}
