import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { IRefund } from "src/app/interfaces/irefund";
import { DictionaryService } from "src/app/services/dictionary.service";
import { ExportService } from "src/app/services/export.service";
import { RefundService } from "src/app/services/refund.service";
import { environment } from "src/environments/environment";
import { RefundFilterComponent } from "./refund-filter/refund-filter.component";

@Component({
  selector: 'app-refund-request',
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', padding: '0 25px'})),
      state('expanded', style({height: '*', padding: '10px 25px 15px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class RefundRequestComponent implements OnInit {
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  globalCurrencySymbol = environment.globalCurrencySymbol;
  isCompleted: boolean;
  dataSource: MatTableDataSource<IRefund>;
  refundRequests: IRefund[] = [];
  refundStatuses: any;
  panelOpenState = false;
  displayedColumns: string[] = ['statusId', 'titleInfo','createdAt', 'expanderBlock'];
  expandedElement: IRefund | null;

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)))
  dateTo = new FormControl(new Date());
  statusId = new FormControl();
  statusTitle: string;
  transactionId = new FormControl();

  public transactionStatusTitle: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private refundSrv: RefundService,
    private bottomSheet: MatBottomSheet,
    private dictionarySrv: DictionaryService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private exportSrv: ExportService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(){
    this.getRefundStatuses();
    this.getRefundPayments();
  }

  getRefundStatuses(){
    this.dictionarySrv.refundRequestStatuses().subscribe(
      (data: any[]) => {
        this.refundStatuses = data;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    )
  }

  exportToExcel() {
    var result = this.exportSrv.translateExcel(this.dataSource.filteredData)
    this.exportSrv.exportToExcel(result, 'Report', 'filename.xlsx');
  }
  getRefundPayments(){
    this.isCompleted = false;
    let dateFromNew = new Date(this.dateFrom.value);
    let dateToNew  = new Date(this.dateTo.value);
    let json = {
      dateFrom : moment(dateFromNew).format("YYYY-MM-DDT00:00:00.000") + "Z",
      dateTo: moment(dateToNew).format("YYYY-MM-DDT00:00:00.000") + "Z",
      statusId : this.statusId?.value?.code ?? 0,
      id: this.transactionId?.value ?? 0 
    };
    this.refundSrv.getRefundRequests(json).subscribe(
      (data: IRefund[]) => {
        this.refundRequests = data;
        this.dataSource = new MatTableDataSource<IRefund>(this.refundRequests);
        this.dataSource.paginator = this.paginator;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }
 
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  openReportFilters(): void {
      const bottomSheetRef = this.bottomSheet.open(RefundFilterComponent,{
        data: { dateFrom: this.dateFrom, dateTo: this.dateTo, refundStatuses: this.refundStatuses, statusId: this.statusId, transactionId: this.transactionId}
      });
  
      bottomSheetRef.afterDismissed().subscribe((data) => {
          if(data && data.isClosed == false){
            this.dateFrom = data.dateFrom;
            this.dateTo = data.dateTo;
            this.statusId = data.statusId;
            this.transactionId = data.transactionId;
            this.getRefundPayments();
          }
      });
  }
  
  openDetails(expandedElement: any, element:any){
    this.expandedElement = expandedElement === element ? null : element;
  }
}
