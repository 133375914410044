<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'recovery' | translate}}</h1>
    <form [formGroup]="recoveryForm" novalidate (ngSubmit)="recovery()">
        <p>Код для восстановления пароля будет отправлен на почту</p>
        <mat-form-field fxFill>
            <input matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
                <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
                <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
        </mat-form-field>
        <button mat-flat-button fxFill color="primary" class="control__btn" type="submit"
            [disabled]="!recoveryForm.valid" cdkFocusInitial>{{'restore-password'| translate}}</button>
    </form>
</div>