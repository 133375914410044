<form [formGroup]="formDetails" fxFill>
    <mat-form-field fxFill>
      <input formControlName="number" placeholder="{{'account' | translate}}" matInput value="">
    </mat-form-field><br />
    <mat-form-field fxFill>
      <input formControlName="name" placeholder="{{'owner' |translate}}" matInput value="">
    </mat-form-field>
    <mat-form-field fxFill>
      <input formControlName="address" placeholder="{{'address' | translate}}" matInput value="">
    </mat-form-field><br />
    <div class="service-block-new" formArrayName="services">
      <div *ngFor="let service of services;let serviceIndex=index;">
        <div [formGroupName]="serviceIndex">
          <mat-expansion-panel disabled hideToggle [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div fxFill fxLayout="row" fxLayoutWrap fxLayoutGap="20px">               
                  <input formControlName="name" matInput value="" readonly class="color-black">
                  <mat-slide-toggle disableRipple="true" color="primary" checked="isWantToPay" fxLayoutAlign="end" formControlName="isWantToPay" (click)="$event.stopPropagation();togglePanel(serviceIndex)"></mat-slide-toggle>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>         
              <div formArrayName="tariffs">
                <div *ngFor="let tariff of getTariffsFor(serviceIndex);let tariffIndex=index;">
                  <ng-container [formGroupName]="tariffIndex">
                    <small>{{'tariff-price' | translate}}
                      {{tariff.get('value').value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</small>
                  </ng-container>
                </div>
              </div>
              <br />
              <div fxLayout="row" fxLayoutGap="20px" formArrayName="readings">
                <ng-container *ngFor="let reading of getReadingsFor(serviceIndex);let readingIndex=index;">
                  <ng-container [formGroupName]="readingIndex">
                    <mat-form-field fxFlex="50%" *ngIf="reading.get('type').value==2">
                      <input matInput (ngModelChange)="userChangedReading(serviceIndex)" tabindex="{{serviceIndex}}1" formControlName="count" value=""
                        placeholder="{{'current-readings' | translate}}" type="number">
                    </mat-form-field>
                    <mat-form-field fxFlex="50%" *ngIf="reading.get('type').value==1">
                      <input matInput (ngModelChange)="userChangedReading(serviceIndex)" tabindex="{{serviceIndex}}2" formControlName="count" value=""
                        placeholder="{{'prev-readings' | translate}}" type="number">
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </div>
              <div *ngIf="service.get('invoiceServicePaymentType').value != null">
                <mat-form-field fxFill *ngIf="service.get('isDeposit').value">
                  <input [readonly]="!service.get('invoiceServicePaymentType').value" tabindex="{{serviceIndex}}3" formControlName="deposit" placeholder="{{'service-deposit' | translate}}" matInput value=""
                    currencyMask (ngModelChange)="getServiceTotal(serviceIndex)">
                </mat-form-field>
                <mat-form-field fxFill *ngIf="service.get('isCredit').value">
                  <div fxLayout="row">
                    <mat-label>{{'service-credit' | translate}}</mat-label>
                    <mat-slide-toggle *ngIf="service.get('invoiceServicePaymentType').value" class="auto-height" labelPosition="before" disableRipple="true" color="primary" checked="isWantToPayCredit" formControlName="isWantToPayCredit" (click)="$event.stopPropagation();toggleCredit(serviceIndex)"></mat-slide-toggle>
                </div>
                  <input [readonly]="!service.get('invoiceServicePaymentType').value" formControlName="credit" tabindex="{{serviceIndex}}4" matInput value="" currencyMask (ngModelChange)="getServiceTotal(serviceIndex)">
                </mat-form-field>
    
                <mat-form-field fxFill *ngIf="service.get('isPenalty').value">
                  <div fxLayout="row">
                    <mat-label>{{'service-penalty' | translate}}</mat-label>
                    <mat-slide-toggle *ngIf="service.get('invoiceServicePaymentType').value" class="auto-height" labelPosition="before" disableRipple="true" color="primary" checked="isWantToPayPenalty" formControlName="isWantToPayPenalty" (click)="$event.stopPropagation();togglePenalty(serviceIndex)"></mat-slide-toggle>
                </div>
                  <input [readonly]="!service.get('invoiceServicePaymentType').value" formControlName="penalty" tabindex="{{serviceIndex}}5" matInput value="" currencyMask (ngModelChange)="getServiceTotal(serviceIndex)">
                </mat-form-field>
                <mat-form-field fxFill>
                  <input [readonly]="!service.get('invoiceServicePaymentType').value" (keyup)="setAccrued(serviceIndex)" tabindex="{{serviceIndex}}6" formControlName="total" placeholder="{{'service-total' | translate}}"
                    matInput currencyMask>
                  <mat-hint *ngIf="service.get('credit').value>0">{{'include-credit' | translate}}
                    {{service.get('credit').value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</mat-hint>
                  <mat-hint *ngIf="service.get('deposit').value>0">{{'include-deposit' | translate}}
                    {{service.get('deposit').value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</mat-hint>
                </mat-form-field>
              </div>
              <!--   </mat-card-content>
            </mat-card> -->
            </ng-container>         
          </mat-expansion-panel>
  
  
          <br />
        </div>
      </div>
    </div>
  </form>