<div fxLayoutAlign="center">
    <p *ngIf="!showButtonSend"><b>{{'refund-request' | translate}}</b></p>
    <p *ngIf="showButtonSend"><b>{{'cancel-payment-confirm'| translate}}</b></p>
</div>
<div>
    <span class="mat-small">{{'service'| translate}} : {{check?.Payload?.serviceName}}</span><br>
    <span class="mat-small" *ngFor="let parameter of check?.Payload?.parameters | keyvalue">
        {{parameter.key.toLowerCase() | translate}} : {{parameter.value}} <br>
    </span>
    <span class="mat-small">{{'sum'| translate}}: {{check?.Payload?.amount / 100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </span><br>
    <span class="mat-small" *ngIf="showButtonSend">{{'comment'| translate}}: {{refundComment.controls['comment'].value}}</span>
</div>

<form [formGroup]="refundComment" *ngIf="!showButtonSend">
    <mat-form-field class="comment-input pd-10">
        <mat-label>{{'comment'| translate}}</mat-label>
        <input  matInput #message maxlength="250" placeholder="{{'payment-cancellation-comment'| translate}}" formControlName="comment">
        <mat-hint align="end">{{message.value.length}} / 250</mat-hint>
    </mat-form-field>

    <div fxFill fxLayoutAlign="end" class="pd-10" fxLayoutGap="10px">        
        <button mat-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
            {{'cancel'| translate}}
        </button>
        
        <button mat-flat-button [disabled]="!refundComment.valid" (click)="confirmRefund()" color="primary">
            {{'apply'| translate}}
        </button>
    </div>
</form>

<div *ngIf="showButtonSend">
    <div fxLayoutAlign="end" fxLayoutGap="10px">
        <button mat-button [mat-dialog-close]="true">
            <mat-icon>cancel</mat-icon>
            {{'cancel'| translate}}
        </button>
        
        <button mat-flat-button (click)="sendRefund()" color="primary">
            <mat-icon>done</mat-icon>
            {{'send'| translate}}
        </button>
    </div>          
</div> 