<form [formGroup]="formDetails" fxFill>
    <div class="service-block-new" formArrayName="services">
      <div *ngFor="let service of services;let serviceIndex=index;">
        <div [formGroupName]="serviceIndex">
          <mat-card>
            <mat-card-header class="mar-card-header-add">
              <mat-card-title>
                <div fxFill fxLayout="row" fxLayoutWrap fxLayoutGap="20px">               
                  <label fxFlex="97%">{{service.get('name').value}}</label>
                  <mat-slide-toggle fxLayoutAlign="right" *ngIf="service.get('servicesSelectType').value == 1" color="primary" (change)="chooseService()" formControlName="enabled" [checked]="service.get('enabled').value"></mat-slide-toggle>
                  <mat-radio-button fxLayoutAlign="right" *ngIf="service.get('servicesSelectType').value == 2" color="primary" (change)="chooseServiceByRadiobutton(serviceIndex)"></mat-radio-button>
                </div>

                  <ng-container *ngFor="let servicesItem of getItemsFor(serviceIndex) | keyvalue">
                    <span class="mat-caption display-block" *ngIf="servicesItem.value.toString().length > 0">{{servicesItem.value}}</span>
                  </ng-container>
              </mat-card-title>
            </mat-card-header>
            <ng-container>         

              <div fxLayout="row" fxLayoutGap="20px" formArrayName="readings">
                <ng-container *ngFor="let reading of getReadingsFor(serviceIndex);let readingIndex=index;">
                  <ng-container [formGroupName]="readingIndex">
                    <mat-form-field fxFlex="50%" *ngIf="reading.get('type').value==2">
                      <input [readonly]="!service.get('invoiceServicePaymentType').value" matInput (ngModelChange)="userChangedReading(serviceIndex)" formControlName="count" value=""
                        placeholder="{{'current-readings' | translate}}" type="number" tabindex="{{serviceIndex}}1">
                    </mat-form-field>
                    <mat-form-field fxFlex="50%" *ngIf="reading.get('type').value==1">
                      <input [readonly]="!service.get('invoiceServicePaymentType').value" matInput (ngModelChange)="userChangedReading(serviceIndex)" formControlName="count" value=""
                        placeholder="{{'prev-readings' | translate}}" type="number" tabindex="{{serviceIndex}}2">
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </div>
              <mat-form-field fxFill *ngIf="service.get('isDeposit').value">
                <input [readonly]="!service.get('invoiceServicePaymentType').value" formControlName="deposit" placeholder="{{'service-deposit' | translate}}" matInput value=""
                  currencyMask (ngModelChange)="getServiceTotal(serviceIndex)" tabindex="{{serviceIndex}}3">
              </mat-form-field>
              <mat-form-field fxFill *ngIf="service.get('isCredit').value">
                <div fxLayout="row">
                    <mat-label>{{'service-credit' | translate}}</mat-label>
                    <mat-slide-toggle *ngIf="service.get('invoiceServicePaymentType').value" class="auto-height" labelPosition="before" disableRipple="true" color="primary" checked="isWantToPayCredit" formControlName="isWantToPayCredit" (click)="$event.stopPropagation();toggleCredit(serviceIndex)"></mat-slide-toggle>
                </div>
                <input [readonly]="!service.get('invoiceServicePaymentType').value" formControlName="credit" matInput value=""
                  currencyMask (ngModelChange)="getServiceTotal(serviceIndex)" tabindex="{{serviceIndex}}4">
              </mat-form-field>
  
              <mat-form-field fxFill *ngIf="service.get('isPenalty').value">
                <div fxLayout="row">
                    <mat-label>{{'service-penalty' | translate}}</mat-label>
                    <mat-slide-toggle *ngIf="service.get('invoiceServicePaymentType').value" class="auto-height" labelPosition="before" disableRipple="true" color="primary" checked="isWantToPayPenalty" formControlName="isWantToPayPenalty" (click)="$event.stopPropagation();togglePenalty(serviceIndex)"></mat-slide-toggle>
                </div>
                <input [readonly]="!service.get('invoiceServicePaymentType').value" tabindex="{{serviceIndex}}5" formControlName="penalty" matInput value=""
                  currencyMask (ngModelChange)="getServiceTotal(serviceIndex)">
              </mat-form-field>
              <mat-form-field fxFill>
                <input [readonly]="!service.get('invoiceServicePaymentType').value" tabindex="{{serviceIndex}}6" (keyup)="setAccrued(serviceIndex)" formControlName="total" placeholder="{{'service-total' | translate}}"
                  matInput currencyMask>
                <mat-hint *ngIf="service.get('credit').value>0">{{'include-credit' | translate}}
                  {{service.get('credit').value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</mat-hint>
                <mat-hint *ngIf="service.get('deposit').value>0">{{'include-deposit' | translate}}
                  {{service.get('deposit').value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</mat-hint>
              </mat-form-field>
              <!--   </mat-card-content>
            </mat-card> -->
            </ng-container>         
          </mat-card>
  
  
          <br />
        </div>
      </div>
    </div>
  </form>