<form [formGroup]="regionForm">
    <div class="region-selection-block">
        <mat-form-field fxFill>
            <mat-select class="region-selection-width" placeholder="" formControlName="region" (selectionChange)="regionChanged($event)">
                <mat-option *ngFor="let item of regions" [value]="item.code">
                    {{ item.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>