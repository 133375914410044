import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentReceiptService } from 'src/app/services/payment-receipt.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class CheckComponent implements OnInit {


  isCompleted: boolean = false;
  check: any;

  mailForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  });

  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private transactionSrv: TransactionService,
    private loaderService: LoaderService,
    private checkSrv: PaymentReceiptService
  ) { }

  ngOnInit(): void {
    this.getCheck();
  }
  // convenience getter for easy access to form fields
  get f() { return this.mailForm.controls; }

  getCheck() {
    this.isCompleted = false;

    this.transactionSrv.get(this.item.transaction.id).subscribe(
      data => {
        this.check = data;
      },
      error => {
        let message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
      }
    ).add(() => { this.isCompleted = true });
  }

  sendToMail(): void {
    if (this.mailForm.valid) {
      this.loaderService.display(true);
      this.checkSrv.send(this.mailForm.controls['email'].value, this.item.transaction.id)
        .subscribe(
          data => {
            let message = this.translate.instant('email-succesfully-send');
            this.openSnackBar(message, 'x');
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
          this.loaderService.display(false);
        });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
