<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'edit-personal-data'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

<form [formGroup]="agentForm" novalidate (ngSubmit)="update(agentForm.value)">
    <mat-form-field fxFill>
        <mat-select placeholder="{{'region' | translate}}" formControlName="region">
            <mat-option *ngFor="let item of regions" [value]="item.code">
                {{ item.title }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="agentForm.controls['region'].hasError('required')">
            {{'region-is-req' | translate}}
        </mat-error>
    </mat-form-field>

    <mat-form-field fxFill>
        <input matInput placeholder="{{'address'| translate}}" formControlName="address">
        <mat-error *ngIf="agentForm.controls['address'].hasError('required')">
          {{'address-is-req'| translate}}
        </mat-error>
      </mat-form-field>

    <span class="toggle-info">{{'setting-repay'| translate}}</span>
    <mat-slide-toggle class="toggle"  fxFill color="primary" formControlName="isCheckRepay" [checked]="agentForm.controls['isCheckRepay'].value">
        {{'is-check-repay'| translate}}
    </mat-slide-toggle>
    
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>

        <button mat-flat-button color="primary" type="submit" [disabled]="!agentForm.valid"
                [mat-dialog-close]="true" cdkFocusInitial>{{'save'| translate}}</button>
    </mat-dialog-actions>
</form>