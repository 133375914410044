<h2 mat-dialog-title fxLayoutAlign="center">{{ data.title }}</h2>
<mat-dialog-content>
  <div style="margin-bottom: 15px;" [innerHTML]="data.content"></div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" style="margin-bottom: -10px;">
    <button mat-flat-button color="primary" routerLink="/pages/contacts" *ngIf="data.contactBtn" [mat-dialog-close]="true">
        {{ data.contactBtn }} 
    </button>
    <button mat-flat-button color="primary" (click)="logout()" *ngIf="data.logoutBtn" [mat-dialog-close]="true">
        {{ data.logoutBtn }}
    </button>
</mat-dialog-actions>