import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from 'src/app/services/agent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upper-commission',
  templateUrl: './upper-commission.component.html',
  styleUrls: ['./upper-commission.component.css']
})
export class UpperCommissionComponent implements OnInit {
  
  isCompleted: boolean = false;
  displayedColumns: string[] = ['from', 'to', 'sum'];
  uppersFix: any;
  uppersPercent: any;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private agentSrv: AgentService
  ) { }

  ngOnInit(): void {
    this.getUpperCommission();
  }

  getUpperCommission() {
    this.isCompleted = false;
    this.uppersFix = [];
    this.uppersPercent = [];
    this.agentSrv.uppers(this.item.service.serviceId).subscribe(
      data => {
        this.uppersFix = data?.Fix;
        this.uppersPercent = data?.Percent;
      },
      error => {
        let message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
      }
      ).add(() => { this.isCompleted = true });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
