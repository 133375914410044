import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RefundService } from 'src/app/services/refund.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.css']
})
export class RefundDialogComponent implements OnInit {

  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  isCompleted : boolean = false;
  check: any;
  showButtonSend: boolean = false;
  refundComment = this.fb.group({
    comment: [null, [Validators.required, Validators.maxLength(250)]]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
  private transactionSrv: TransactionService,
  private refundSrv: RefundService,
  private fb: FormBuilder,
  private dialogConfirm: MatDialogRef<any>,
  public snackBar: MatSnackBar,
  private translate: TranslateService) { 
    
  }

  ngOnInit(): void {
    this.getCheck();
  }

  getCheck() {
    this.isCompleted = false;
    this.transactionSrv.get(this.item.transaction.id).subscribe(
      data => {
        this.check = data;
      },
      error => {
        if (error.status === 404) {
          this.openSnackBar(error.error, 'x');
          this.isCompleted = true;
        }
        else{
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
      }
    ).add(() => { this.isCompleted = true });
  }

  confirmRefund(){
    this.showButtonSend = true;
  }
  
  sendRefund(){
    let obj = {
      transactionId: this.item.transaction.id,
      agentComment : this.refundComment.get('comment').value,    
    };
    this.refundSrv.createRefund(obj).subscribe(
      (data: any[]) => {
        let message = this.translate.instant('refund-success-created');
        this.openSnackBar(message, 'x');
        this.dialogConfirm.close();
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        if(error.status === 409){
          let message = this.translate.instant('refund-transaction-already-exists');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            //let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(error?.error, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
