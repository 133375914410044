import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IAgent } from 'src/app/interfaces/agent/iagent';
import { AgentService } from 'src/app/services/agent.service';
import { DictionaryService } from 'src/app/services/dictionary.service';

@Component({
  selector: 'app-change-info',
  templateUrl: './change-info.component.html',
  styleUrls: ['./change-info.component.css']
})
export class ChangeInfoComponent implements OnInit {

  regions = [];
  agent: any;
  isCompleted: boolean = false;

  agentForm = this.fb.group({
    region: [this.item.agent.regionId, Validators.required],
    address: [this.item.agent.address, Validators.required],
    isCheckRepay: [this.item.agent.isCheckRepay]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    private translate: TranslateService,
    private dictionarySrv: DictionaryService,
    public snackBar: MatSnackBar,
    private agentSrv: AgentService) {

    }

    ngOnInit(): void {
      this.load();
    }
    load() {
      this.getRegions();
    }
  
    getRegions() {
      this.dictionarySrv.getRegions().subscribe(
        (data: any) => { this.regions = data; },
        error => {
          console.log(error);
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
      ).add(() => { this.isCompleted = true })
    }
  
   // convenience getter for easy access to form fields
    get f() { return this.agentForm.controls; }
    public hasError = (controlName: string, errorName: string) => {
      return this.agentForm.controls[controlName].hasError(errorName);
    }
  
    public update = (cityForm) => {
      if (this.agentForm.valid) {
        this.execute(cityForm);
      }
    }
  
    private execute = (data) => {
      let json: IAgent = {            
        regionId: data.region,
        address: data.address,
        isCheckRepay: data.isCheckRepay
      }
  
      this.agentSrv.updateAgent(json)
        .subscribe(
          data => {
            let message = this.translate.instant('data-success-updated');
            this.openSnackBar(message, 'x');    
          },
          error => {
            if (error.status === 409) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 400) {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
              else
                if (error.status === 500) {
                  let message = this.translate.instant('service-is-temporarily-unavailable');
                  this.openSnackBar(message, 'x');
                }
                else {
                  let message = this.translate.instant('an-error-occurred-while-processing');
                  this.openSnackBar(message, 'x');
                }
          }
        );
    }
  
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000,
      });
    }

}
