<h2 class="mat-headline">{{'redefine-upper-commission'| translate}}</h2>
<form [formGroup]="upperForm" novalidate (ngSubmit)="update(upperForm.value)">
  <mat-dialog-content class="mat-typography">
    <h3 *ngIf="item">{{'service' | translate}} : {{item.service.name}}</h3>
    <p>{{'redefine-upper-commission-desc'|translate}}</p>

    <button color="primary" mat-button (click)="addItemFix()" class="add-rule-btn">
      <mat-icon>add box</mat-icon>{{'add-fix-rule'| translate}}
    </button>

    <button color="primary" type="button" mat-button (click)="clearAllFix()" class="clear-all-btn">
      <mat-icon>remove_circle</mat-icon>
      {{'clear-all'| translate}}
    </button>

    <div formArrayName="fix" *ngIf="isCompleted">
      <!-- loop throught units -->
      <div *ngFor="let item of upperForm.get('fix')['controls']; let i=index" >

        <!-- row divider show for every nex row exclude if first row -->
         <mat-divider *ngIf="upperForm.get('fix')['controls'].length > 1 && i > 0"></mat-divider><br> 

        <!-- group name in this case row index -->
        <div [formGroupName]="i" fxFill>
          <div fxLayout="row" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="left" >

            <!-- from sum -->
            <mat-form-field fxFlex="20%" fxFlex.xs="25%">
              <input currencyMask matInput placeholder="{{'from'| translate}}" formControlName="from" required>
            </mat-form-field>

            <!-- to sum -->
            <mat-form-field fxFlex="20%" fxFlex.xs="25%">
              <input currencyMask matInput placeholder="{{'to'| translate}}" formControlName="to" required>
            </mat-form-field>

            <!-- unit fix price input field, calculated and not editable -->
            <div class="sum-block" fxFlex.xs="40%" fxLayoutGap="10px">
              <mat-form-field fxFlex.xs="70%">
                <input currencyMask matInput placeholder="{{'sum'| translate}}" formControlName="sum">

                <mat-hint>
                    {{'min'| translate}}: {{minFix}}, {{'max'| translate}}: {{maxFix}}
                </mat-hint>
              </mat-form-field>

              <button mat-icon-button color="primary" (click)="removeItemFix(i)" fxFlex.xs="15%">
                <mat-icon>delete forever</mat-icon>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <button color="primary" mat-button (click)="addItemPercent()" class="add-rule-btn" style="margin-top: 20px;">
      <mat-icon>add box</mat-icon>{{'add-percent-rule'| translate}}
    </button>

    <button color="primary" type="button" mat-button (click)="clearAllPercent()" class="clear-all-btn">
      <mat-icon>remove_circle</mat-icon>
      {{'clear-all'| translate}}
    </button>

    <div formArrayName="percent" *ngIf="isCompleted">
      <!-- loop throught units -->
      <div *ngFor="let item of upperForm.get('percent')['controls']; let i=index" >

        <!-- row divider show for every nex row exclude if first row -->
         <mat-divider *ngIf="upperForm.get('percent')['controls'].length > 1 && i > 0"></mat-divider><br> 

        <!-- group name in this case row index -->
        <div [formGroupName]="i" fxFill>
          <div fxLayout="row" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="left" >

            <!-- from sum -->
            <mat-form-field fxFlex="20%" fxFlex.xs="25%">
              <input currencyMask matInput placeholder="{{'from'| translate}}" formControlName="from" required>
            </mat-form-field>

            <!-- to sum -->
            <mat-form-field fxFlex="20%" fxFlex.xs="25%">
              <input currencyMask matInput placeholder="{{'to'| translate}}" formControlName="to" required>
            </mat-form-field>

            <!-- unit percent price input field, calculated and not editable -->
            <div class="sum-block" fxFlex.xs="40%" fxLayoutGap="10px">
              <mat-form-field fxFlex.xs="70%">
                <input type="number" matInput placeholder="{{'percent'| translate}}" formControlName="sum">

                <mat-hint>
                    {{'min'| translate}}: {{minPercent}}, {{'max'| translate}}: {{maxPercent}}
                </mat-hint>
              </mat-form-field>

              <button mat-icon-button color="primary" (click)="removeItemPercent(i)" fxFlex.xs="15%">
                <mat-icon>delete forever</mat-icon>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="!upperForm.valid"
      cdkFocusInitial>{{'save'| translate}}</button>
  </mat-dialog-actions>
</form>