import { ConstantPool } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { count } from 'rxjs/operators';
import { ICommissionProfile } from 'src/app/interfaces/services/icommission-profile';
import { AgentService } from 'src/app/services/agent.service';
import { CommissionService } from 'src/app/services/commission.service';

@Component({
  selector: 'app-upper-commissions-edit',
  templateUrl: './upper-commissions-edit.component.html',
  styleUrls: ['./upper-commissions-edit.component.css']
})
export class UpperCommissionsEditComponent implements OnInit {

  upperForm: FormGroup = this.fb.group(
                              {
                                fix: [],
                                percent: [],
                          });
  items: FormArray;
  services = [];
  upperArray: any[] = [];
  isCompleted: boolean = false;
  numberPatern = '^[0-9.,]+$';
  minFix: number = 0;
  maxFix: number = 490000;
  minPercent: number = 0;
  maxPercent: number = 100;

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
            private fb: FormBuilder,
            private translate: TranslateService,
            public snackBar: MatSnackBar,
            private commissionSrv: CommissionService,
            private agentSrv: AgentService,
            public dialog: MatDialog) {
  }

  ngOnInit(): void {
      this.getUpperCommission();    
  }

  getUpperCommission() {
    this.agentSrv.uppers(this.item.service.id).subscribe(
      data => {
          this.minFix = data?.MinFix ?? data?.DefaultMinFix ?? this.minFix;
          this.maxFix = data?.MaxFix ?? data?.DefaultMaxFix ?? this.maxFix;
          this.minPercent = data?.MinPercent ?? data?.DefaultMinPercent ?? this.minPercent;
          this.maxPercent = data?.MaxPercent ?? data?.DefaultMinPercent ?? this.maxPercent;

          this.upperForm = this.fb.group(
            {
              fix: this.fb.array(this.getCommissionValues(data?.Fix, 'fix')),
              percent: this.fb.array(this.getCommissionValues(data?.Percent, 'percent')),
            }
          );

          this.upperForm.get("fix").markAllAsTouched();
          this.upperForm.get("percent").markAllAsTouched();
      },
      error => {
        let message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
      }
    ).add(() => { this.isCompleted = true });
  }

  getCommissionValues(json, commission_type){
    if(json && json.length > 0){
        if(commission_type == "percent"){
            return json.map(jsonItem => this.fb.group({
                from: [Number(jsonItem.from), [Validators.required, Validators.pattern(this.numberPatern)]],
                to: [Number(jsonItem.to), [Validators.required, Validators.pattern(this.numberPatern)]],
                sum: [Number(jsonItem.sum), [Validators.required, Validators.pattern(this.numberPatern), Validators.min(this.minPercent), Validators.max(this.maxPercent)]]
            }));
        }
        else{
          return json.map(jsonItem => this.fb.group({
                from: [Number(jsonItem.from), [Validators.required, Validators.pattern(this.numberPatern)]],
                to: [Number(jsonItem.to), [Validators.required, Validators.pattern(this.numberPatern)]],
                sum: [Number(jsonItem.sum), [Validators.required, Validators.pattern(this.numberPatern), Validators.min(this.minFix), Validators.max(this.maxFix)]]
          }));
        }
    }
    else{
      return [];
    }
  }

  createItem(commission_type): FormGroup {
    if(commission_type == 'percent'){
        return this.fb.group({
          from: [0, [Validators.required,Validators.pattern(this.numberPatern)]],
          to: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          sum: [0, [Validators.required, Validators.pattern(this.numberPatern), Validators.min(this.minPercent), Validators.max(this.maxPercent)]]
      });
    }
    else{
      return this.fb.group({
          from: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          to: [0, [Validators.required, Validators.pattern(this.numberPatern)]],
          sum: [0, [Validators.required, Validators.pattern(this.numberPatern), Validators.min(this.minFix), Validators.max(this.maxFix)]]
      });
    }
  }
  
  addItemFix(){
    this.items = this.upperForm.get('fix') as FormArray;
    this.items.push(this.createItem('fix'));
    return false;
  }

  removeItemFix(i){
      const control = <FormArray>this.upperForm.controls['fix'];
      control.removeAt(i);
      return false;
  }

  clearAllFix() {
    const control = <FormArray>this.upperForm.controls['fix'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  };
 
  addItemPercent(){
    this.items = this.upperForm.get('percent') as FormArray;
    this.items.push(this.createItem('percent'));
    return false;
  }

  removeItemPercent(i){
    const control = <FormArray>this.upperForm.controls['percent'];
    control.removeAt(i);
    return false;
  }

  clearAllPercent() {
    const control = <FormArray>this.upperForm.controls['percent'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  };

  get f() { return this.upperForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.upperForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.upperForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {

    let json: any = {
      serviceId: this.item.service.id,
      fix: data.fix,
      percent: data.percent
    }

     this.commissionSrv.updateUpperCommissionsByService(json)
      .subscribe(
        data => {
            const dialogRef = this.dialog.closeAll();
            let message = this.translate.instant('upper-commissions-updated');
            this.openSnackBar(message, 'x');
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('incorrect-upper-commission-values');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
