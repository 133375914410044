import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ChangeInfoComponent } from './change-info/change-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreditAgentComponent } from './credit-agent/credit-agent.component';
import { DebetAgentComponent } from './debet-agent/debet-agent.component';
/* import { RedefineUpperCommissionComponent } from './redefine-upper-commission/redefine-upper-commission.component'; */
import { TariffChangeComponent } from './tariff-change/tariff-change.component';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

  isCompleted: boolean = false;
  isCompletedSubs: boolean = false;
  agents: any[] = [];
  parentAgents: any[] = [];
  displayedColumnsMe: string[] = ['company-phone', 'action'];
  displayedColumnsParent: string[] = ['company-phone', 'action']; 
  displayedColumns: string[] = ['company-phone', 'action'];
  dataSource: any[] = [];
  parentDataSource: any[] = [];
  agentDataSource: any = [];
  agent: any;
  month: Number = new Date().getMonth();
  startOfNextMonth = moment().add(1, 'M').startOf('month').format('DD.MM.YYYY');
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(public authService: AuthService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentSrv: AgentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getAgent();
    this.getAgents();
    this.getParentAgents();
  }

  getAgent() {
    this.isCompleted = false;
    this.agent =[];
    this.agentDataSource=[];
    this.agentSrv.agent().subscribe(
      (data: any) => { 
        this.agent.push(data);
        this.agentDataSource = this.agent;
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  getParentAgents() {
    this.isCompleted = false;
    this.parentAgents =[];
    this.parentDataSource=[];
    this.agentSrv.parentAgents().subscribe(
      (data: any[]) => {
        this.parentAgents.push(data);
        this.parentDataSource = this.parentAgents;
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
     ).add(() => { this.isCompleted = true });
}

  getAgents() {
    this.isCompletedSubs = false;
    this.agents =[];
    this.dataSource=[];
    this.agentSrv.agents().subscribe(
      (data: any[]) => {
        this.agents = data;
        this.dataSource = this.agents?.sort((a, b) => a.id - b.id);
      },
      error => {
        if (error.status === 404) {
          let message = this.translate.instant('not-found');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
     ).add(() => { this.isCompletedSubs = true });
}

  // View tariff change dialog
  openAgentDialog(item: any) {
    const dialogRef = this.dialog.open(TariffChangeComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe(result => {
      this.getAgents();
     // console.log(`Dialog result: ${result}`);
    });
  }

  // Вызов формы дать овер субагенту
  openAgentCreditDialog(item: any) {
    const dialogRef = this.dialog.open(CreditAgentComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe(result => {
        this.getAgent();
    }); 
  }

  // Вызов формы возврат овер субагенту
  openAgentDebetDialog(item: any) {
    // const dialogRef = this.dialog.open(DebetAgentComponent,
    //   { data: { agent: item } });

    //   dialogRef.afterClosed().subscribe(result => {
    //     this.getAgent();
    // }); 
  }

  // Вызов формы изменения личных данных
  openAgentChangeInfoDialog(item: any) {
    const dialogRef = this.dialog.open(ChangeInfoComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe((data: any) => {
        this.getAgent();
    });
  }

  // Вызов формы смены пароля
  openAgentChangePasswordDialog(item: any) {
    const dialogRef = this.dialog.open(ChangePasswordComponent,
      { data: { agent: item } });
  }

  // View redefine upper commission
/*   openAgentUpperCommissionDialog(item: any) {
    const dialogRef = this.dialog.open(RedefineUpperCommissionComponent,
      { data: { agent: item } });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  } */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  updateMyInfo(){
    this.getAgent();
  }

  updateMyAgents(){
    this.getAgents();
  }
}

