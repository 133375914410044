<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{item.tariff.name}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" *ngIf="isCompleted">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- serviceName Column -->
        <ng-container matColumnDef="serviceName">
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-body">{{element.serviceName }} </span>
            </td>
        </ng-container>
        
        <!-- allAmount Column -->
        <ng-container matColumnDef="allAmount">
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-caption">{{'itoPercent' | translate}}: {{element.itoPercent /100}}</span> <br>
                <span class="mat-caption">{{'itoFix' | translate}}: {{element.itoFix /100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span> <br>
                <span class="mat-caption">{{'lowerPercent' | translate}}: {{element.lowerPercent/100}}</span> <br>
                <span class="mat-caption">{{'lowerFix' | translate}}: {{element.lowerFix /100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span> <br>
            </td>
        </ng-container>
       
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'| translate}}</button>
</mat-dialog-actions>