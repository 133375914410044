import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommissionService } from 'src/app/services/commission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-tariff',
  templateUrl: './view-tariff.component.html',
  styleUrls: ['./view-tariff.component.css']
})
export class ViewTariffComponent implements OnInit {

  isCompleted: boolean = false;
  dataSource: any[] = [];
  displayedColumns: string[] = ['serviceName', 'allAmount'];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    private commissionSrv: CommissionService,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getValuesByTariff(this.item.tariff.id);
  }

  getValuesByTariff(tariffId) {
    this.commissionSrv.getServicesLowers(tariffId).subscribe(
      (data: any) => { 
        this.dataSource = data;
      },
      error => {
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
