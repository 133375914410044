import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { TransferTypes } from '../enums/TransferEnum';

@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  host: string;
  constructor(private http: HttpClient) { this.host = environment.api_url + '/transfers' }

  transfers(fromAgentId: string, toAgentId: string, amount: number, comment: string, transferType: TransferTypes): Observable<any> {
    let url = this.host;
    
    let json: any = {
      amount: amount,
      comment: comment,
      fromAgentId: fromAgentId,
      toAgentId: toAgentId, 
      transferType: transferType 
    }   
    return this.http.post(url, json);
  }
}
