<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'payment-failed-title'| translate}}!</h1>
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <ng-container *ngIf="isCompleted">
        <mat-list dense fxFill>
            <mat-list-item>
                <span fxFlex="50" class="mat-body-1">{{'check-service-name' | translate}}:
                </span>
                <span fxFlex="50" class="mat-body-1">{{check?.Payload?.serviceName}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">{{'check-created' | translate}}:
                </div>
                <div fxFlex="50" class="mat-body-1">{{check?.CreatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
        <mat-list dense *ngFor="let applicant of check?.Payload?.parameters | keyvalue" fxFill fxFlex="360">
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">
                    {{applicant.key | lowercase | translate}}:
                </div>
                <div fxFlex="50" class="mat-body-1">
                    {{applicant.value}}
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>

        <mat-list dense *ngFor="let service of check?.Payload?.bill?.Services" fxFill>
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">
                    {{service.Name }}:
                </div>
                <div fxFlex="50" class="mat-body-1">
                    {{service.Total/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>

        <mat-list dense fxFill>
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">{{'support' | translate}}:
                </div>
                <div fxFlex="50" class="mat-body-1">
                    {{'support-description' | translate}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">{{'check-upper-commission' | translate}}:
                </div>
                <div fxFlex="50" class="mat-body-1">
                    {{check?.Payload?.fee/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div fxFlex="50" class="mat-body-1">{{'check-amount' | translate}}:
                </div>
                <div fxFlex="50" class="mat-body-1">
                    {{check?.Payload?.amount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div fxFlex="50">
                    <h1 class="mat-headline">{{'sum' | translate}}:
                        {{check?.Payload?.accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
                    </h1>
                </div>
            </mat-list-item>

        </mat-list>

    </ng-container>
    <p> {{'payment-success-description' | translate}} </p>
    <a fxFill mat-flat-button color="primary" class="control__btn"
        href="/agent/reports/transactions">{{'payments-history'| translate}}</a>
</div>