import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['ru', 'en', 'kz', 'kg']);
    // включить, если появится переключатель языков
    // let currentLang = localStorage.getItem("browser_lang");
    // const browserLang = currentLang ?? environment.defaultLang;
    const browserLang = environment.defaultLang;
    translate.use(browserLang.match(/ru|en|kz|kg/) ? browserLang : environment.defaultLang);
    // localStorage.setItem("browser_lang",browserLang);
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}