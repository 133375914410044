import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAgent } from '../interfaces/agent/iagent';
import { IRegistration } from '../interfaces/agent/iregistration';
import { ILower } from '../interfaces/commission/Ilower';
import { IAccount } from '../interfaces/iaccount';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  host: string;
  current_user: any;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/agent'
  }

  agent(): Observable<any> {
    let url = this.host;
    return this.http.get<any>(url);
  }

  localSetAgent(): void {
    if (localStorage.getItem('current_user') == null) {
      let url = this.host;
      this.http.get<any>(this.host).subscribe(res => {
        this.setSession(res);
      });
    }
  }

  private setSession(user) {
    localStorage.setItem('current_user', JSON.stringify(user));
  }

  getCurrentUser(): Observable<any> {
    if (localStorage.getItem('current_user')) {
      const data = localStorage.getItem('current_user');
      this.current_user = JSON.parse(data);
    }
    return this.current_user;
  }
  
  create(data: IRegistration): Observable<any> {
    return this.http.post(this.host, data);
  }

  createSubAgent(data: IRegistration): Observable<any> {
    return this.http.post(this.host + '/sub', data);
  }

  agents(): Observable<any[]> {
    let url = this.host + '/subs';
    return this.http.get<any[]>(url);
  }

  agentsBranch(): Observable<any[]> {
    let url = this.host + '/branch';
    return this.http.get<any[]>(url);
  }

  parentAgents(): Observable<any[]> {
    let url = this.host + '/parent';
    return this.http.get<any[]>(url);
  }

  account(): Observable<IAccount> {
    let url = this.host + '/account';
    return this.http.get<IAccount>(url);
  }

  updateAgent(data: IAgent): Observable<any> {
    return this.http.put(this.host, data);
  }
  
  updateTemplate(data: any): Observable<any> {
    let url = this.host + '/template';
    return this.http.put(url, data);
  }

  rates(): Observable<ILower[]> {
    let url = this.host + '/rates';
    return this.http.get<ILower[]>(url);
  }

  uppers(serviceId: number): Observable<any> {
    let url = this.host + '/uppers/'+serviceId;
    return this.http.get<any>(url);
  }

  lowers(serviceId: number): Observable<any> {
    let url = this.host + '/lowers/'+serviceId;
    return this.http.get<any>(url);
  }

  // Сервис смена пароля
  changepassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    let url = this.host + '/change-password';
    let json =
    {
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    return this.http.post<any>(url, json);
  }
}