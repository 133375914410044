import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MustMatch } from 'src/app/helpers/must-match';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordManagerService } from 'src/app/services/password-manager.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  isCompleted: boolean = false;
  hide = true;
  currentUser: any;
  agent: any;
  agentForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
    oldPassword: [null, Validators.required],
    newPassword: [null, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    repeatNewPassword: [null, Validators.required]
  },
  {
    validator: MustMatch('newPassword', 'repeatNewPassword')
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private agentSrv: AgentService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAgent();
    this.getAuthUser();
    this.isCompleted = true;
  }

  getAgent() {
    this.agentSrv.agent().subscribe(
      (data: any) => {
        this.agent = data;
      },
      error => console.log(error)
    )
  }

  getAuthUser(){
    this.authService.getAuthUser().subscribe(
     (data: any) => {
       this.currentUser = data;
     },
     error => {
       let message = this.translate.instant('service-is-temporarily-unavailable');
       this.openSnackBar(message, 'x');
     }
   )
 }

  // convenience getter for easy access to form fields
  get f() { return this.agentForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.agentForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.agentForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.agentSrv.changepassword(this.currentUser.name, data.oldPassword, data.newPassword)
      .subscribe(
        data => {
          const dialogRef = this.dialog.closeAll();
          let message = this.translate.instant('success-change-password');
          this.openSnackBar(message, 'x');
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 400) {
            let message = this.translate.instant('old-password-incorrect');
            this.openSnackBar(message, 'x');
          }
          else{
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        }
      );
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
