<h2 mat-dialog-title fxLayoutAlign="center">{{ 'warning' | translate }}!</h2>
<mat-dialog-content>
    <div style="margin-bottom: 15px;">
        <p  fxLayoutAlign="center">{{ 'repay-service-continue' | translate }} </p>
    </div>
    <div class="transaction-info">
        <p><b>{{ 'receipt-number' | translate }}:</b> {{dataSource.transaction.Id }} </p>
        <p><b>{{ 'report-accepted' | translate }}:</b> {{dataSource.transaction.Accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </p>
        <p><b>{{ 'userAmount' | translate }}:</b> {{dataSource.transaction.Amount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </p>
        <p><b>{{ 'upperCommissionAmount' | translate }}:</b> {{dataSource.transaction.UpperCommission/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}} </p>
        <p><b>{{ 'service' | translate }}:</b> {{dataSource.transaction.ServiceName }}</p>
        <p><b>{{ 'date' | translate }}:</b> {{dataSource.transaction.CreatedAt | date : 'dd/MM/yyyy HH:mm' }}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" style="margin-bottom: 0px;" >
    <button mat-button mat-dialog-close (click)="closeButton()" >{{'cancel'| translate}}</button>
    <button mat-flat-button color="primary" (click)="submitButton()" >
        {{ 'confirm-payment' | translate }}
    </button>
</mat-dialog-actions>


