import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRefund } from '../interfaces/irefund';

@Injectable({
  providedIn: 'root'
})
export class RefundService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/refund'
  }

  createRefund(json:any){
    let url = this.host;
    return this.http.post<any>(url, json);
  }

  getRefundRequests(json:any){
    let url = this.host + "/report";
    return this.http.post<IRefund[]>(url, json);
  }
}
