import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/service'
  }

  categories(regionId: number): Observable<any[]> {
    let url = this.host;
    return this.http.get<any[]>(url + '/category' + '/' + regionId);
  }

  services(categoryId: string, regionId: number): Observable<any[]> {
    let url = this.host + '/' + categoryId + '/' + regionId;
    return this.http.get<any[]>(url);
  }

  get(): Observable<any[]> {
    let url = this.host;
    return this.http.get<any[]>(url);
  }

  getUpperEditableServices(): Observable<any[]> {
    let url = this.host + "/get-upper-editable-services";
    return this.http.get<any[]>(url);
  }

  top(): Observable<any[]> {
    let url = this.host + "/top";
    return this.http.get<any[]>(url);
  }

  addTopService(serviceId: number){
    let url = this.host + '/add-top-service';
    return this.http.post<any[]>(url, serviceId);
  }

  removeTopService(serviceId: number){
    let url = this.host + '/remove-top-service';
    return this.http.post<any[]>(url, serviceId);
  }

  search(query:string): Observable<any[]> {
    const url = this.host + '/' + query;
    return this.http.get<any[]>(url);
  }
}
