<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'two-factor-authorize'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="twoFactorForm" novalidate (ngSubmit)="update(twoFactorForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!twoFactorForm.valid"
                [mat-dialog-close]="true" cdkFocusInitial>{{'save'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>