import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAgentsReport } from '../interfaces/agent/iagents-report';
import { IProvidersReport } from '../interfaces/agent/iproviders-report';
import { IReportTransactions } from '../interfaces/agent/itransactions';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  host: string;
  constructor(private http: HttpClient) { this.host = environment.api_url + '/report' }

  // Отчет по провайдерам
  providersReport(account: string, dateFrom: Date, dateTo: Date): Observable<IProvidersReport[]> {
    
    var dateFromNew = null;
    var dateToNew = null;
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.999") + "Z";
    }

    let json : any = {
      agentId: account,
      from: dateFromNew,
      to: dateToNew
    }

    let url = this.host + '/services-report'
    return this.http.post<IProvidersReport[]>(url, json);
  }

  // Отчет по агентам
  agentsReport(account: string, dateFrom: Date, dateTo: Date, serviceId: number): Observable<IAgentsReport[]> {
    
    var dateFromNew = null;
    var dateToNew = null;
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.999") + "Z";
    }

    let json : any = {
      agentId: account,
      from: dateFromNew,
      to: dateToNew,
      serviceId: serviceId ?? 0
    }

    let url = this.host + '/agents-report'
    return this.http.post<IAgentsReport[]>(url, json);
  }

  // Отчет по транзакциям
  transactionsReport(account: string, dateFrom: Date, dateTo: Date, type: number, status: number, serviceId: number, paymentParameter: string): Observable<IReportTransactions[]> {
    let url = this.host + '/transactions';
    var dateFromNew = null;
    var dateToNew = null;
    if(dateFrom != null){
      dateFromNew = moment(dateFrom).format("YYYY-MM-DDT00:00:00.000") + "Z";
    }
    if(dateTo != null){
      dateToNew = moment(dateTo).format("YYYY-MM-DDT23:59:59.999") + "Z";
    }

    if(status != null){
        status = Number(status);
    }
    let json: any = {
      agentId: account,
      type: type,     
      status: status,
      from: dateFromNew,   
      to: dateToNew,
      serviceId: serviceId,
      params: paymentParameter
    }   
    return this.http.post<IReportTransactions[]>(url, json);
  }
}
