import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ILower } from 'src/app/interfaces/commission/Ilower';
import { IAccount } from 'src/app/interfaces/iaccount';
import { AgentService } from 'src/app/services/agent.service';
import { CommissionService } from 'src/app/services/commission.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-add-tariff',
  templateUrl: './add-tariff.component.html',
  styleUrls: ['./add-tariff.component.css']
})
export class AddTariffComponent implements OnInit {

  isCompleted: boolean = false;
  lowers: ILower[] = [];
  tariffForm: FormGroup;
  
  tariffInfo: any;
  data: any[];
  incorrectServiceList: any[];
  previousIndexTarriff: number = -1;
  agent: any;
  categories: any;
  panelOpenState = false;
  previousCategory: any = null;
  currentCategoryIndex: number = 0; 

  constructor(private fb: FormBuilder,
              public snackBar: MatSnackBar,
              private translate: TranslateService,
              private commissionSrv: CommissionService,
              public dialog: MatDialog,
              private serviceSrv: ServiceService,
              private agentSrv: AgentService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getAgentTariff();
  }


  get tariffValues() {
    return (<FormArray>this.tariffForm.get('tariffValues')).controls;
  }

  getAgent(){
    this.agentSrv.agent().subscribe((data: any) =>{
      this.agent = data;
      this.getCategory();
    },
      error => {
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    )
  }

  getCategory(){
    this.serviceSrv.categories(this.agent.regionId).subscribe((data: any) =>{
      for (let index = 0; index < data.length; index++) {
        data[index]["panelOpenState"] = false;
      }
      this.categories = data;
    },
      error => {
        this.isCompleted = true; 
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true });
  }

  getAgentTariff() {
    this.commissionSrv.agentTariff().subscribe(
      (data: any) => { 
        this.tariffInfo = data;
        this.getValuesByTariff(this.tariffInfo.id);
        this.getAgent();
      },
      error => {
        this.isCompleted = true; 
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    )
  }

  getValuesByTariff(tariffId) {
    this.commissionSrv.getServicesLowers(tariffId).subscribe(
      (data: any) => { 
        this.lowers = data;

        this.tariffForm = this.fb.group({
            name: new FormControl("", Validators.required),
            tariffValues: this.fb.array(this.getTariffValues())
        });
      },
      error => {
        if (error.status === 409) {
          let message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
        else
          if (error.status === 400) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
      }
    ).add(() => { this.isCompleted = true })
  }

  // convenience getter for easy access to form fields
  get f() { return this.tariffForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.tariffForm.controls[controlName].hasError(errorName);
  }

  public update = (formContent) => {
    if (this.tariffForm.valid) {
      this.execute(formContent);
    }
  }

  private execute = (data) => {
    this.incorrectServiceList = null;

    let json: any = {
      name: data.name,
      tariffValues: data.tariffValues
    }

    this.commissionSrv.addTariff(json)
      .subscribe(
        data => {
          let message = this.translate.instant('tariff-successfully-added');
          this.openSnackBar(message, 'x');    
          window.location.href = "/settings/tariffs";
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant(error.error.message);
              this.openSnackBar(message, 'x');
              this.incorrectServiceList = error.error.incorrectServiceList;
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }

  setEditableCommissions(index) {
    if (this.previousIndexTarriff == -1 || (<FormArray>this.tariffForm.get('tariffValues')).controls[this.previousIndexTarriff]?.valid) {
      (<FormArray>this.tariffForm.get('tariffValues')).controls[this.previousIndexTarriff]?.get("isEdit").setValue(false);
      (<FormArray>this.tariffForm.get('tariffValues')).controls[index].get("isEdit").setValue(true);
      this.previousIndexTarriff = index;
    }
  }

  setOpenOrCloseCategory(category) {
    if (this.categories.filter(f => f.id == this.currentCategoryIndex)[0]) {
      this.categories.filter(f => f.id == this.currentCategoryIndex)[0]["panelOpenState"] = false;
    }
    this.categories.filter(f => f.id == category.id)[0]["panelOpenState"] = true;
    this.currentCategoryIndex = category.id;
  }

  saveCommissions(index) {
    if ((<FormArray>this.tariffForm.get('tariffValues')).controls[index].valid) {
      (<FormArray>this.tariffForm.get('tariffValues')).controls[index].get("isEdit").setValue(false);
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }


  getTariffValues() {
    return this.lowers.map(lower => this.fb.group({
      serviceId: new FormControl(lower.serviceId, Validators.required),
      serviceName: new FormControl(lower.serviceName, Validators.required),
      itoPercent: new FormControl(lower.itoPercent / 100, [Validators.required, Validators.max(lower.itoPercent / 100)]),
      itoFix: new FormControl(lower.itoFix / 100, [Validators.required, Validators.max(lower.itoFix / 100)]),
      lowerPercent: new FormControl(lower.lowerPercent / 100, [Validators.required, Validators.min(0), Validators.max(lower.lowerPercent / 100)]),
      lowerFix: new FormControl(lower.lowerFix / 100, [Validators.required, Validators.min(0), Validators.max(lower.lowerFix / 100)]),
      maxItoPercent: new FormControl(lower.itoPercent / 100),
      maxItoFix: new FormControl(lower.itoFix / 100),
      maxLowerPercent: new FormControl(lower.lowerPercent / 100),
      maxLowerFix: new FormControl(lower.lowerFix / 100),
      isEdit: new FormControl(false, Validators.required),
      categoryName: new FormControl(lower.categoryName, Validators.required)
    }));
  }

}
