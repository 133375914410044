<mat-nav-list>
    <mat-form-field fxFill mat-list-item>
        <mat-select placeholder="{{'agent' | translate}}"  [formControl]="selectedAgent">
            <mat-option *ngFor="let option of agents" [value]="option">
                {{option.company}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom"
            placeholder="dateFrom" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [formControl]="dateTo"
            placeholder="{{'dateTo'| translate}}" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'transactionType' | translate}}</mat-label>
        <mat-select class="region-selection-width" placeholder="{{'transactionType' | translate}}" [formControl]="transactionType">
            <mat-option>{{'all' | translate}}</mat-option>
            <mat-option *ngFor="let item of transactionTypes" [value]="item.code">
                {{ item.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'transactionStatus' | translate}}</mat-label>
        <mat-select class="region-selection-width" placeholder="{{'transactionStatus' | translate}}" [formControl]="transactionStatus">
            <mat-option>{{'all' | translate}}</mat-option>
            <mat-option *ngFor="let item of transactionStatuses" [value]="item.code">
                {{ item.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFill>
        <input type="text" placeholder="{{'select-service'| translate}}" matInput [formControl]="selectedService"
            [matAutocomplete]="auto2">
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnService" autoActiveFirstOption>
            <mat-option *ngFor="let service of serviceAutoComplete$ | async; let index = index" [value]="service">
                {{service.name}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="selectedService != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchService()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <mat-form-field fxFill>
        <input type="text" placeholder="{{'parameter'| translate}}" matInput [formControl]="paymentParameter">
        <button *ngIf="paymentParameter != null" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchPaymentParameter()">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>

    <div mat-list-item fxFill fxLayoutAlign="end" fxLayoutGap="10px">
        
        <button mat-button (click)="closeReportFilter()">
            <mat-icon>close</mat-icon>
            {{'cancel'| translate}}
        </button>
        
        <button mat-flat-button (click)="searchReportFilter()" color="primary">
            <mat-icon>search</mat-icon>
            {{'apply'| translate}}
        </button>
    </div>
  </mat-nav-list>