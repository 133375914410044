<mat-nav-list>
    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateFrom' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" [formControl]="dateFrom"
            placeholder="dateFrom" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'dateTo' | translate}}</mat-label>
        <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [formControl]="dateTo"
            placeholder="{{'dateTo'| translate}}" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFill mat-list-item>
        <mat-label>{{'status-news' | translate}}</mat-label>
        <mat-select [formControl]="statusNews">
            <mat-option value="-1">{{'select-all' | translate}}</mat-option>
            <mat-option value="1" >{{'readed' | translate}}</mat-option>
            <mat-option value="0" >{{'not-readed' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
    
    <div mat-list-item fxFill fxLayoutAlign="end" fxLayoutGap="10px">
        
        <button mat-button (click)="closeNewsFilter()">
            <mat-icon>close</mat-icon>
            {{'cancel'| translate}}
        </button>
        
        <button mat-flat-button (click)="searchNewsFilter()" color="primary">
            <mat-icon>search</mat-icon>
            {{'apply'| translate}}
        </button>
    </div>
  </mat-nav-list>