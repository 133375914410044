<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'refund-requests'| translate}}</h1>
</div>

<div class="container" fxLayout fxLayoutAlign="end center">
    <mat-chip-list>
        <mat-chip (click)="openReportFilters()" *ngIf="transactionId?.value > 0"># {{transactionId.value}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="statusId?.value?.title">{{statusId?.value?.title}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateFrom?.value">{{'from-date' | translate}} {{dateFrom?.value | date: 'dd/MM/yyyy'}}</mat-chip>
        <mat-chip (click)="openReportFilters()" *ngIf="dateTo?.value">{{'to-date' | translate}} {{dateTo?.value | date: 'dd/MM/yyyy'}}</mat-chip>
    </mat-chip-list>
    
    <button mat-icon-button color="primary" (click)="exportToExcel()" [disabled]="dataSource?.paginator?.length==0" >
        <mat-icon>save_alt</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="openReportFilters()">
        <mat-icon>filter_list</mat-icon>
    </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
<table mat-table [dataSource]="dataSource" class="accordion-block"  multiTemplateDataRows>

    <ng-container matColumnDef="statusId">
        <th mat-header-cell *matHeaderCellDef class="checkbox-td-padding-right-10">{{'report-status' | translate}}
           
        </th>
        <td mat-cell *matCellDef="let element" class="checkbox-td-padding-right-10">
            <mat-icon class="inprogress-transaction-icon" *ngIf="element.statusId == 1 || element.statusId == 2" matTooltip="{{'in-progress' | translate}}">update</mat-icon>
            <mat-icon class="completed-transaction-icon" *ngIf="element.statusId == 4" matTooltip="{{'success-short' | translate}}">check_circle</mat-icon>
            <mat-icon class="canceled-transaction-icon" *ngIf="element.statusId == 5" matTooltip="{{'failed-short' | translate}}">cancel</mat-icon>
            <mat-icon class="completed-transaction-icon" *ngIf="element.statusId == 3" matTooltip="{{'partially-canceled' | translate}}">check_circle</mat-icon>
        </td>
    </ng-container>

    <!-- titleInfo Column -->
    <ng-container matColumnDef="titleInfo">
        <th mat-header-cell *matHeaderCellDef> {{'more' | translate}}</th>
        <td mat-cell  class="header-title" *matCellDef="let element" (click)="openDetails(expandedElement, element)"> 
            <span>{{'order-number' | translate}}{{element.id}} {{element.statusName}}</span> <br>
            <span  class="mat-small service-name">{{element.serviceName}}</span>
        </td>
    </ng-container>

    
     <!-- createdAt Column -->
     <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> {{'created-date' | translate}} </th>
        <td mat-cell *matCellDef="let element" (click)="openDetails(expandedElement, element)">
            <span style="color:	#808080">{{element.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</span> 
        </td>
    </ng-container>

    <!-- expanderBlock Column -->
    <ng-container matColumnDef="expanderBlock">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="openDetails(expandedElement, element)">
            <mat-icon *ngIf="expandedElement !== element" style="float: right;">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element" style="float: right;">keyboard_arrow_up</mat-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
            style="padding: 0; border-bottom: none;">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                
                <div fxFill >
                    <div fxFlex="100%">
                        <p class="mat-small"><b>{{'number-transaction' | translate}}</b>: {{element.transactionId}}</p>
                        <p class="mat-small"><b>{{'canceled-amount' | translate}}</b>: {{element.canceledAmount / 100 }} {{globalCurrencySymbol}}</p>
                        <p class="mat-small"><b>{{'date-canceled' | translate}}</b>: {{element.canceledAt | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
                        <p class="mat-small"><b>{{'comment' | translate}}</b>: {{element.userComment}}</p>
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
        [class.expanded-row]="expandedElement === element"
        [class.expanded-row-style]="element.statusId > 0"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="2">{{'empty-report-result' | translate}}</td>
    </tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 50, 100, 500, 1000]"></mat-paginator>
</div>