<form [formGroup]="formDetails" fxFill>
    <mat-form-field fxFill>
      <input formControlName="number" placeholder="{{'account' | translate}}" matInput value="">
    </mat-form-field><br />
    <mat-form-field fxFill>
      <input formControlName="name" placeholder="{{'owner' |translate}}" matInput value="">
    </mat-form-field>
    <mat-form-field fxFill>
      <input formControlName="address" placeholder="{{'address' | translate}}" matInput value="">
    </mat-form-field><br />
    <div class="service-block-new" formArrayName="services">
      <div *ngFor="let service of services;let serviceIndex=index;">
        <div [formGroupName]="serviceIndex">
          <mat-expansion-panel hideToggle [expanded]="true">
          <!-- <mat-expansion-panel hideToggle [expanded]="true" [disabled]="disabled" (opened)="isWantToPay = true" (closed)="isWantToPay = false"> -->
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div fxFill fxLayout="row" fxLayoutWrap fxLayoutGap="20px">               
                  <input formControlName="name" matInput value="" readonly>
                 <!--  <mat-slide-toggle disableRipple="true" color="primary" checked="isWantToPay" fxLayoutAlign="end" formControlName="isWantToPay" (click)="$event.stopPropagation();togglePanel(serviceIndex)">                 
                  </mat-slide-toggle> -->
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>    
              <div fxLayout="row" fxLayoutGap="20px" formArrayName="readings" class="readings">
                <ng-container *ngFor="let reading of getReadingsFor(serviceIndex);let readingIndex=index;">
                  <ng-container [formGroupName]="readingIndex">
                    <mat-form-field fxFlex="100%" *ngIf="reading.get('type').value==2">
                      <input matInput (ngModelChange)="userChangedReading()" formControlName="count" value=""
                        placeholder="{{'current-readings' | translate}}" tabindex="{{serviceIndex}}1" type="number" [readonly]="!reading.get('overwritable').value">
                    </mat-form-field>
                    <mat-form-field fxFlex="100%" *ngIf="reading.get('type').value==1">
                      <input matInput (ngModelChange)="userChangedReading()" formControlName="count" value=""
                        placeholder="{{'prev-readings' | translate}}" tabindex="{{serviceIndex}}2" type="number" [readonly]="!reading.get('overwritable').value">
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </div>
              <!--   </mat-card-content>
            </mat-card> -->
            </ng-container>         
          </mat-expansion-panel>
  
  
          <br />
        </div>
      </div>
    </div>
  </form>