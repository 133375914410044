<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'my-tariffs'| translate}}</h1>
</div> 
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div *ngIf="tariffs.length < 40">
    <a color="primary" mat-button routerLink="/settings/add-tariff">
        <mat-icon>library_add</mat-icon>
        {{'add-tariff'| translate}}
    </a>
</div>

<div *ngIf="tariffs.length == 40" style="margin: 10px 0;">
    <span class="mat-body">{{'agent-plan-limit-text' | translate}}</span>
</div>

<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-body">{{element.name }} </span>
            </td>
        </ng-container>
        
         <!-- created Column -->
         <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> {{'created' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span class="mat-body">{{element.created | date: 'dd/MM/yyyy HH:mm:ss'}} </span>
            </td>
        </ng-container>

         <!-- action Column -->
         <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <section class="template-section" fxLayoutAlign="end">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="openTariffViewDialog(element)" mat-menu-item>
                                <mat-icon>bar_chart</mat-icon>
                                <span>{{'view-tariff-commissions' | translate}}</span>
                            </button>
                        
                            <a href="/settings/edit-tariff/{{element.id}}" style="text-decoration: none;">
                                <button mat-menu-item>
                                    <mat-icon>edit</mat-icon>
                                    <span>{{'edit-tariff' | translate}}</span>
                                </button>
                            </a>                 
                      </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">{{'empty-user-tariffs' | translate}}</td>
        </tr>
    </table>
</div>