import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ArgumentOutOfRangeError } from 'rxjs';
import { TransferTypes } from 'src/app/enums/TransferEnum';
import { AgentService } from 'src/app/services/agent.service';
import { CommissionService } from 'src/app/services/commission.service';
import { TransfersService } from 'src/app/services/transfers.service';

@Component({
  selector: 'app-credit-agent',
  templateUrl: './credit-agent.component.html',
  styleUrls: ['./credit-agent.component.css']
})
export class CreditAgentComponent implements OnInit {

  isCompleted: boolean = false;
  inProgressTransfer: boolean = false;

  agent: any;
  creditForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
    creditSum: [0, [Validators.required, Validators.min(1)]],
    comment: ""
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private commissionSrv: CommissionService,
    private agentSrv: AgentService,
    private transfersSrv: TransfersService
  ) { }

  ngOnInit(): void {
    this.getAgent();
    this.isCompleted = true;
  }

  getAgent() {
    this.agentSrv.agent().subscribe(
      (data: any) => {
        this.agent = data;
      },
      error => console.log(error)
    )
  }

  // convenience getter for easy access to form fields
  get f() { return this.creditForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.creditForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.creditForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
    this.inProgressTransfer = true;
    this.transfersSrv.transfers(this.agent.id, data.agentId, Math.round((Number(Number(data.creditSum).toFixed(2)) * 100)), data.comment, TransferTypes.GiveBalance)
      .subscribe(
        data => {
          let message = this.translate.instant('success-credit-agent');
          this.openSnackBar(message, 'x');
        },
        error => {
          this.inProgressTransfer = false;
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = error.error;
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      );
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
