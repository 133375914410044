import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/commission'
  }

  // Получить шаблоны тарифов для суб агента
  get(): Observable<any> {
    let url = this.host + '/tariff';
    return this.http.get<any[]>(url);
  };
  
  getSubsTariffs(agentId: string): Observable<any[]> {
    let url = this.host + '/subs/' + agentId + '/tariffs';
    return this.http.get<any[]>(url);
  }

  getServicesLowers(tariffId: number): Observable<any[]> {
    let url = this.host + '/services-lowers/' + tariffId;
    return this.http.get<any[]>(url);
  }

  // возвращает все тарифы созданные Агентом
  agentTariffs(): Observable<any> {
    let url = this.host + '/tariffs';
    return this.http.get<any>(url);
  }

  // возвращает информацию о тарифе Агента
  agentTariff(): Observable<any> {
    let url = this.host + '/agent-tariff';
    return this.http.get<any>(url);
  }

  // возвращает информацию о созданном Агентом тарифе 
  getAgentCreatedTariff(tariffId: number): Observable<any> {
    let url = this.host + '/agent-tariff/' + tariffId;
    return this.http.get<any>(url);
  }

  // Добавление тарифа
  addTariff(data: any): Observable<any> {
    let url = this.host + '/add-tariff';
    return this.http.post(url, data);
  }

  // Обновление тарифа
  updateTariffValues(data: any, tariffId: number): Observable<any> {
    let url = this.host + '/update-tariff/' + tariffId;
    return this.http.post(url, data);
  }

  // Обновление верхной комиссии агента по услуге
  updateUpperCommissionsByService(data: any): Observable<any> {
    let url = this.host + '/update-upper-commissions';
    return this.http.post(url, data);
  }
}