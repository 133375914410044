import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from 'src/app/services/agent.service';
import { TransfersService } from 'src/app/services/transfers.service';

@Component({
  selector: 'app-two-factor-settings',
  templateUrl: './two-factor-settings.component.html',
  styleUrls: ['./two-factor-settings.component.css']
})
export class TwoFactorSettingsComponent implements OnInit {

  isCompleted: boolean = false;
  agent: any;
  twoFactorForm = this.fb.group({
    agentId: [this.item.agent.id, Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private transfersSrv: TransfersService,
    private agentSrv: AgentService
  ) { }

  ngOnInit(): void {
      this.getAgent();
      this.isCompleted = true;
  }

  getAgent() {
    this.agentSrv.agent().subscribe(
      (data: any) => {
        this.agent = data;
      },
      error => console.log(error)
    )
  }

  // convenience getter for easy access to form fields
  get f() { return this.twoFactorForm.controls; }
  public hasError = (controlName: string, errorName: string) => {
    return this.twoFactorForm.controls[controlName].hasError(errorName);
  }

  public update = (form) => {
    if (this.twoFactorForm.valid) {
      this.execute(form);
    }
  }

  private execute = (data) => {
   
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
