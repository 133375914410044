import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IReportTransactions } from 'src/app/interfaces/agent/itransactions';
import { AgentService } from 'src/app/services/agent.service';
import { ExportService } from 'src/app/services/export.service';
import { PaymentReceiptService } from 'src/app/services/payment-receipt.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ReportService } from 'src/app/services/report.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckComponent } from 'src/app/components/payment/check/check.component';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TransactionsFilterComponent } from './transactions-filter/transactions-filter.component';
import { environment } from 'src/environments/environment';
import { RefundDialogComponent } from 'src/app/components/custom-dialogs/refund-dialog/refund-dialog.component';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  isCompleted: boolean = false;
  isHideHeader: boolean = true;
  isHideTransactionStatusChip: boolean = true;
  isHideTransactionTypeChip: boolean = true;
  isHidePaymentParameterChip: boolean = true;
  displayedColumns: string[] = ['status', 'mainInfo', 'allAmount'];
  dataSource: IReportTransactions[] = [];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  public transactions: IReportTransactions[] = [];
  public agents: any[] = [];
  public transactionTypes: any[] = [];
  public transactionStatuses: any[] = [];
  selectedAgent = new FormControl();

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 4)));
  dateTo = new FormControl(new Date());
  transactionType = new FormControl();
  transactionStatus = new FormControl();
  selectedService = new FormControl();
  paymentParameter = new FormControl();
  public fileName: string;
  public transactionStatusTitle: string;
  public transactionTypeTitle: string;

  constructor(
    private reportSrv: ReportService,
    private exportSrv: ExportService,
    private dictionarySrv: DictionaryService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentsSrv: AgentService,
    private paymentReceiptService: PaymentReceiptService,
    public dialog: MatDialog,
    public dialogConfirm: MatDialog,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getTransactionType();
    this.getTransactionStatus();
    this.getAgents();
  }

  openReportFilters(): void {
      const bottomSheetRef = this.bottomSheet.open(TransactionsFilterComponent,{
        data: { selectedAgent: this.selectedAgent, dateFrom: this.dateFrom, dateTo: this.dateTo, agents: this.agents,
              transactionTypes: this.transactionTypes, transactionStatus: this.transactionStatus, transactionType: this.transactionType,
              transactionStatuses: this.transactionStatuses,  selectedService: this.selectedService, paymentParameter: this.paymentParameter
        }
      });
  
      bottomSheetRef.afterDismissed().subscribe((data) => {
          if(data){
            this.selectedAgent = data.selectedAgent;
            this.dateFrom = data.dateFrom;
            this.dateTo = data.dateTo;
            this.transactionType = data.transactionType;
            this.transactionStatus = data.transactionStatus;
            this.transactionTypeTitle = data.transactionTypeTitle;
            this.transactionStatusTitle = data.transactionStatusTitle;
            this.selectedService = data.selectedService;
            this.paymentParameter = data.paymentParameter;
            if(data.isClosed == false){
              this.getReport();
            }
          }
      });
  }

  openDialogConfirmRefund(item : any): void {
    const dialogConfirmRef = this.dialogConfirm.open(RefundDialogComponent,
      {       
        data: { transaction: item }
      });

      dialogConfirmRef.afterClosed().subscribe(result => {
    });
  }

  getReport(userId = "") {
    var curUserId = "";
    var curServiceId = null;
    if(userId.length > 0){
        curUserId = userId;
    }
    else if (this.selectedAgent.value != null && this.selectedAgent.value != undefined) {
        curUserId = this.selectedAgent.value.id;
    }

    if (this.selectedService.value != null && this.selectedService.value != undefined) {
      curServiceId = this.selectedService.value.id;
    }

    this.isCompleted = false;
    this.dataSource = [];
    this.reportSrv.transactionsReport(curUserId, this.dateFrom.value, this.dateTo.value, this.transactionType?.value, this.transactionStatus?.value, curServiceId, this.paymentParameter.value)
      .subscribe(
        data => {
          this.transactions = data;
          this.dataSource = this.transactions;

          if(this.transactionType?.value > 0){
              this.isHideHeader = false;
              this.isHideTransactionTypeChip = false;
          }
          else{
              this.isHideHeader = true;
              this.isHideTransactionTypeChip = true;
          }

          if(this.transactionStatus?.value > 0){
              this.isHideTransactionStatusChip = false;
          }
          else{
              this.isHideTransactionStatusChip = true;
          }
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }

  getAgents() {
    this.agentsSrv.agentsBranch().subscribe(
      (data: any[]) => {
        this.agents = data;
        if(data != null){
            this.getReport(this.agents[0]['id']);
            this.selectedAgent = new FormControl(this.agents[0]);
        }
      },
      error => console.log(error)
    )
  }

  
  getTransactionType(){
    this.dictionarySrv.transactionTypes().subscribe(
      (data: any[]) => {
        this.transactionTypes = data;
      },
      error => console.log(error)
    )
  }

  getTransactionStatus(){
    this.dictionarySrv.transactionStatuses().subscribe(
      (data: any[]) => {
        this.transactionStatuses = data;
      },
      error => console.log(error)
    )
  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  getTotalAccepted() {
    return this.dataSource.map(t => t.accepted).reduce((acc, value) => acc + value, 0);
  }
  getTotalUserAmount() {
    return this.dataSource.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUpper() {
    return this.dataSource.map(t => t.commission).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledAmount() {
    return this.dataSource.map(t => t.canceledAmount).reduce((acc, value) => acc + value, 0);
  }
  exportToExcel() {
    var result = this.exportSrv.translateExcel(this.dataSource);
    this.exportSrv.exportToExcel(result, 'Report', 'filename.xlsx');
  }

  public dateFromChanged() {
    this.getReport();
  }

  public dateToChanged() {
    this.getReport();
  }

  public agentChanged() {
    this.getReport();
  }

  public updateReport(){
    this.getReport();
  }

  checkDownload(item: any) {
    if (item != null) {

      this.paymentReceiptService.download(item.id)
        .subscribe(
          data => {
            importedSaveAs(data, item.id + ".pdf");
          },
          error => {
            if (error.status === 404) {
              let message = this.translate.instant('no-data');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 400) {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
              else
                if (error.status === 500) {
                  let message = this.translate.instant('service-is-temporarily-unavailable');
                  this.openSnackBar(message, 'x');
                }
                else {
                  let message = this.translate.instant('an-error-occurred-while-processing');
                  this.openSnackBar(message, 'x');
                }
          }
        ).add(() => { });
    }
  }

  // View transaction details
  openViewTransactionDetailsDialog(item: any) {
    const dialogRef = this.dialog.open(CheckComponent,
      {       
        data: { transaction: item }
      });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  emptyTransactionType(){
      this.transactionType.setValue(null);
      this.transactionTypeTitle = "";
      this.isHideTransactionTypeChip = true;
      this.getReport();
  }

  emptyTransactionStatus(){
    this.transactionStatus.setValue(null);
    this.transactionStatusTitle = "";
    this.isHideTransactionStatusChip = true;
    this.getReport();
  }

  emptyPaymentParameter(){
    this.paymentParameter.setValue(null);
    this.isHidePaymentParameterChip = true;
    this.getReport();
  }
}


