<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<div *ngIf="template">

    <mat-nav-list>
        <mat-list-item class="template-service-block">
            <a routerLink="/">
                <mat-icon color="primary" mat-list-icon>arrow_back_ios</mat-icon>
            </a>

            <div>
                <mat-icon mat-list-icon class="template-service-icon"><img src="{{static_api_url}}services/read/{{id}}.png" height="40" /></mat-icon>
            </div>
            <span style="padding-left: 22px;">
                {{serviceName}}
                <br>
                <span style="color: grey;" class="mat-caption">{{categoryName}}</span>
            </span>
            <mat-divider></mat-divider>
        </mat-list-item>
    </mat-nav-list>


    <div>
        <form [formGroup]="form">
            <mat-card>
                <mat-card-content style="margin-bottom: 0;">
                    <ng-container *ngFor="let item of inputcontrols" class="form-row">
                        <app-templatecontrol *ngIf="!item.isForPay" [inputcontrol]="item" [inputPrefix]="inputPrefix" [inputMask]="inputMask" [form]="form"></app-templatecontrol>
                    </ng-container>
                    <br />
                    <mat-form-field fxFill *ngIf="recievedReference">
                        <input #amountel type="text" [formControl]="amount" (ngModelChange)="getCommission()" matInput
                            [readonly]="isInvoice || onDemandAmount" placeholder="{{'amount'| translate}}" (keypress)="onKeypressAmountEvent($event)" currencyMask>
                        <mat-error *ngIf="amount.errors">
                            <div *ngIf="amount.errors.required">{{'amount-req' | translate}}</div>
                            
                            <div *ngIf="amount.errors.min && template.minAmount/100 <= 0">{{'amount-min-value-default' | translate }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 <= 0">{{'amount-max-value-default' | translate }}</div>

                            <div *ngIf="amount.errors.min && template.minAmount/100 > 0">{{'amount-min-value' | translate: {amount: template.minAmount/100} }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 > 0 && id != 310036">{{'amount-max-value' | translate: {amount: template.maxAmount/100} }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 > 0 && id == 310036">{{'amount-max-value' | translate: {amount: 490000} }}</div>
                        </mat-error>
                        <mat-hint style="display: flex;">{{'minAmount'| translate}}:
                            {{template.minAmount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}},
                            {{'maxAmount'| translate}}:
                            <span *ngIf="id != 310036"> {{template.maxAmount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                            <span *ngIf="id == 310036"> {{490000 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                        </mat-hint>
                    </mat-form-field>
                    <ng-container *ngIf="recievedReference">
                        
                        <mat-slide-toggle class="include-commission-checkbox" fxFill color="primary" [formControl]="isIncludeCommission" checked="false"
                            *ngIf="!isInvoice && !onDemandAmount" (ngModelChange)=getCommission()>
                            {{ 'include-commission'| translate}}
                        </mat-slide-toggle>
                        <small>{{'commission' | translate}}
                            {{commission | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</small>                                                       
                            <button mat-icon-button color="primary" matTooltip="{{'view-upper-commission' | translate}}" (click)="openAgentUpperCommissionDialog(template)">
                                <mat-icon>contact_support</mat-icon>
                            </button>
                        <br />
                        <small>{{'lower-commission-short' | translate}}</small>                                                       
                        <button mat-icon-button color="primary" matTooltip="{{'view-lower-commission' | translate}}" (click)="openAgentLowerCommissionDialog(template)">
                            <mat-icon>contact_support</mat-icon>
                        </button>
                        <br>
                        <small>{{'calculated-amount' | translate}}
                            {{calculatedAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</small>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="!recievedReference" mat-flat-button fxFill [disabled]="!form.valid"
                        (click)="checkPay()"
                        color="primary">{{'check'| translate}}</button>
                    <button *ngIf="recievedReference" mat-flat-button fxFill (click)="pay()"
                        [disabled]="!amount.valid || calculatedAmount <= 0" color="primary">{{'pay'| translate}}
                        {{accepted | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
    <br>

    <!-- Начало Template для типа InvoiceTypeA, InvoiceTypeB, InvoiceTypeEnergiaRudny -->
    <app-details (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeA' || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeB'
        || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeEnergiaRudny')
        " [checkDetails]="checkDetails"></app-details>
    <!-- Конец Template для типа InvoiceTypeA, InvoiceTypeB, InvoiceTypeEnergiaRudny -->    
    
    <!-- Начало Template для типа InvoiceTypeC -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeC'">
        <app-invoice-type-cdetails (update)="countChange($event)" (changedServices)="billChanged($event)" [checkDetails]="checkDetails"></app-invoice-type-cdetails>
    </div>
    <!-- Конец Template для типа InvoiceTypeC -->

    <!-- Начало Template для типа InvoiceTypeOnlyLastCounts -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeOnlyLastCounts'">
        <app-invoice-type-only-last-counts-details (update)="countChange($event)" (changedServices)="billChanged($event)" [checkDetails]="checkDetails"></app-invoice-type-only-last-counts-details>
    </div>
    <!-- Конец Template для типа InvoiceTypeOnlyLastCounts -->

    <!-- Начало Template для типа SimpleWithDisplayInfo и ExtBalance -->
    <div *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'Simple' || checkDetails?.serviceExtInfo?.serviceType == 'SimpleWithDisplayInfo' || checkDetails?.serviceExtInfo?.serviceType == 'ExtBalance')">
        <div *ngIf="checkDetails?.addInfo?.accounts?.account">
            <div *ngFor="let item of checkDetails?.addInfo?.accounts?.account">
                <div *ngFor="let detail of item | keyvalue">
                    <mat-form-field fxFill *ngIf="detail.key != 'displayInfo' && detail.value.toString().length > 0">
                        <input matInput placeholder='{{detail.key | translate}}' value='{{detail.value}}' disabled>
                    </mat-form-field>
                </div>

                <div *ngFor="let detailLine of item.displayInfo?.line">
                    <mat-form-field fxFill>
                        <input matInput placeholder='' value='{{detailLine}}' disabled>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа SimpleWithDisplayInfo и ExtBalance -->

    <!-- Начало Template для типа ShowAgreements -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'ShowAgreements'">
        <div *ngFor="let agreement of checkDetails?.addInfo?.document?.agreements?.agreement">
            <div *ngFor="let detail of agreement | keyvalue">
                <mat-form-field fxFill *ngIf="detail.value.toString().length > 0">
                    <input matInput placeholder='{{detail.key | translate}}' value='{{detail.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа ShowAgreements -->

    <div *ngIf="recievedReference && checkDetails?.addInfo?.accounts?.accountsList">
        <div *ngFor="let item of checkDetails?.addInfo?.accounts?.accountsList[0] | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>
    </div>

    <!-- Начало Template для типа InvoiceTypeERCKU -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeERCKU'">
        <div *ngFor="let item of checkDetails?.addInfo?.document?.payer | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>
    </div>
    <!-- Конец Template для типа InvoiceTypeERCKU -->

    <!-- Начало Template для типа Tickets -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Tickets'">
        <div *ngFor="let item of checkDetails?.addInfo?.tickets?.ticket">
            <div *ngFor="let ticketItem of item | keyvalue">
                <mat-form-field fxFill *ngIf="ticketItem.value.toString().length > 0">
                    <input matInput placeholder='{{ticketItem.key | translate}}' value='{{ticketItem.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Tickets -->

    <!-- Начало Template для типа Tickets2 -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Tickets2'">
        <div *ngFor="let item of checkDetails?.addInfo?.document?.payer | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>

        <div *ngFor="let item of checkDetails?.addInfo?.document?.tickets?.ticket">
            <div *ngFor="let ticketItem of item | keyvalue">
                <mat-form-field fxFill *ngIf="ticketItem.value.toString().length > 0">
                    <input matInput placeholder='{{ticketItem.key | translate}}' value='{{ticketItem.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Tickets2 -->

    <!-- Начало Template для типа CurrencyExchange -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'CurrencyExchange'">
        <div *ngFor="let item of checkDetails?.addInfo?.exchange | keyvalue">
            <div *ngIf="item.key == 'rate'">
                <mat-form-field fxFill>
                    <input matInput placeholder='{{item.key | translate}}' value="{{item.value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}" disabled>
                </mat-form-field>
                <mat-form-field fxFill>
                    <input matInput placeholder='' [formControl]="rateCalc" value="" disabled>
                </mat-form-field>
            </div>  
        </div>
    </div>
    <!-- Конец Template для типа CurrencyExchange -->

    <!-- Начало Template для типа Parking -->
      <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Parking'">
        <div *ngFor="let accountItem of checkDetails?.addInfo?.accounts?.account">
            <div *ngFor="let item of accountItem | keyvalue">
                <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                    <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Parking -->

    <!-- Начало Template для типа InvoiceTypeKTEK и InvoiceTypeUKTS -->
    <app-invoice-type-ktek (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeKTEK' || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeUKTS')
        " [checkDetails]="checkDetails"></app-invoice-type-ktek>
    <!-- Конец Template для типа InvoiceTypeKTEK и InvoiceTypeUKTS -->    

    <!-- Начало Template для типа SelectContract -->
    <app-invoice-type-select-contract (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'SelectContract'
        " [checkDetails]="checkDetails"></app-invoice-type-select-contract>
    <!-- Конец Template для типа SelectContract -->
</div>

<div *ngIf="buttonRepay">
    <ng-container *ngIf="transactionInfo?.Payload?.bill?.Services?.length > 2 || checkDetails?.addInfo?.document?.invoices?.invoice[0]?.parameters?.parameter?.length >= 2">
            <button mat-flat-button fxFill (click)="pay()"
                [disabled]="!amount.valid || calculatedAmount <= 0" color="primary">{{'pay'| translate}}
                {{accepted | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</button>
    </ng-container>  
</div>