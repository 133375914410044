import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IProvidersReport } from 'src/app/interfaces/agent/iproviders-report';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { ExportService } from 'src/app/services/export.service';
import { ReportService } from 'src/app/services/report.service';
import { environment } from 'src/environments/environment';
import { ProvidersReportFilterComponent } from './providers-report-filter/providers-report-filter.component';

@Component({
  selector: 'app-providers-report',
  templateUrl: './providers-report.component.html',
  styleUrls: ['./providers-report.component.css']
})
export class ProvidersReportComponent implements OnInit {
  isCompleted: boolean = false;
  displayedColumns: string[] = ['serviceInfo', 'allAmount'];
  dataSource: IProvidersReport[] = [];

  public balances: IProvidersReport[] = [];
  public agents: any[] = [];
  selectedAgent = new FormControl();

  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  
  constructor(
    private authSrv: AuthService,
    private reportSrv: ReportService,
    private exportSrv: ExportService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public agentsSrv: AgentService,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getAgents();
  }

  openReportFilters(): void {
      const bottomSheetRef = this.bottomSheet.open(ProvidersReportFilterComponent,{
        data: { selectedAgent: this.selectedAgent, dateFrom: this.dateFrom, dateTo: this.dateTo, agents: this.agents }
      });
  
      bottomSheetRef.afterDismissed().subscribe((data) => {
          if(data){
            this.selectedAgent = data.selectedAgent;
            this.dateFrom = data.dateFrom;
            this.dateTo = data.dateTo;
            this.getReport();
          }
      });
  }

  getReport(userId = "") {
    var curUserId = "";
    if(userId.length > 0){
        curUserId = userId;
    }
    else if (this.selectedAgent.value != null && this.selectedAgent.value != undefined) {
        curUserId = this.selectedAgent.value.id;
    }

    this.isCompleted = false; 
    this.dataSource = [];
    this.reportSrv.providersReport(curUserId, this.dateFrom.value, this.dateTo.value)
      .subscribe(
        data => {
          this.balances = data;
          this.dataSource = this.balances;
        },
        error => {
          if (error.status === 404) {
            let message = this.translate.instant('no-data');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
        ).add(() => { this.isCompleted = true });
  }

  getAgents() {
    this.agentsSrv.agentsBranch().subscribe(
      (data: any[]) => {
        this.agents = data;
        if(data != null){
            this.getReport(this.agents[0]['id']);
            this.selectedAgent = new FormControl(this.agents[0]);
        }
      },
      error => console.log(error)
    )
  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  getTotalSuccesTransaction() {
    return this.dataSource.map(t => t.successCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCanceledTransaction() {
    return this.dataSource.map(t => t.canceledCount).reduce((acc, value) => acc + value, 0);
  }
  getTotalAccepted() {
    return this.dataSource.map(t => t.acceptedAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUserAmount() {
    return this.dataSource.map(t => t.userAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalUpper() {
    return this.dataSource.map(t => t.upperCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalLower() {
    return this.dataSource.map(t => t.lowerCommissionAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalExpectedLower(){
    return this.dataSource.map(t => t.expectedLowerCommissionAmount).reduce((acc, value) => acc + value, 0);
  }

  getTotalITO() {
    return this.dataSource.map(t => t.itoCommissionAmount).reduce((acc, value) => acc + value, 0);
  }

  getCanceledAmount() {
    return this.dataSource.map(t => t.canceledAmount).reduce((acc, value) => acc + value, 0);
  }
  exportToExcel() {
    var result = this.exportSrv.translateExcel(this.dataSource)
    this.exportSrv.exportToExcel(result, 'Report', 'filename.xlsx');
  }

  public dateFromChanged() {
    this.getReport();
  }

  public dateToChanged() {
    this.getReport();
  }

  public agentChanged() {
    this.getReport();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}


