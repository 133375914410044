import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PasswordManagerService } from 'src/app/services/password-manager.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {
  returnUrl: string = "/resetpassword";
  recoveryForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  });



  constructor(private fb: FormBuilder,
    private router: Router,
    private loaderService: LoaderService,
    private translate: TranslateService,   
    public snackBar: MatSnackBar,
    private passwordManagerService: PasswordManagerService) { }

  ngOnInit(): void {
  }

  // convenience getter for easy access to form fields
  get f() { return this.recoveryForm.controls; }

  public hasError = (controlName: string, errorName: string) => {
    return this.recoveryForm.controls[controlName].hasError(errorName);
  }

  recovery(): void {
    if (this.recoveryForm.valid) {
      //http call starts
      this.loaderService.display(true);
      this.passwordManagerService.forgotpassword(this.recoveryForm.controls['email'].value)
        .subscribe(
          data => {
            this.router.navigate([this.returnUrl]);
          },
          error => {

            if (error.status === 500) {
              let message = this.translate.instant('service-is-temporarily-unavailable');
              this.openSnackBar(message, 'x');
            }
            else {
              let message = this.translate.instant('an-error-occurred-while-processing');
              this.openSnackBar(message, 'x');
            }
          }
        ).add(() => {
          //http call ends
          this.loaderService.display(false);
        });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
