import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  host: string;
  
  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/dictionary'
  }
  agents(): Observable<any[]> {
    let url = this.host+"/agents";
    return this.http.get<any[]>(url);
  }

  transactionTypes(): Observable<any[]> {
    let url = this.host+"/transaction-type";
    return this.http.get<any[]>(url);
  }

  refundRequestStatuses(): Observable<any[]> {
    let url = this.host+"/refund-request-statuses";
    return this.http.get<any[]>(url);
  }
  
  transactionStatuses(): Observable<any[]> {
    let url = this.host+"/transaction-statuses";
    return this.http.get<any[]>(url);
  }

  getRegions(): Observable<any> {
    let url = this.host +'/regions';
    return this.http.get<any[]>(url);
  };

  getCities(): Observable<any> {
    let url = this.host +'/cities';
    return this.http.get<any[]>(url);
  };
}
