import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ILower } from 'src/app/interfaces/commission/Ilower';
import { AgentService } from 'src/app/services/agent.service';
import { AuthService } from 'src/app/services/auth.service';
import { ServiceService } from 'src/app/services/service.service';
import { environment } from 'src/environments/environment';
import { UpperCommissionComponent } from './upper-commission/upper-commission.component';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.css']
})
export class RatesComponent implements OnInit {

  uppers: any[] = [];
  lowers: ILower[] = [];
  displayedColumns: string[] = ['serviceName', 'allAmount'];
  dataSource: any[] = [];
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  constructor(public authService: AuthService,
    public servicesSrv: ServiceService,
    private agentSrv: AgentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getCurrentRates();
  }

  getCurrentRates() {
    this.agentSrv.rates().subscribe(
      (data: ILower[]) => {
        this.lowers = data;
        this.dataSource = this.lowers?.sort((a, b) => a.serviceId - b.serviceId);
      },
      error => console.log(error)
    )
  }

  // View upper commission
  openAgentUpperCommissionDialog(item: any) 
  {
    const dialogRef = this.dialog.open(UpperCommissionComponent,
      { data: { service: item } });

    dialogRef.afterClosed().subscribe(result => {
    });  
  }
}
