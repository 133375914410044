import { Component, Inject, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/services/agent.service';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-agents-report-filter',
  templateUrl: './agents-report-filter.component.html',
  styleUrls: ['./agents-report-filter.component.css']
})
export class AgentsReportFilterComponent implements OnInit {

  public agents: any[] = this.data.agents;
  selectedAgent = new FormControl();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  selectedService = new FormControl();
  public serviceAutoComplete$: Observable<any> = null;
  @Output() someEvent = new EventEmitter<string>();

  constructor(public agentsSrv: AgentService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef,
    private servicesSrv: ServiceService) {
        this.dateFrom.setValue(this.data.dateFrom.value);
        this.dateTo.setValue(this.data.dateTo.value);
        this.selectedService.setValue(this.data.selectedService?.value);
        this.selectedAgent.setValue(this.data.selectedAgent?.value);
     }

    lookupServices(value: any): Observable<any> {
      if (typeof value === 'object') {
        value = value.name;
      }
  
      return this.servicesSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
  }
  
  ngOnInit(): void {
    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '' && value !== null) {
          // lookup from services
          return this.lookupServices(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
    this.load();
  }

  load() {
  }

  displayFnService(service: any): string {
    return service && service.name ? service.name : '';
  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  searchReportFilter(){
    let data = [];
    data['selectedAgent'] = this.selectedAgent;
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    data['selectedService'] = this.selectedService;
    data['isClosed'] = false;
    this.bottomSheetRef.dismiss(data);
  }

  closeReportFilter(){
      let data = [];
      data['isClosed'] = true;
      data['dateFrom'] = this.data.dateFrom;
      data['dateTo'] = this.data.dateTo;
      data['selectedAgent'] = this.data.selectedAgent;
      data['selectedService'] = this.data.selectedService;
      this.bottomSheetRef.dismiss(data);
  }

  clearSearchService() {
    this.selectedService.setValue(null);
  }
}
