import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private translateSrv : TranslateService
  ) { }

  exportToExcel(data: any, sheetName: string, reportName: string) {  

   var workSheet = XLSX.utils.json_to_sheet(data);   

  // var workSheet = XLSX.utils.json_to_sheet(data, {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true});

 //var workSheet = XLSX.utils.sheet_add_json(workSheet, data, {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true, origin: -1});
  
   
   let wscols = [{ wpx: 150 }, { wpx: 200 }, { wpx: 150 }, { wpx: 150 }];
   workSheet['!cols'] = wscols; // set cell width

    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    XLSX.writeFile(workBook, reportName); 
  }

  translateExcel(data: any) {
    var totalArray = [];
    let divisionParams = ["lowerPercent", "itoFix", "itoPercent", "lowerFix"];
    let excludedParams = ["id", "status", "type", "parentTransactionId", "fromAccount", "toAccount", "payload", "agentId"];
    var translateArr = this.translateSrv.translations[environment.defaultLang];
    data.forEach(function (dataValue, dataKey) {
      var newDataValue = {};
      Object.entries(dataValue).forEach(([key, val]) => {
        if (divisionParams.includes(key)) {
          val = Number(val) / 100;
        }
        if (key == "parameters") {
          var parameter_str = "";
          if (val != null) {
            Object.entries(val).forEach(([paramsKey, paramsValue]) => {
              parameter_str += (translateArr[paramsKey.toLowerCase()] ?? paramsKey) + ": " + paramsValue + " ";
            });
          }
          newDataValue[translateArr[key.toLowerCase()] ?? key] = parameter_str;
        }
        else if(!excludedParams.includes(key)) {
          newDataValue[translateArr[key.toLowerCase()] ?? key] = val;
        }
      });
      totalArray.push(newDataValue);
    });
    return totalArray;
  }
}
