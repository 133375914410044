import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEmailingCheck } from '../interfaces/iemailing-check';

@Injectable({
  providedIn: 'root'
})
export class PaymentReceiptService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/check'
  }

  get(transactionId: string): Observable<any> {
    let url = this.host + '/' + transactionId;
    return this.http.get<any>(url);
  }

  download(transactionId: string): Observable<Blob> {
    let url = this.host + '/' + transactionId+'/download';
    return this.http.get(url, { responseType: 'blob' });
  }

  send(email: string, transactionId: number ): Observable<any> {
    let json =
    {
      transactionId: transactionId,
      email: email
    }
    return this.http.post(this.host, json);
  }

  sendToWhatsapp(phone: string, transactionId: number): Observable<any> {
    let url = this.host + '/send-whatsapp';
    let json =
    {
      transactionId: transactionId,
      phone: phone
    }
    return this.http.post(url, json);
  }
}