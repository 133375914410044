import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { IResponse } from '../interfaces/iresponse';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  host: string;
  current_user: any;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/User';
  }

  getUser(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(this.host).pipe(tap(res => {
      if (res.code==0)
      this.setSession(res.data);
    }), shareReplay());
  };

  private setSession(user) {
    localStorage.setItem('current_user', JSON.stringify(user.valueOf()));
  }

  getCurrentUser(): any {
    if (localStorage.getItem('current_user')) {
      const data = localStorage.getItem('current_user');
      this.current_user = JSON.parse(data);
    }
    return this.current_user;
  }

  // отображаемое значение
  displayFn(user?: any): string | undefined {
    return user ? user.fullName  : undefined;
  }
}
