<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'adding-new-tariff'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>

<div *ngIf="isCompleted && tariffInfo">
    <form [formGroup]="tariffForm" (ngSubmit)="update(tariffForm.value)">
        <div fxLayout="row" fxLayoutAlign="center" fxFill>  
            <div class="tariff-form-content">
                
                <div>
                    <mat-form-field fxFill>
                        <input matInput placeholder="{{'name' | translate}}" formControlName="name">
                        <mat-error *ngIf="tariffForm.controls['name'].hasError('required')">
                            {{'name-is-req'| translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                
                
                <mat-accordion class="accordion" *ngFor="let category of categories; let categoryIndex=index;" (click)="setOpenOrCloseCategory(category)" >
                    <mat-expansion-panel [expanded]="category.panelOpenState">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                         {{category.name}}
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <div formArrayName="tariffValues" fxFill>
                        <div *ngFor="let tariffValue of tariffValues;let tariffValueIndex=index;">
                            <div [formGroupName]="tariffValueIndex" *ngIf="category.alias == tariffValue.get('categoryName').value"  fxLayout="row" fxLayoutGap="0px" style="padding: 10px 0;" >
                                
                                
                                <div fxLayout="column" fxFill >
                                    <div class="mar-card-header-add" fxFlex="100%" fxFlex.xs="40%">
                                        <label class="tariff-label">{{tariffValue.get('serviceId').value}} {{tariffValue.get('serviceName').value}}</label>
                                    </div>
                                    <div fxFlex="100%" fxLayoutGap="20px" fxFlex.xs="100%">
                                        <div fxFlex fxFill *ngIf="tariffValue.get('isEdit').value == false; else elseBlock">
                                            <div class="block-input-commisions" fxFlex="22%">
                                                <p >{{'itoFix' | translate}}</p>
                                               <p class="total-сommission">{{tariffValue.get('itoFix').value}}</p> 
                                            </div>
                                            <div class="block-input-commisions"  fxFlex="22%">
                                                <p>{{'itoPercent' | translate}}</p>
                                                <p class="total-сommission"> {{tariffValue.get('itoPercent').value}}</p>       
                                            </div>
                                            <div  class="block-input-commisions" fxFlex="22%">
                                                <p>{{'lowerFix' | translate}}</p>
                                                <p class="total-сommission">{{tariffValue.get('lowerFix').value}}</p>                                           
                                            </div>
                                            <div class="block-input-commisions" fxFlex="22%">
                                                <p>{{'lowerPercent' | translate}}</p>
                                                <p class="total-сommission">{{tariffValue.get('lowerPercent').value}}</p>
                                            </div>
                                            <div fxFlex="12%">
                                                <button  mat-icon-button color="primary" class="ml-2" type="button" [disabled] = "(tariffValue.get('isEdit').value == true) && (!tariffValue.get('lowerPercent').valid) || (!tariffValue.get('itoPercent').valid) || (!tariffValue.get('itoFix').valid) ||  (!tariffValue.get('lowerFix').valid) " (click)="setEditableCommissions(tariffValueIndex)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                    
                                        </div>
    
                                        <ng-template #elseBlock>
                                            <div fxFlex="100%" fxFlex.xs="90%" fxLayoutGap.xs="1px">
                                                <mat-form-field fxFlex="22%">
                                                    <input matInput formControlName="itoFix" value="" placeholder="{{'itoFix' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['itoFix'].hasError('max')">
                                                        {{'max-value'| translate}}: {{tariffValue.controls['maxItoFix'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex="22%">
                                                    <input matInput formControlName="itoPercent" value="" placeholder="{{'itoPercent' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['itoPercent'].hasError('max')">
                                                        {{'max-value'| translate}}: {{tariffValue.controls['maxItoPercent'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                                    
                                                <mat-form-field fxFlex="22%">
                                                    <input matInput formControlName="lowerFix" value="" placeholder="{{'lowerFix' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['lowerFix'].hasError('min') || tariffValue.controls['lowerFix'].hasError('max')">
                                                        {{'min-max-range'| translate}}: 0 - {{tariffValue.controls['maxLowerFix'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex="22%">
                                                    <input matInput formControlName="lowerPercent" value="" placeholder="{{'lowerPercent' | translate}}" type="number" step="0.1">
                                                    <mat-error *ngIf="tariffValue.controls['lowerPercent'].hasError('min') || tariffValue.controls['lowerPercent'].hasError('max')">
                                                        {{'min-max-range'| translate}}: 0 - {{tariffValue.controls['maxLowerPercent'].value}}
                                                    </mat-error>
                                                </mat-form-field>
                                                <div fxFlex="12%">
                                                    <button   mat-icon-button color="primary" class="ml-2" type="button" [disabled] = "(tariffValue.get('isEdit').value == true) && (!tariffValue.get('lowerPercent').valid) || (!tariffValue.get('itoPercent').valid) || (!tariffValue.get('itoFix').valid) ||  (!tariffValue.get('lowerFix').valid) " (click)="saveCommissions(tariffValueIndex)">
                                                        <mat-icon>save</mat-icon>
                                                    </button>
                                                </div>
                                            </div>  
                                        </ng-template>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    </mat-expansion-panel>
                  </mat-accordion>

                <div class="add-tariff-btn-block">
                    <mat-error class="error-tariff-service-list" *ngIf="incorrectServiceList != null" fxLayoutGap="20px">
                        <label *ngFor="let service of incorrectServiceList">
                            {{service.serviceName}} <br>
                        </label>
                    </mat-error>
                    <mat-card-actions fxLayoutGap="10px" fxLayoutAlign="end">
                        <a href="/settings/tariffs">
                            <button mat-flat-button type="button">{{'cancel' | translate}}</button>
                        </a>
                        <button mat-flat-button color="primary" type="submit" [disabled]="!tariffForm.valid || !isCompleted">{{'add' | translate}}</button>
                    </mat-card-actions>
                </div>
            </div>
        </div>

    </form>
</div>