export const environment = {
  production: true,
  api_url: 'https://kassa-api.yurta.me/api/ru',
  api_identity: 'https://cashboxauth.yurta.me/api',
  api_static: 'https://s3.yurta.me/',
  version: '3.0.0',
  globalLocation: window["env"]["globalLocation"] || 'kz',
  globalCurrencyCode: window["env"]["globalCurrencyCode"] || 'KZT',
  globalCurrencySymbol: window["env"]["globalCurrencySymbol"] || '₸',
  defaultLang: 'ru'
};
