import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { UnauthorizedComponent } from './components/pages/unauthorized/unauthorized.component';
import { TemplateComponent } from './components/payment/template/template.component';
import { RatesComponent } from './components/agent/rates/rates.component';
import { AgentsComponent } from './components/agents/agents.component';
import { RecoveryComponent } from './components/user/recovery/recovery.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { AddAgentComponent } from './components/agents/add-agent/add-agent.component';
import { CommissionComponent } from './components/commission/commission.component';
import { ProvidersReportComponent } from './components/agent/reports/providers-report/providers-report.component';
import { AgentsReportComponent } from './components/agent/reports/agents-report/agents-report.component';
import { TransactionsComponent } from './components/agent/reports/transactions/transactions.component';
import { DashboardComponent } from './components/agent/reports/dashboard/dashboard.component';
import { SuccessComponent } from './components/payment/success/success.component';
import { ResetpasswordComponent } from './components/user/resetpassword/resetpassword.component';
import { FailedComponent } from './components/payment/failed/failed.component';
import { InfoComponent } from './components/pages/info/info.component';
import { TariffsComponent } from './components/tariffs/tariffs/tariffs.component';
import { AddTariffComponent } from './components/tariffs/add-tariff/add-tariff/add-tariff.component';
import { EditTariffComponent } from './components/tariffs/edit-tariff/edit-tariff/edit-tariff.component';
import { UpperCommissionsComponent } from './components/tariffs/upper-commissions/upper-commissions/upper-commissions.component';
import { NewsComponent } from './components/news/news/news.component';
import { SuccessDivisionalTransactionComponent } from './components/payment/success-divisional-transaction/success-divisional-transaction.component';
import { RefundRequestComponent } from './components/agent/refund-request/refund-request.component';



const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'home' } },
  { path: 'login', component: LoginComponent, data: { title: 'login' } },
  { path: 'agent/dashboard', component: DashboardComponent, data: { title: 'dashboard' } },
  { path: 'recovery', component: RecoveryComponent, data: { title: 'recovery' } },
  { path: 'resetpassword', component: ResetpasswordComponent, data: { title: 'reset-password' } },
  { path: 'registration', component: RegistrationComponent, data: { title: 'registration' } }, 
  { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'unauthorized' } },
  { path: 'pages/:code', component: InfoComponent, data: { title: 'pages' } },
  { path: 'rates', component: RatesComponent, canActivate: [AuthGuard], data: { title: 'services-rates' } },
  { path: 'template/:id', component: TemplateComponent, canActivate: [AuthGuard], data: { title: 'template' } },  
  { path: 'template/:id/:transactionId', component: TemplateComponent, canActivate: [AuthGuard], data: { title: 'template' } },  
  { path: 'agents', component: AgentsComponent, canActivate: [AuthGuard], data: { title: 'my-agents' } },
  { path: 'agents/add', component: AddAgentComponent, canActivate: [AuthGuard], data: { title: 'adding-agent' } },
  { path: 'agent/reports/providers-report', component: ProvidersReportComponent, canActivate: [AuthGuard], data: { title: 'providers-report' } },
  { path: 'agent/reports/agents-report', component: AgentsReportComponent, canActivate: [AuthGuard], data: { title: 'agents-report' } },
  { path: 'agent/reports/transactions', component: TransactionsComponent, canActivate: [AuthGuard], data: { title: 'transactions' } },
  { path: 'commission', component: CommissionComponent, canActivate: [AuthGuard], data: { title: 'commission' } },
  { path: 'payment/success/:id', component: SuccessComponent, canActivate: [AuthGuard], data: { title: 'payment-success' } },  
  { path: 'payment/failed/:id', component: FailedComponent, canActivate: [AuthGuard], data: { title: 'payment-failed' } },  
  { path: 'payment/success-divisional/:id', component: SuccessDivisionalTransactionComponent, canActivate: [AuthGuard], data: { title: 'divisional-payment-success' } },  
  
  { path: 'settings/tariffs', component: TariffsComponent, canActivate: [AuthGuard], data: { title: 'my-tariffs' } },   
  { path: 'settings/add-tariff', component: AddTariffComponent, canActivate: [AuthGuard], data: { title: 'add-tariff' } },   
  { path: 'settings/edit-tariff/:id', component: EditTariffComponent, canActivate: [AuthGuard], data: { title: 'edit-tariff' } },   
  { path: 'settings/upper-commissions', component: UpperCommissionsComponent, canActivate: [AuthGuard], data: { title: 'manage-upper-commissions' } },   

  { path: 'news', component: NewsComponent, canActivate: [AuthGuard], data: { title: 'news' } },
  { path: 'agent/refund-request', component: RefundRequestComponent, canActivate: [AuthGuard], data: { title: 'refund-requests' } },    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
