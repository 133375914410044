<mat-tab-group mat-stretch-tabs [selectedIndex]="selectedTabIndex" (selectedIndexChange)="selectTab($event)">
  <mat-tab label="{{'categories' | translate}}">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <mat-nav-list>
      <mat-list-item (click)="handleClick(item)" *ngFor="let item of categories">
        <mat-icon mat-list-icon><img src="{{static_api_url}}categories/read/icon-{{item.alias | lowercase}}.png" height="25" /></mat-icon>
        <span style="padding-left: 42px;">{{item.name}}</span>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-nav-list>
  </mat-tab>
  <mat-tab label="{{'services' | translate}}" [disabled]="!selectedCategoryId">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <mat-nav-list *ngIf="services">
      <div class="service-item" *ngFor="let item of services">
        <div class="service-left-part">
          <mat-list-item [routerLink]="['template/',item.id]">
            <mat-icon mat-list-icon><img src="{{static_api_url}}services/read/{{item.id}}.png" height="25"/>
            </mat-icon>
            <span style="padding-left: 42px;">{{ item.name }}</span>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
        <div class="service-right-part" (click)="changeServiceTopState(item)">
          <mat-icon mat-list-icon *ngIf="checkServiceInTop(item)"><img src="{{static_api_url}}ukassa/read/star-fill.png" height="25"/>
          </mat-icon>
          <mat-icon mat-list-icon *ngIf="!checkServiceInTop(item)"><img src="{{static_api_url}}ukassa/read/star-line.png" height="25"/>
          </mat-icon>
        </div>
      </div>
    </mat-nav-list>

    <p *ngIf="isCompletedServices && services?.length < 1" class="empty-services-text">{{'empty-services' | translate}}</p>
  </mat-tab>
  <mat-tab label="{{'top-services' | translate}}">
    <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
    <mat-nav-list *ngIf="top">
      <div class="service-item" *ngFor="let item of top">
        <div class="service-left-part">
          <mat-list-item [routerLink]="['template/',item.id]">
            <mat-icon mat-list-icon><img src="{{static_api_url}}services/read/{{item.id}}.png" height="25"/>
            </mat-icon>
            <span style="padding-left: 42px;">{{ item.name }}</span>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
        <div class="service-right-part" (click)="changeServiceTopState(item)">
          <mat-icon mat-list-icon *ngIf="checkServiceInTop(item)"><img src="{{static_api_url}}ukassa/read/star-fill.png" height="25"/>
          </mat-icon>
          <mat-icon mat-list-icon *ngIf="!checkServiceInTop(item)"><img src="{{static_api_url}}ukassa/read/star-line.png" height="25"/>
          </mat-icon>
        </div>
      </div>
    </mat-nav-list>
  </mat-tab>
</mat-tab-group>
