import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InputControlBase } from 'src/app/classes/input-control-base';

@Injectable({
  providedIn: 'root'
})
export class InputControlService {

  constructor() { }
  toFormGroup(inputcontrols: InputControlBase<string>[] ) {
    const group: any = {};

    inputcontrols.forEach(item => {
      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
                                              : new FormControl(item.value || '');
    });
    return new FormGroup(group);
  }

  createPayFormgroup(inputcontrols: InputControlBase<string>[], contractId: string) {
    const group: any = {};

    inputcontrols.forEach(item => {
      group[item.key] = new FormControl(contractId || '');
    });
    return new FormGroup(group);
  }
}