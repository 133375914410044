<div fxLayout="column" fxLayoutAlign="center center">
  <h1 class="mat-headline">{{'registration' | translate}}</h1>
</div>
<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="registrationForm" novalidate (ngSubmit)="create(registrationForm.value)">
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <div>
        <div>
          <mat-form-field fxFill>
            <input type="email" matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
              <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
              <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div>
        <div>
          <mat-form-field fxFill>
            <input matInput placeholder="{{'lname' | translate}}" formControlName="lname">
            <mat-error *ngIf="registrationForm.controls['lname'].hasError('required')">
              {{'lname-is-req'| translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div>
          <mat-form-field fxFill>
            <input matInput placeholder="{{'name' | translate}}" formControlName="name">
            <mat-error *ngIf="registrationForm.controls['name'].hasError('required')">
              {{'name-is-req'| translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div>
          <mat-form-field fxFill>
            <input matInput placeholder="{{'pname' | translate}}" formControlName="pname">
          </mat-form-field>
        </div>
      </div>

      <div>
        <div>
          <mat-form-field fxFill>
            <mat-select placeholder="{{'city' | translate}}" formControlName="cityId">
              <mat-option *ngFor="let item of cities" [value]="item.code">
                {{ item.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registrationForm.controls['cityId'].hasError('required')">
              {{'city-is-req' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFill>
            <input matInput placeholder="{{'phoneNumber'| translate}}" formControlName="phone">
            <mat-icon matSuffix>996</mat-icon>
            <mat-error *ngIf="f.phone.errors">
              <div *ngIf="f.phone.errors.required"> {{'phone-is-req'| translate}}</div>
              <div *ngIf="f.phone.errors.pattern">{{'phone-number-pattern' | translate}}</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFill>
            <mat-select placeholder="{{'region' | translate}}" formControlName="region">
              <mat-option *ngFor="let item of regions" [value]="item.code">
                {{ item.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registrationForm.controls['region'].hasError('required')">
              {{'region-is-req' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field fxFill>
            <input matInput #iin maxlength="12" placeholder="{{'iin' | translate}}" type="text" formControlName="iin">
            <mat-hint align="end">{{iin.value.length}} / 12</mat-hint>
            <mat-error *ngIf="f.iin.errors">
              <div *ngIf="f.iin.errors.required">{{'iin-is-req' | translate}}</div>
              <div *ngIf="f.iin.errors.pattern">{{'iin-must-be' | translate}}</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field fxFill>
            <input matInput placeholder="{{'address'| translate}}" formControlName="address">
            <mat-error *ngIf="registrationForm.controls['address'].hasError('required')">
              {{'address-is-req'| translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" />
        <label for="acceptTerms" class="form-check-label">{{'accept-terms'| translate}}</label>
        <a mat-link-button color="primary" href="#">{{'acceptTerms' | translate}}</a>
        <mat-error *ngIf="f.acceptTerms.errors">{{'accept-terms-req'| translate}}</mat-error>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button fxFill mat-flat-button color="primary" type="submit" [disabled]="!registrationForm.valid || !isCompleted">{{'register' | translate}}</button>
    </mat-card-actions>
  </mat-card>
</form>