import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-divisional-payments-confirm-dialog',
  templateUrl: './divisional-payments-confirm-dialog.component.html',
  styleUrls: ['./divisional-payments-confirm-dialog.component.css']
})
export class DivisionalPaymentsConfirmDialogComponent implements OnInit {

  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  divisionalPaymentsCount = 0;
  paymentInfo: any;
  constructor(@Inject(MAT_DIALOG_DATA) public item: any,
            private matDialogRef: MatDialogRef<DivisionalPaymentsConfirmDialogComponent>) { }

  ngOnInit(): void {
    this.paymentInfo = this.item;
    console.log(this.item.amount/this.item.maxAmount);
    this.divisionalPaymentsCount = Math.ceil(this.item.amount/this.item.maxAmount);
  }

  confirmDivisionalPayments(){
    let data = [];
    data['confirmed'] = true;
    this.matDialogRef.close(data);
  }
}
