<div fxLayout="column" fxLayoutAlign="center center">
  <h1 class="mat-headline">{{'change-tariff'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div *ngIf="isCompleted">
  <form [formGroup]="tariffForm" novalidate (ngSubmit)="update(tariffForm.value)">
      <mat-dialog-content class="mat-typography">
          <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
          <p>{{'tariff-description'|translate}}</p>

          <mat-form-field fxFill>
              <mat-select placeholder="{{'template' | translate}}" formControlName="templateId">
                  <mat-option *ngFor="let item of templates" [value]="item.id">
                      {{ item.name }}
                  </mat-option>
              </mat-select>
              <mat-error *ngIf="tariffForm.controls['templateId'].hasError('required')">
                  {{'template-is-req' | translate}}
              </mat-error>
          </mat-form-field>

      </mat-dialog-content>
      <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
          <button mat-flat-button color="primary" type="submit" [disabled]="!tariffForm.valid"
              [mat-dialog-close]="true" cdkFocusInitial>{{'save'| translate}}</button>
      </mat-dialog-actions>
  </form>
</div>