import { Component, OnInit, ViewChild } from '@angular/core';
import { INews } from 'src/app/interfaces/inews';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NewsFilterComponent } from '../news-filter/news-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', padding: '0 25px'})),
      state('expanded', style({height: '*', padding: '10px 25px 15px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NewsComponent implements OnInit {

  news: INews[] = [];
  displayedColumns: string[] = ['selectCheckbox', 'titleInfo', 'expanderBlock'];
  dataSource: MatTableDataSource<INews>;
  isCompleted: boolean = false;
  expandedElement: INews | null;
  dateFrom = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
  dateTo = new FormControl(new Date());
  selectedCount: number = 0;
  isSelectedAll: boolean = false;
  isReloadPage: boolean = false;
  statusNews: string = "-1";
  staticApiUrl = environment.api_static;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public authService: AuthService,
            private newsSrv: NewsService,
            private bottomSheet: MatBottomSheet,
            public snackBar: MatSnackBar,
            private translate: TranslateService,
            private appComponent: AppComponent) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.getAgentNews();
  }

  getAgentNews() {
    this.newsSrv.getAgentNews(this.dateFrom.value, this.dateTo.value).subscribe(
      (data: INews[]) => {
        this.news = data;
        this.dataSource = new MatTableDataSource<INews>(this.news.filter(f => Number(this.statusNews) == 1 ? f.userNewsStatus == true : Number(this.statusNews) == 0 ? f.userNewsStatus == false : f.userNewsStatus != null));
        this.dataSource.paginator = this.paginator;
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true })
  }

  getNewsByFilter(){
    this.getAgentNews();
  }

  openNewsFilters(): void {
    const bottomSheetRef = this.bottomSheet.open(NewsFilterComponent,{
      data: { dateFrom: this.dateFrom, dateTo: this.dateTo, statusNews: this.statusNews}
    });
    bottomSheetRef.afterDismissed().subscribe((data) => {
        if(data){
          this.dateFrom = data.dateFrom;
          this.dateTo = data.dateTo;
          this.statusNews = data.statusNews;
          this.getAgentNews();
        }
    });

  }

  updateSelectedCount(){
      this.isSelectedAll = this.dataSource.data.filter(s => s.isSelected == true).length == this.dataSource.data.length;
      this.selectedCount = this.dataSource.data.filter(s => s.isSelected == true).length;
  }

  changeNewsStatus(status: boolean){
      let newsList = this.dataSource.data.filter(s => s.isSelected == true);
      this.isReloadPage = true;
      this.updateNewsStatus(status, newsList);
  }

  updateNewsStatus(status: boolean, newsList: any){
    this.newsSrv.setNewsAsReaded(newsList, status)
      .subscribe(
        data => {
            if(this.isReloadPage == false){
              this.appComponent.getAgentNewsCount();
            }
            else{
              window.location = window.location;
            }
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('incorrect-upper-commission-values');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ); 
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  updateAllSelectedCount(event){
    this.dataSource.data.forEach(function(item,i){
        item["isSelected"] = event.checked;
    });
    this.updateSelectedCount();
  }

  readNews(expandedElement: any, element:any){
    this.expandedElement = expandedElement === element ? null : element;
    if(element.userNewsStatus == false){
      this.isReloadPage = false;
      let newsList = [element];
      this.updateNewsStatus(true, newsList);
      this.dataSource.data.find(f => f.id == element.id).userNewsStatus = true;
    }
  }

}
