<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'parent-agent'| translate}}</h1>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" class="overflow-auto">
    <table mat-table [dataSource]="parentDataSource" class="mat-elevation-z0" fxFill>

        <!-- company-phone Column -->
        <ng-container matColumnDef="company-phone">
            <td mat-cell *matCellDef="let element"> 
                <div class="md-list-item-text">
                    <h4 class="mat-title">{{element.company }} </h4>
                    <span class="mat-caption">{{element.phone}} </span>
                </div>
            </td>
        </ng-container>
        
        <!-- action Column -->
        <ng-container matColumnDef="action"> 
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAgentCreditDialog(element)">
                            <mat-icon>attach_money</mat-icon>
                            <span>{{'agent-crediting' | translate}}</span>
                          </button>                       
                      </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumnsParent;"></tr>
    </table>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="my-info-table-headline">
    <h1 class="mat-headline">{{'me'| translate}}</h1>
</div>
<div fxLayout="column" fxLayoutAlign="end end">
    <a color="primary" mat-button (click)="updateMyInfo()">
        <mat-icon>refresh</mat-icon>
        {{'update'| translate}}
    </a>
</div>

<div fxLayout="column" fxLayoutAlign="none" class="overflow-auto">
    <table mat-table [dataSource]="agentDataSource" class="mat-elevation-z0" fxFill>

       <!-- company-phone Column -->
       <ng-container matColumnDef="company-phone">
            <td mat-cell *matCellDef="let element"> 
                <div class="md-list-item-text">
                    <h4 class="mat-title">{{element.lName }} {{element.fName ? element.fName : element.company }} </h4>
                    <span class="mat-caption">{{element.phone}} </span> <span class="mat-caption">{{element.template }}</span> <br>
                    <span class="mat-small">{{'available-amount' | translate}}: {{element.availableAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation }} </span>  <br>
                    <span class="mat-small">{{'expected-amount' | translate: {date: startOfNextMonth} }}: {{element.expectedAmount  | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation }}</span> <br>
                    <span class="mat-small color-red">{{'nk-warning-text' | translate}}</span>
                </div>
            </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <td mat-cell *matCellDef="let element">
                <section class="template-section">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAgentChangeInfoDialog(element)">
                            <mat-icon>edit</mat-icon>
                            <span>{{'settings' | translate}}</span>
                          </button>     
                          
                          <button mat-menu-item (click)="openAgentChangePasswordDialog(element)">
                            <mat-icon>vpn_key</mat-icon>
                            <span>{{'change-password' | translate}}</span>
                          </button> 
                      </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumnsMe;"></tr>
    </table>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="my-agents-headline">
    <h1 class="mat-headline">{{'my-agents'| translate}}</h1>
</div>
<div>
    <a color="primary" mat-button routerLink="add">
        <mat-icon>person_add</mat-icon>
        {{'create-agent'| translate}}
    </a>
    <div fxFlex></div>
    
    <a color="primary" mat-button (click)="updateMyAgents()">
        <mat-icon>refresh</mat-icon>
        {{'update'| translate}}
    </a>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompletedSubs"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none" class="overflow-auto">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFill>

        <!-- company-phone Column -->
        <ng-container matColumnDef="company-phone">
            <td mat-cell *matCellDef="let element"> 
                <div class="md-list-item-text">
                    <h4 class="mat-title">{{element.lName }} {{element.fName ? element.fName : element.company }} </h4>
                    <span class="mat-caption">{{element.phone}} </span> <span class="mat-caption">{{element.template }}</span> <br>
                    <span class="mat-small">{{'available-amount' | translate}}: {{element.availableAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation }} </span>  <br>
                    <span class="mat-small">{{'expected-amount' | translate: {date: startOfNextMonth} }}: {{element.expectedAmount  | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation }}</span>
                  </div>
            </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <td mat-cell *matCellDef="let element">
                
                <section class="template-section" fxLayoutAlign="end">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAgentCreditDialog(element)">
                          <mat-icon>attach_money</mat-icon>
                          <span>{{'agent-crediting' | translate}}</span>
                        </button>                       
                        <!-- <button *ngIf="agent[0].isHead == true" mat-menu-item (click)="openAgentDebetDialog(element)" >
                            <mat-icon>money_off</mat-icon>
                            <span>{{'agent-debeting' | translate}}</span>
                          </button> -->
                          <button mat-menu-item (click)="openAgentDialog(element)">
                            <mat-icon>list</mat-icon>
                            <span>{{'change-tariff' | translate}}</span>
                          </button>                  
                      </mat-menu>
                </section>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>