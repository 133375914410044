import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/services/agent.service';
import { ServiceService } from 'src/app/services/service.service';


@Component({
  selector: 'app-transactions-filter',
  templateUrl: './transactions-filter.component.html',
  styleUrls: ['./transactions-filter.component.css']
})
export class TransactionsFilterComponent implements OnInit {
  public agents: any[] = this.data.agents;
  selectedAgent = new FormControl();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  transactionTypes: any[] = this.data.transactionTypes;
  transactionType = new FormControl();
  transactionStatus = new FormControl();
  transactionStatuses: any[] = this.data.transactionStatuses;
  selectedService = new FormControl();
  paymentParameter = new FormControl();
  public serviceAutoComplete$: Observable<any> = null;

  @Output() someEvent = new EventEmitter<string>();

  constructor(public agentsSrv: AgentService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef,
    private servicesSrv: ServiceService) { 
        this.dateFrom.setValue(this.data.dateFrom.value);
        this.dateTo.setValue(this.data.dateTo.value);
        this.selectedService.setValue(this.data.selectedService?.value);
        this.selectedAgent.setValue(this.data.selectedAgent?.value);
        this.transactionType.setValue(this.data.transactionType?.value);
        this.transactionStatus.setValue(this.data.transactionStatus?.value);
        this.paymentParameter.setValue(this.data.paymentParameter?.value);
    }

  lookupServices(value: any): Observable<any> {
      if (typeof value === 'object') {
        value = value.name;
      }
  
      return this.servicesSrv.search(value.toLowerCase()).pipe(
        // map the item property of the results as our return object
        map(results => results),
        // catch errors
        catchError(_ => {
          return of(null);
        })
      );
  }

  ngOnInit(): void {
    this.serviceAutoComplete$ = this.selectedService.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '' && value !== null) {
          // lookup from services
          return this.lookupServices(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
    this.load();
  }

  load() {
  }

  displayFn(account: any): string {
    return account && account.company ? account.company : '';
  }

  displayFnService(service: any): string {
    return service && service.name ? service.name : '';
  }

  searchReportFilter(){
    let data = [];
    data['selectedAgent'] = this.selectedAgent;
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    data['transactionType'] = this.transactionType;
    data['transactionTypeTitle'] = this.transactionTypes.filter(x => x.code === this.transactionType.value)[0]?.title;
    data['transactionStatus'] = this.transactionStatus;
    data['transactionStatusTitle'] = this.transactionStatuses.filter(x => x.code === this.transactionStatus.value)[0]?.title;
    data['selectedService'] = this.selectedService;
    data['paymentParameter'] = this.paymentParameter;
    data['isClosed'] = false;
    this.bottomSheetRef.dismiss(data);
  }

  closeReportFilter(){
    let data = [];
    data['selectedAgent'] = this.data.selectedAgent;
    data['dateFrom'] = this.data.dateFrom;
    data['dateTo'] = this.data.dateTo;
    data['transactionType'] = this.data.transactionType;
    data['transactionTypeTitle'] = this.data.transactionTypes.filter(x => x.code === this.data.transactionType.value)[0]?.title;
    data['transactionStatus'] = this.data.transactionStatus;
    data['transactionStatusTitle'] = this.data.transactionStatuses.filter(x => x.code === this.data.transactionStatus.value)[0]?.title;
    data['selectedService'] = this.data.selectedService;
    data['paymentParameter'] = this.data.paymentParameter;
    data['isClosed'] = true;
    this.bottomSheetRef.dismiss(data);
  }

   clearSearchService() {
    this.selectedService.setValue(null);
  }

  clearSearchPaymentParameter() {
    this.paymentParameter.setValue(null);
  }
}
