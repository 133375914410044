<h2 mat-dialog-title fxLayoutAlign="center">{{ 'warning' | translate }}!</h2>
<mat-dialog-content>
    <div style="margin-bottom: 15px;">
        <p [innerHTML]="'divisional-text-you-accepted' | translate: {amount: paymentInfo.amount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}"></p>
        <p [innerHTML]="'divisional-text-transaction-count' | translate: {count: divisionalPaymentsCount}"></p>
        <p [innerHTML]="'divisional-text-transaction-max-limit' | translate: {amount: paymentInfo.maxAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}"></p>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" style="margin-bottom: -10px;">
    <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
    <button mat-flat-button color="primary" (click)="confirmDivisionalPayments()" [mat-dialog-close]="false">
        {{ 'confirm-payment' | translate }}
    </button>
</mat-dialog-actions>