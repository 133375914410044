<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'agent-debeting'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="debetForm" novalidate (ngSubmit)="update(debetForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
            <mat-form-field fxFill>
                <input matInput type="text" placeholder="{{'debet-sum' | translate}}" formControlName="debetSum" currencyMask>

                <mat-error *ngIf="debetForm.controls['debetSum'].hasError('required') || debetForm.controls['debetSum'].hasError('min')">
                    {{'debet-sum-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <textarea matInput placeholder="{{'comment' | translate}}" formControlName="comment"></textarea>
            </mat-form-field>
  
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!debetForm.valid"
                [mat-dialog-close]="true" cdkFocusInitial>{{'save'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>