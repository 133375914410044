<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'change-password'| translate}}</h1>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
  <div *ngIf="isCompleted">
    <form [formGroup]="agentForm" novalidate (ngSubmit)="update(agentForm.value)">
        <mat-dialog-content class="mat-typography">
            <h3 *ngIf="item">{{'agent' | translate}} : {{item.agent.company}}</h3>
            
            <mat-form-field fxFill>
                <input [type]="hide ? 'password' : 'text'"  matInput placeholder="{{'old-password' | translate}}" formControlName="oldPassword">
                <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

                <mat-error *ngIf="agentForm.controls['oldPassword'].hasError('required')">
                    {{'old-password-is-req' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input [type]="hide ? 'password' : 'text'"  matInput placeholder="{{'new-password' | translate}}" formControlName="newPassword">
                <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

                <mat-error *ngIf="f.newPassword.errors">
                    <div *ngIf="f.newPassword.errors?.required">{{'password-is-required' | translate}}</div>
                    <div *ngIf="f.newPassword.errors?.pattern">{{'password-pattern' | translate}}</div>
                  </mat-error>
            </mat-form-field>

            <mat-form-field fxFill>
                <input [type]="hide ? 'password' : 'text'"  matInput placeholder="{{'repeat-new-password' | translate}}" formControlName="repeatNewPassword">
                <mat-icon matSuffix color="primary" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

                <mat-error *ngIf="f.repeatNewPassword.errors">
                    <div *ngIf="f.repeatNewPassword.errors.required">{{'password-confirm-req' | translate}}</div>
                    <div *ngIf="f.repeatNewPassword.errors.mustMatch">{{'passwords-must-match' | translate}}</div>
                  </mat-error>
            </mat-form-field>
  
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{'cancel'| translate}}</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!agentForm.valid">{{'save'| translate}}</button>
        </mat-dialog-actions>
    </form>
  </div>