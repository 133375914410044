import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {ServiceService} from 'src/app/services/service.service';
import {AgentService} from 'src/app/services/agent.service';
import {CookieService} from 'ngx-cookie-service';
import {RouterExtService} from 'src/app/services/router-ext-service.service';
import {environment} from 'src/environments/environment';
import {IService} from '../../interfaces/services/iservice';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  categories: any[] = [];
  services: IService[] = [];
  top: IService[] = [];
  isCompleted: boolean = false;
  isCompletedServices: boolean = false;
  selectedCategoryId: string;
  selectedTabIndex = 0;
  agent: any;
  static_api_url: string;

  general: any[] = [{
    name: 'Requests',
    url: '/',
  },
  ];

  constructor(public authService: AuthService,
    private agentSrv: AgentService,
    public serviceSrv: ServiceService,
    private cookieService: CookieService,
    private routerService: RouterExtService) {
      this.static_api_url = environment.api_static;
  }

  ngOnInit(): void {
    if(this.getCookieCatId() != 0){
        this.selectTab(1);
    }
    this.load();
  }
  load() {
    this.getAgent();
    this.getTopServices();
  }

  getCategories() {
    this.isCompleted = false;
    this.serviceSrv.categories(this.agent.regionId).subscribe(
      (data: any) => {
        this.categories = data;
        if(this.getCookieCatId() != 0){
          this.selectedCategoryId = this.getCookieCatId().toString();
          this.getServices();
        }
      },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  getAgent() {
    this.agentSrv.agent().subscribe(
      (data: any) => {
        this.agent = data;
        this.getCategories();
      },
      error => console.log(error)
    )
  }

  handleClick(selectedItem) {
    this.selectedCategoryId = selectedItem.id;
    this.getServices();
    this.selectTab(1);
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }

  getServices() {
    this.isCompletedServices = false;
    this.services = [];
    if (this.selectedCategoryId != null && this.selectedCategoryId != undefined && this.agent != null && this.agent != undefined) {
      this.serviceSrv.services(this.selectedCategoryId, this.agent.regionId).subscribe(
        (data: IService[]) => {
          this.services = data;
          this.services.forEach(service => service.isTop = this.checkServiceInTop(service));
        },
        error => console.log(error)
      ).add(() => {
        this.isCompletedServices = true
      })
    }
  }

  getTopServices() {
    this.isCompleted = false;
    this.top = [];
    this.serviceSrv.top().subscribe(
      (data: IService[]) => {
        this.top = data;
        this.top.forEach(service => service.isTop = this.checkServiceInTop(service));
      },
      error => console.log(error)
    ).add(() => {
      this.isCompleted = true
    });
  }

  getCookieCatId(){
    const referrer = this.routerService.getPreviousUrl();
    if(this.cookieService.get('catId').length > 0 && referrer.indexOf('template/') !== -1){
        return this.cookieService.get('catId');
    }
    else{
        return 0;
    }
  }

  checkServiceInTop(item: IService) {
    return (this.top != null && this.top.some(top => top.id === item.id));
  }

  changeServiceTopState(item: IService){
    if (item.isTop){
      this.serviceSrv.removeTopService(item.id).subscribe(() => {
        this.updateServices();
      });
    }
    else {
      this.serviceSrv.addTopService(item.id).subscribe(() => {
        this.updateServices();
      });
    }
  }

  updateServices(){
    this.getTopServices();
    this.getServices();
  }
}
