import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ICheckDetails } from 'src/app/interfaces/check-details/icheck-details';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IBill } from 'src/app/interfaces/payment/ibill';
import { IService } from 'src/app/interfaces/payment/iservice';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { environment } from 'src/environments/environment';
import { TemplateComponent } from '../template/template.component';

@Component({
  selector: 'app-invoice-type-select-contract',
  templateUrl: './invoice-type-select-contract.component.html',
  styleUrls: ['./invoice-type-select-contract.component.css']
})
export class InvoiceTypeSelectContractComponent implements OnInit, AfterViewInit {



  @Input() checkDetails: ICheckDetails;
  @Output('update') handleAmountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output('changedServices') handleServicesChange: EventEmitter<IBill> = new EventEmitter<IBill>();
  data: any[];
  formDetails: FormGroup;

  changedBill: IBill;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;

  get services() {
    return (<FormArray>this.formDetails.get('services')).controls;
  }

  getServicesFor(index) {
    return (<FormArray>(<FormArray>this.formDetails.get('services')).controls[index].get('services')).controls;
  }

  getReadingsFor(index) {
    return (<FormArray>(<FormArray>(this.formDetails.get('services'))).controls[index].get('readings')).controls;
  }

  getItemsFor(index) {
    console.log((<FormArray>(<FormArray>(this.formDetails.get('services'))).controls[index]));
    return (<FormArray>(<FormArray>(this.formDetails.get('services'))).controls[index].get('items')).value;
  }

  constructor(private fb: FormBuilder, private templateComponent: TemplateComponent) { }

  ngOnInit() {
    this.loadData();
    this.formDetails = this.fb.group({
      amount: new FormControl({ value: 0, disabled: true }, Validators.required),
      services: this.fb.array(this.getServices())
    });

    this.getTotal();
  }

  ngAfterViewInit(){
    this.templateComponent.getArrayInput();
  }

  getServices() {
    return this.data.map(service => this.fb.group({
      isWantToPay: new FormControl(true),
      isWantToPayCredit: new FormControl(true),
      isWantToPayPenalty: new FormControl(true),
      enabled: new FormControl(false),
      accrued: new FormControl((service.paymentInformation.total > 0 ? (service.paymentInformation.total - service.paymentInformation.penalty - service.paymentInformation.credit + service.paymentInformation.deposit > 0 ? service.paymentInformation.total - service.paymentInformation.penalty - service.paymentInformation.credit + service.paymentInformation.deposit : 0) : 0) / 100, Validators.required),
      total: new FormControl(service.paymentInformation.total / 100, Validators.required),
      exTotal: new FormControl(service.paymentInformation.total / 100, Validators.required),
      count: new FormControl({ value: service?.paymentInformation?.accrued?.count, disabled: true }),
      id: new FormControl({ value: service.id, disabled: false }),
      name: new FormControl({ value: service.name, disabled: false }),
      readings: this.fb.array(this.getReadings(service.readings)),
      isDeposit: service.paymentInformation.deposit > 0 ? true : false,
      deposit: new FormControl({ value: service.paymentInformation.deposit / 100, disabled: false }),
      isCredit: service.paymentInformation.credit > 0 ? true : false,
      credit: new FormControl({ value: service.paymentInformation.credit / 100, disabled: false }),
      exCredit: new FormControl({ value: service.paymentInformation.credit / 100, disabled: false }),
      isPenalty: service.paymentInformation.penalty > 0 ? true : false,
      penalty: new FormControl({ value: service.paymentInformation.penalty / 100, disabled: false }),
      exPenalty: new FormControl({ value: service.paymentInformation.penalty / 100, disabled: false }),
      invoiceServicePaymentType: new FormControl(service.paymentInformation.invoiceServicePaymentType),
      servicesSelectType: new FormControl({ value: this.checkDetails?.invoice?.servicesSelectType, disabled: true }),
      items: service.items,
    })).sort(function (a, b) {
      return (parseInt(a.get('id').value) > parseInt(b.get('id').value) ? 1 : -1); // сортировка по идентификатору по просьбе ИВЦ
    });
  }
  getPenalty(penalty: any) {
    if (penalty > 0) {
      return penalty;
    }
  }

  getReadings(readings: any[]) {
    return readings.map(read => this.fb.group({
      type: read.type,
      count: read.count
    }));
  }

  loadData() {
    this.data = this.checkDetails.invoice.invoiceServices;
  }


  getTotal() {
    let total = 0;
    let totalTotal = 0;
    let _services: IService[] = [];

    this.services.forEach(item => {
      if(item.value.enabled == true){
        total += Number(item.value.total);

        let _service: IService =
        {
          id: item.value.id,
          name: item.value.name,
          total: Math.round((Number(Number(item.value.total).toFixed(2)) * 100)),
          readings: item.value.readings
        }
      
        totalTotal+=Math.round((Number(Number(item.value.total).toFixed(2)) * 100));
        _services.push(_service);
      }
    });

    if (this.formDetails.valid) {
      this.changedBill =
      {
        id: this.checkDetails.invoice.id,
        total: totalTotal,//parseInt((Number(Number(total).toFixed(2)) * 100).toString()),
        services: _services
      }
      this.handleServicesChange.emit(this.changedBill);
    }
    this.formDetails.controls['amount'].setValue(totalTotal/100);
    this.handleAmountChange.emit(totalTotal/100);    
  }

  userChangedReading(serviceIndex) {
    let val = 0;
    let prev = 0;
    let current = 0;
    this.services[serviceIndex].value.readings.forEach(item => {
      if (item.type == 1) {
        prev = item.count;
      }
      if (item.type == 2) {
        current = item.count;
      }
    });
    val = (current - prev) > 0 ? current - prev : 0;
    this.services[serviceIndex].get('count').setValue(val);

    let tariffValue = 0;

    this.services[serviceIndex].value.tariffs.forEach(element => {
      if(element.from == 0 && element.to == 0){
          tariffValue = element.value;
      }
      else if((val > element.from) && ((element.to > 0 && val <= element.to) || element.to == 0)){
          tariffValue = element.value;
      }
    });
    
    this.services[serviceIndex].get('accrued').setValue(val * tariffValue);
    this.getServiceTotal(serviceIndex);
    return val;
  }

  togglePanel(serviceIndex) {
    if(this.services[serviceIndex].get('isWantToPay').value == true) {
      this.services[serviceIndex].get('exTotal').setValue(this.services[serviceIndex].get('total').value);
      this.services[serviceIndex].get('total').setValue(0);
    }
    else {
      this.services[serviceIndex].get('total').setValue(this.services[serviceIndex].get('exTotal').value);
    }

    this.getTotal();
  }

  toggleCredit(serviceIndex) {
    if(this.services[serviceIndex].get('isWantToPayCredit').value == true) {
      this.services[serviceIndex].get('exCredit').setValue(this.services[serviceIndex].get('credit').value);
      this.services[serviceIndex].get('credit').setValue(0);
    }
    else {
      this.services[serviceIndex].get('credit').setValue(this.services[serviceIndex].get('exCredit').value);
    }

    this.getTotal();
  }

  togglePenalty(serviceIndex) {
    if(this.services[serviceIndex].get('isWantToPayPenalty').value == true) {
      this.services[serviceIndex].get('exPenalty').setValue(this.services[serviceIndex].get('penalty').value);
      this.services[serviceIndex].get('penalty').setValue(0);
    }
    else {
      this.services[serviceIndex].get('penalty').setValue(this.services[serviceIndex].get('exPenalty').value);
    }

    this.getTotal();
  }

  getServiceTotal(serviceIndex) {
    var _total = (this.services[serviceIndex].get('accrued').value) + (this.services[serviceIndex].get('penalty').value) - (this.services[serviceIndex].get('deposit').value) + this.services[serviceIndex].get('credit').value;
    this.services[serviceIndex].get('total').setValue(_total > 0 ? Math.round((Number(Number(_total).toFixed(2)) * 100))/100 : 0);
    this.getTotal();
  }

  setAccrued(serviceIndex){
    var _total = this.services[serviceIndex].get('total').value;
    this.services[serviceIndex].get('accrued').setValue(_total > 0 ? Math.round((Number(Number(_total).toFixed(2)) * 100))/100 : 0);
    this.getTotal();
  }

  chooseService(){
    this.getTotal();
  }

  chooseServiceByRadiobutton(serviceIndex){
    for(var i = 0; i<this.services.length; i++){
      if(i != 2){
        this.services[i].get('enabled').setValue(false);
      }
    }
    if(serviceIndex != 2){
      this.services[serviceIndex].get('enabled').setValue(true);
    }
    this.getTotal();
  }
}
