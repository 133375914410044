<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" fixedInViewport>
    <mat-nav-list>
      <div *ngIf="isAuthenticated">

        <!-- <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/agent'">
            <mat-icon>perm_identity</mat-icon><span style="padding-left: 20px;">{{'edit-personal-data' | translate}}
            </span>
        </a> -->
        <!-- <mat-divider></mat-divider> -->
        <a mat-list-item (click)="sidenav.toggle()" href="'/agents'" [fxHide.md]="true" [fxHide.sm]="true" [fxHide.lg]="true" [fxHide.xl]="true" *ngIf="isProfileExist">
            <mat-icon>account_circle</mat-icon><span style="padding-left: 20px;">
            {{currentUser?.name}} </span>
        </a>

        <a mat-list-item (click)="sidenav.toggle()" [fxHide.md]="true" [fxHide.sm]="true" [fxHide.lg]="true" [fxHide.xl]="true" *ngIf="!isProfileExist">
          <mat-icon>account_circle</mat-icon><span style="padding-left: 20px;">
          {{currentUser?.name}} </span>
        </a>

        <div *ngIf="isProfileExist">
            <mat-list-item>
                <mat-icon>place</mat-icon>
                <app-user-region style="padding-left: 20px;"></app-user-region>
            </mat-list-item>

            <mat-divider></mat-divider>
            <a mat-list-item (click)="sidenav.toggle()" href="/">
              <mat-icon>home</mat-icon><span style="padding-left: 20px;">
                {{'payments' | translate}} </span>
            </a>
            <mat-divider></mat-divider>
            <div mat-subheader>{{'perosnal-data' | translate}}</div>
            <!-- <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/agent/account'">
              <mat-icon>attach_money</mat-icon><span style="padding-left: 20px;">{{'my-account' | translate}}
              </span>
            </a> -->

            <a mat-list-item (click)="sidenav.toggle()" href="/agent/dashboard">
              <mat-icon>table_chart</mat-icon><span style="padding-left: 20px;">{{'reports' | translate}}
              </span>
            </a>
            <a mat-list-item (click)="sidenav.toggle()" href="/rates">
              <mat-icon>list</mat-icon><span style="padding-left: 20px;">
                {{'services-rates' | translate}}</span>
            </a>
            <a mat-list-item (click)="sidenav.toggle()" href="/agent/refund-request">
              <mat-icon>list_alt</mat-icon><span style="padding-left: 20px;">
                {{'refund-requests'| translate}}</span>
            </a>
            <mat-divider></mat-divider>
            <div mat-subheader>{{'news' | translate}}</div>
            <a mat-list-item (click)="sidenav.toggle()" href="/news">
              <mat-icon>notifications</mat-icon><span style="padding-left: 20px;">
                {{'news-and-notifications' | translate}}</span>
            </a>

            <mat-divider></mat-divider>
            <div mat-subheader>{{'settings' | translate}}</div>
            <a mat-list-item (click)="sidenav.toggle()"  href="/agents">
              <mat-icon>perm_identity</mat-icon>
              <span style="padding-left: 20px;">{{'agents' | translate}}</span>
            </a>
            <a mat-list-item (click)="sidenav.toggle()" href="/settings/tariffs">
              <mat-icon>list_alt</mat-icon>
              <span style="padding-left: 20px;">{{'my-tariffs' | translate}}</span>
            </a>
            <a mat-list-item (click)="sidenav.toggle()" href="/settings/upper-commissions">
              <mat-icon>list_alt</mat-icon>
              <span style="padding-left: 20px;">{{'manage-upper-commissions' | translate}}</span>
            </a>
            <mat-divider></mat-divider>
        </div>
      </div>
      <div mat-subheader>{{'about' | translate}}</div>
      <div *ngFor="let item of pages">
        <a mat-list-item (click)="sidenav.toggle()" href="/pages/{{ item.code}}">
          <mat-icon>info_outline</mat-icon>
          <span style="padding-left: 20px;">{{item.title}}</span>
      </a>
      </div>

      <mat-list-item>
        {{'version'| translate}} {{version}}
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <div fxLayout="column" fxFlexFill  #scrollTop>
      <mat-toolbar>
        <mat-toolbar-row>
          <div fxFlex fxLayoutAlign="flex-start center">
            <div>
              <button mat-icon-button>
                <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
              </button>
              <span class="menu-spacer"></span>
              <span class="toolbar-filler"></span>
            </div>

            <div>
                <a href="/">
                    <img class="header-logo" src="assets/img/logo.png" fxLayoutAlign="flex-start center" height="25" />
                </a>
            </div>
          </div>

          <div fxFlex fxLayoutAlign="flex-end center">
              <a mat-button *ngIf="isAuthenticated && isProfileExist" href="/agents" [fxHide.xs]="true">  
                  <span class="material-icons">account_circle</span> 
                  <span class="current-user-company">{{currentUser?.name}}</span> 
              </a>

              <a mat-button *ngIf="isAuthenticated && !isProfileExist" [fxHide.xs]="true">  
                  <span class="material-icons">account_circle</span> 
                  <span class="current-user-company">{{currentUser?.name}}</span> 
              </a>
              
              
              <a mat-button *ngIf="isAuthenticated && isProfileExist" href="/news" > 
                  <mat-icon matBadge="{{agentNewsCount}}">notifications</mat-icon>
              </a>

              <a mat-button *ngIf="isAuthenticated && isProfileExist" href="/" [fxHide.xs]="true"> 
                  <span class="material-icons">home</span> 
              </a>

              
              <!-- <button mat-button routerLink="/registration" *ngIf="!isAuthenticated">
                <mat-icon>perm_identity</mat-icon>
              </button> -->
              <button mat-button href="/login" *ngIf="!isAuthenticated">
                <mat-icon>vpn_key</mat-icon>
              </button>
              <button mat-button *ngIf="isAuthenticated" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
              </button>
          </div>

        </mat-toolbar-row>
      </mat-toolbar>

      <!-- header end -->
      <div fxFlex>

        <section class="hero">
          <div class="header-search-block">
              <!--<p>Автоматизированное рабочее место для агентов</p>-->
              <app-search *ngIf="isAuthenticated && isProfileExist"></app-search>
          </div>
        </section>
        <div fxFlexFill fxLayoutAlign="center" fxFill>
          <div fxFlex.xs="90%" fxFlex.gt-xs="100%"  fxFlex.gt-sm="800px" fxFlex.gt-md="1000px" fxFlex.gt-lg="1140px" class="content">
            <router-outlet>
              <span *ngIf="showLoader" class="loading"></span>
            </router-outlet>
          </div>
        </div>
      </div>
      <button class="button-up" *ngIf="visibilityUpButton" (click)="scrollToUp()"><mat-icon>arrow_upward</mat-icon></button>
      <button class="button-up" *ngIf="visibilityBottomButton" (click)="scrollToBottom()"><mat-icon>arrow_downward</mat-icon></button>
      <app-footer></app-footer>
      <div  #scrollBottom></div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>