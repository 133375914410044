import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  host: string;
  public isAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router) {
    this.host = environment.api_url + '/user'
  }

  login(username: string, password: string) {
    this.logout();

    let url = this.host + "/auth";

    let json: any = {
      email: username,   
      password: password,
    }   

    return this.http.post(url, json).pipe(
      tap(res => {
        this.setSession(res);
        this.isAuthenticated.next(true);
      }),
      shareReplay()
    );
  }

  

  private setSession(authResult) {
    const expireAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem('id_token', authResult.accessToken);
    localStorage.setItem('expired_at', JSON.stringify(expireAt.valueOf()));
  }

  logout() {
    let url = this.host + "/logout";
    this.http.get(url);
    localStorage.removeItem('id_token');
    localStorage.removeItem('expired_at');
    localStorage.removeItem('current_user');
    this.isAuthenticated.next(false);
    this.router.navigate(['/login']);
  }

  checkAuthenticated() {
    if (this.sessionExists()) {
      const authenticated = moment().isBefore(this.getExpiration());
      this.isAuthenticated.next(authenticated);
      return authenticated;
    }
    else {
      this.isAuthenticated.next(false);
      return false;
    }
  }

  isLoggedOut() {
    return !this.checkAuthenticated();
  }

  sessionExists() {
    const expiration = localStorage.getItem('expired_at');
    if (expiration != null && expiration != undefined) {
      return true;
    }
    else
      return false;
  }

  getExpiration() {
    const expiration = localStorage.getItem('expired_at');
    const expireAt = JSON.parse(expiration);
    return moment(expireAt);
  }

  getAuthUser(): any{
    let url = this.host + "/user-info";
    return this.http.get(url);
  }
}
