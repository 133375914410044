import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMenu } from './interfaces/imenu';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from './services/loader.service';
import { AgentService } from './services/agent.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from './services/pages.service';
import { ErrorDialogComponent } from './components/custom-dialogs/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NewsService } from './services/news.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showLoader: boolean;
  title = 'cashbox';
  currentUser: any;
  version: string;
  pages: any[] = [];
  isProfileExist: boolean;
  agentNewsCount: number = 0;
  visibilityUpButton: boolean = false;
  visibilityBottomButton: boolean = false;
  @ViewChild('scrollTop', { static: false }) scrollRef: ElementRef;
  @ViewChild('scrollBottom', { static: false }) scrollBottom: ElementRef;
  isAuthenticated: boolean;

  reports: IMenu[] = [
    {
      title: 'account-balance-report',
      url: "/reports/account-balance",
    },
    {
      title: 'balanceSummary',
      url: "/reports/consolidated-report",
    },
    {
      title: 'consolidated-report',
      url: "/reports/consolidated-report-extended",
    }
  ];
  constructor(public authService: AuthService,
    private loaderService: LoaderService,
    private agentService: AgentService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    private pagesSrv: PagesService,
    private dialog: MatDialog,
    private newsService: NewsService,
  ) {
    this.authService.isAuthenticated.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );
  }

  async ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    this.isAuthenticated = await this.authService.checkAuthenticated();
    if (this.isAuthenticated) {
      this.getAgent();
      this.getAuthUser();
      this.getAgentNewsCount();
    }
    this.load();
    window.addEventListener('scroll', this.scrollButton, true);
    window.addEventListener('scrollBottom', this.scrollButton, true);
  }

  scrollButton = (event: any): void => {
    const scrollNumber = event.srcElement.scrollTop;
    this.visibilityUpButton = false;
    this.visibilityBottomButton = true;
    if(scrollNumber == 0) {
      this.visibilityUpButton = false;
      this.visibilityBottomButton = false;
    }
    else if(scrollNumber + event.target.offsetHeight >= event.target.scrollHeight / 2){
        this.visibilityBottomButton = false;
        this.visibilityUpButton = true;   
    }
    else {
        this.visibilityBottomButton = true;
        this.visibilityUpButton = false;
    }
  };

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollButton, true);
    window.addEventListener('scrollBottom', this.scrollButton, true);
  }

  getAgentNewsCount() {
    this.newsService.getAgentNewsCount().subscribe(
      (data: any) => {
        this.agentNewsCount = data;
      },
      error => { }
    )
  }

  getAuthUser() {
    this.authService.getAuthUser().subscribe(
      (data: any) => {
        this.currentUser = data;
      },
      error => {
        let message = this.translate.instant('service-is-temporarily-unavailable');
        this.openSnackBar(message, 'x');
      }
    )
  }

  getAgent() {
    this.agentService.agent().subscribe(
      (data: any) => {
        this.isProfileExist = true;
      },
      error => {
        this.isProfileExist = false;
        if (error.status == 404) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              title: this.translate.instant('warning'),
              content: this.translate.instant('profile-not-found-message'),
              closeButtonLabel: this.translate.instant('close'),
              contactBtn: this.translate.instant('contacts'),
              logoutBtn: this.translate.instant('logout'),
            },
            panelClass: 'custom-alert-dialog',
            disableClose: true
          });
        }
        else {
          let message = this.translate.instant('service-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        }
      }
    )
  }

  load() {
    this.version = environment.version;
    this.getPages();
  };

  getPages() {
    this.pagesSrv.get().subscribe(
      (data: any) => {
        this.pages = data;
      },
      error => console.log(error)
    );
  }

  logout(): void {
    this.authService.logout();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  scrollToUp() {
    this.scrollRef.nativeElement.scrollIntoView({
      behavior: "smooth"
  });
    this.visibilityUpButton = false;
  }

  scrollToBottom() {
    this.scrollBottom.nativeElement.scrollIntoView({
      behavior: "smooth"
  });
    this.visibilityBottomButton = false;
  }
}
