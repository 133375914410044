// Компонент регистрации - полностью готов
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IRegistration } from 'src/app/interfaces/agent/iregistration';
import { AgentService } from 'src/app/services/agent.service';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from 'src/app/helpers/must-match';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/services/dictionary.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  registrationForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    lname: [null, Validators.required],
    name: [null, Validators.required],
    pname: [null],
    phone: [null, [Validators.required, Validators.pattern('77\\d{9}')]],
    address: [null, Validators.required],
    iin: [null, [Validators.required, Validators.pattern('\\d{12}')]],
    region: [null, Validators.required],
    acceptTerms: [false, Validators.requiredTrue]
  });
  hide = true;
  hasUnitNumber = false;

  regions = [];
  cities = [];
  isCompleted: boolean = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private agentSrv: AgentService,
    private dictionarySrv: DictionaryService,
    public snackBar: MatSnackBar,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getRegions();
    this.getCities();
  }

  getRegions() {
    this.dictionarySrv.getRegions().subscribe(
      (data: any) => { this.regions = data; },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  getCities() {
    this.dictionarySrv.getCities().subscribe(
      (data: any) => { this.cities = data; },
      error => console.log(error)
    ).add(() => { this.isCompleted = true });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registrationForm.controls; }

  public hasError = (controlName: string, errorName: string) => {
    return this.registrationForm.controls[controlName].hasError(errorName);
  }

  public create = (registrationForm) => {
    if (this.registrationForm.valid) {
      this.executeCreation(registrationForm);
    }
  }

  private executeCreation = (item) => {
    this.isCompleted = false;
    let json: IRegistration = {
      lname: item.lname,
      name: item.name,
      pname: item.pname,
      company: item.name,
      companyBin: item.iin.toString(),
      address: item.address,
      email: item.email,
      iin: item.iin.toString(),
      phone: Number(item.phone),
      templateId: 0,
      regionId: item.region,
      cityId: item.cityId
    }
    this.agentSrv.create(json)
      .subscribe(
        data => {
          // Авторизуемся
          this.authService.login(item.email, item.password)
            .subscribe(
              resLogin => {
                window.location.href = "/";
                //this.router.navigate(['/']);
              },
              error => {
                if (error.status === 500) {
                  let message = this.translate.instant('service-is-temporarily-unavailable');
                  this.openSnackBar(message, 'x');
                }
                else {
                  let message = this.translate.instant('wrong-login-or-password');
                  this.openSnackBar(message, 'x');
                }
              }
            );
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('user-is-already-registered');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('user-is-already-registered');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => { this.isCompleted = true });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
