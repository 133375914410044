import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsPayProcessService {
  public isPayStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set(value: boolean) {
      this.isPayStatus.next(value);
  }

  get(): Observable<boolean> {
      return this.isPayStatus.asObservable();
  }
}