<section class="hero">
    <p>
        <mat-icon>done</mat-icon> {{'payment-accepted'| translate}}!
    </p>
</section>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center">
    <mat-list dense fxFill>
        <mat-list-item>
            <span fxFlex="50" class="mat-body-1">{{'check-service-name' | translate}}:
            </span>
            <span fxFlex="50" class="mat-body-1">{{item?.transaction?.serviceName}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">{{'check-created' | translate}}:
            </div>
            <div fxFlex="50" class="mat-body-1">{{check?.CreatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>
    <mat-list dense *ngFor="let applicant of check?.Payload?.Parameters | keyvalue" fxFill fxFlex="360">
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">
                {{applicant.key | lowercase | translate}}:
            </div>
            <div fxFlex="50" class="mat-body-1">
                {{applicant.value}}
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>



    <mat-list dense *ngFor="let service of check?.Payload?.Bill?.Services" fxFill>
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">
                {{service.Name }}:
            </div>
            <div fxFlex="50" class="mat-body-1">
                {{service.Total/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>

    <mat-list dense fxFill>
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">{{'check-upper-commission' | translate}}:
            </div>
            <div fxFlex="50" class="mat-body-1">
                {{item?.transaction?.upperCommission/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">{{'check-amount' | translate}}:
            </div>
            <div fxFlex="50" class="mat-body-1">
                {{item?.transaction?.amount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div fxFlex="50" class="mat-body-1">{{'check-accepted' | translate}}:
            </div>
            <div fxFlex="50" class="mat-body-1"><b>
                    {{item?.transaction?.accepted/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</b></div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>

</mat-dialog-content>
<mat-dialog-actions align="end"></mat-dialog-actions>
   <img src="assets/img/stamp.png" height="120" /> 
<form [formGroup]="mailForm" novalidate (ngSubmit)="sendToMail()">

    <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="end" fxLayoutGap="10px" fxLayoutGap.xs="0">

        <mat-form-field fxFlex="40">
            <input matInput placeholder="{{'email' | translate}}" formControlName="email">
            <mat-error *ngIf="f.email.errors">
                <div *ngIf="f.email.errors.required">{{'email-is-required' | translate}}</div>
                <div *ngIf="f.email.errors.email">{{'email-must-be-valid' | translate}}</div>
            </mat-error>
        </mat-form-field>

        <button mat-icon-button color="primary" [disabled]="!mailForm.valid">
            <mat-icon>send</mat-icon>
        </button>
   <!--      <button mat-flat-button color="primary" type="submit" [disabled]="!mailForm.valid"
            cdkFocusInitial>{{'send-check'| translate}}</button> -->
        <!--   <button mat-button mat-dialog-close>{{'close'| translate}}</button> -->

    </div>
</form>